.c-footer {

	@include critical {
		opacity: 0;
		content-visibility: auto;
		contain-intrinsic-size: auto 1225px;

		@include mq-min($bpTablet) {
			contain-intrinsic-size: auto 838px;
		}

		@include mq-min($bpDesktop) {
			contain-intrinsic-size: auto 600px;
		}
	}

	@include defer {
		background: $cFooterBackgroundColor;
		opacity: 1;
	}
}

.c-footer__top {

	@include defer {
		padding: 20px 0;
	}
}

.c-footer__bottom {

	@include defer {
		background-color: $cFooterBottomBackgroundColor;
		padding: 20px 0;
	}
}

.c-footer__title {

	@include defer {
		color: $cFooterLinkTitleTextColor;
		font-weight: 700;
		font-size: rem(16px);
		margin: 0 0 20px 0;
		padding: 0;
	}
}

.c-footer__link {

	@include defer {
		color: $cFooterLinkTextColor;
		text-decoration: none;
	}

	@include defer {
		transition: color 300ms;
	}
}

.c-footer__link--highlight {

	@include defer {
		text-decoration: underline;
		color: $cFooterLinkAltTextColor;
	}
} 

.c-footer__link:hover,
.c-footer__link:focus {

	@include defer {
		color: $cFooterLinkHoverTextColor;
		text-decoration: none;
	}
}

.c-footer__list {

	@include defer {
		list-style: none;
		padding: 0;
		margin: 0;	
	}
}

.c-footer__list-item {

	@include defer {
		padding-bottom: 0;
	}
}

.c-footer__list-link {

	@include defer {
		display: block;
		color: $cFooterLinkTextColor;
		font-size: rem(14px);
		padding: 0 0 20px 0;
	}
}

.c-footer__list-link:hover,
.c-footer__list-link:focus {

	@include defer {
		color: $cFooterLinkHoverTextColor;
		text-decoration: none;
	}
}

.c-footer__social-list {

	@include defer {
		display: inline-flex;
		list-style: none;
		padding: 0;
		margin: 0 0 0 -10px;
	}
}

.c-footer__social-item {

	@include defer {
		display: inline-block;
		margin: 0;
		padding: 0;
	}
}

.c-footer__social-link {

	@include defer {
		color: $cFooterSocialLinkTextColor;
		padding: 10px;
	}
}

.c-footer__social-link:hover,
.c-footer__social-link:focus {

	@include defer {
		color: $cFooterSocialLinkHoverTextColor;
	}
}

.c-footer__social-icon {

	@include defer {
		width: 25px;
		height: 25px;
	}
}

.c-footer__logo {

	@include defer {
		display: block;
		width: 200px;
		height: 40px;
		margin: 0 0 15px 0;

		.is-trade & {
			width: 141px;
		}
	}
}

.c-footer__contact {

	@include defer {
		color: $cFooterContactTextColor;
		min-width: 284px;
	}
}

.c-footer__contact-intro {
	
	@include defer {
		margin: 0;
	}
}

.c-footer__contact-item {

	@include defer {
		margin: 0;
		padding: 0 0 15px 0;
	}
}

.c-footer__contact-link {

	@include defer {
		color: $cFooterContactLinkTextColor;
	}
}

.c-footer__inline-list {

	@include defer {
		list-style: none;
		padding: 0;
		margin: 0 0 0 -5px;
	}
}

.c-footer__inline-list-item {

	@include defer {
		display: inline-block;
		margin: 0 3px 0 0;
		padding: 0;
	}
}

.c-footer__inline-list-item--with-image {
	@include defer {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0 rem(10px);
		margin: 0 3px 5px 0;
		height: 30px;
		box-shadow: 1px 2px 2px rgba(0,0,0,0.1);
		background-color: $colorWhite;
	}
}

.c-footer__inline-list-image {

	@include defer {
		width: auto;
	}
}

.c-footer__inline-list-link {

	@include defer {
		padding: 0 5px;
		color: $cFooterInlineListLinkTextColor;
	}
}

.c-footer__inline-list-item:first-child .c-footer__inline-list-link {

	@include defer {
		padding-left: 0;
	}
}

.c-footer__inline-list--has-seperator .c-footer__inline-list-item:not(:last-child):after {

	@include defer {
		content: '|';
	}
}

.c-footer__inline-list--has-seperator {

	@include defer {
		margin: 0 0 15px 0;
	}
}

@include mq-max($bpTablet) {

	.c-footer__inline-list--has-seperator .c-footer__inline-list-item {
		@include defer {
			margin-bottom: 5px;
		}
	}

	.c-footer__inline-list--has-seperator .c-footer__inline-list-link {
		@include defer {
			display: inline-block;
			padding-bottom: 10px;
		}
	}
}

.c-footer__mobile-mb {

	@include defer {
		margin-bottom: 15px;
	}
}

.c-footer__copyright {

	@include defer {
		font-size: rem(12px);
		line-height: rem(16px);
		color: $cFooterCopyrightTextColor;
	}
}

.c-footer__trustpilot {

	@include defer {
		transform: scale(0.9);
		transform-origin: 0 0;
		margin-bottom: 8px;
	}
}

/* Footer error */
.c-footer--error {

	@include defer {
		text-align: center;
	}
}

.c-footer__inline-list {
	@include defer {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
}

/* Tablet and Above */

@include defer {

	@include mq-min($bpTablet) {
		.c-footer__mobile-mb {
			margin-bottom: inherit;
		}
		.c-footer__bottom {
			padding: 35px 0;
		}
		.c-footer__inline { 
			display: flex;
			align-items: center;
		}
		.c-footer__inline-item {
			padding: 0 10px;
			margin: 0;
		}
		.c-footer__inline-item.c-footer__inline-item--list {
			margin-bottom: 0;
		}
		.c-footer__trustpilot {
			margin-bottom: inherit;
		}
	}
}

/* Tablet and Above */

@include defer {

	@include mq-min($bpDesktop) {
		.c-footer__top {
			padding: 65px 0 45px 0;
		}

		.c-footer__inline-list--has-seperator {
			margin: 0 0 25px 0;
		}

		.c-footer__social-list {
			margin-top: 10px;
		}

		.c-footer__trustpilot {
			margin-bottom: -10px;
		}

		.c-footer__inline-list {
			justify-content: flex-end;
		}
	}
}
