@include defer {

	[data-simplebar] {
		position: relative;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-content: flex-start;
		align-items: flex-start;
	}

	.simplebar-wrapper {
		overflow: hidden;
		width: inherit;
		height: inherit;
		max-width: inherit;
		max-height: inherit;
	}

	.simplebar-mask {
		direction: inherit;
		position: absolute;
		overflow: hidden;
		padding: 0;
		margin: 0;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: auto !important;
		height: auto !important;
		z-index: 0;
	}

	.simplebar-offset {
		direction: inherit !important;
		box-sizing: inherit !important;
		resize: none !important;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		padding: 0;
		margin: 0;
		-webkit-overflow-scrolling: touch;
	}

	.simplebar-content-wrapper {
		direction: inherit;
		box-sizing: border-box !important;
		position: relative;
		display: block;
		height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
		width: auto;
		max-width: 100%; /* Not required for horizontal scroll to trigger */
		max-height: 100%; /* Needed for vertical scroll to trigger */
		overflow: auto;
		scrollbar-width: none;
		-ms-overflow-style: none;
	}

	.simplebar-content-wrapper::-webkit-scrollbar,
	.simplebar-hide-scrollbar::-webkit-scrollbar {
		display: none;
		width: 0;
		height: 0;
	}

	.simplebar-content:before,
	.simplebar-content:after {
		content: ' ';
		display: table;
	}

	.simplebar-placeholder {
		max-height: 100%;
		max-width: 100%;
		width: 100%;
		pointer-events: none;
	}

	.simplebar-height-auto-observer-wrapper {
		box-sizing: inherit !important;
		height: 100%;
		width: 100%;
		max-width: 1px;
		position: relative;
		float: left;
		max-height: 1px;
		overflow: hidden;
		z-index: -1;
		padding: 0;
		margin: 0;
		pointer-events: none;
		flex-grow: inherit;
		flex-shrink: 0;
		flex-basis: 0;
	}

	.simplebar-height-auto-observer {
		box-sizing: inherit;
		display: block;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 1000%;
		width: 1000%;
		min-height: 1px;
		min-width: 1px;
		overflow: hidden;
		pointer-events: none;
		z-index: -1;
	}

	.simplebar-track {
		z-index: 1;
		position: absolute;
		right: 0;
		bottom: 0;
		pointer-events: none;
		overflow: hidden;
	}

	[data-simplebar].simplebar-dragging {
		pointer-events: none;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	[data-simplebar].simplebar-dragging .simplebar-content {
		pointer-events: none;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	[data-simplebar].simplebar-dragging .simplebar-track {
		pointer-events: all;
	}

	.simplebar-scrollbar {
		position: absolute;
		left: 0;
		right: 0;
		min-height: 10px;
	}

	.simplebar-scrollbar:before {
		position: absolute;
		content: '';
		background: black;
		border-radius: 7px;
		left: 2px;
		right: 2px;
		opacity: 0;
		transition: opacity 0.2s 0.5s linear;
	}

	.simplebar-scrollbar.simplebar-visible:before {
		opacity: 0.5;
		transition-delay: 0s;
		transition-duration: 0s;
	}

	.simplebar-track.simplebar-vertical {
		top: 0;
		width: 11px;
	}

	.simplebar-scrollbar:before {
		top: 2px;
		bottom: 2px;
		left: 2px;
		right: 2px;
	}

	.simplebar-track.simplebar-horizontal {
		left: 0;
		height: 11px;
	}

	.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
		right: auto;
		left: 0;
		top: 0;
		bottom: 0;
		min-height: 0;
		min-width: 10px;
		width: auto;
	}
	/* Rtl support */
	[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
		right: auto;
		left: 0;
	}

	.simplebar-dummy-scrollbar-size {
		direction: rtl;
		position: fixed;
		opacity: 0;
		visibility: hidden;
		height: 500px;
		width: 500px;
		overflow-y: hidden;
		overflow-x: scroll;
		-ms-overflow-style: scrollbar !important;
	}

	.simplebar-dummy-scrollbar-size > div {
		width: 200%;
		height: 200%;
		margin: 10px 0;
	}

	.simplebar-hide-scrollbar {
		position: fixed;
		left: 0;
		visibility: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
		-ms-overflow-style: none;
	}
	/* Custom Overrides */

	.simplebar-dragging {
		cursor: grab;
	}

	.simplebar-track,
	.simplebar-scrollbar {
		cursor: grab;
	}

	.simplebar-track.simplebar-horizontal {
		cursor: grab;
		background-color: transparent;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAADCAIAAADdv/LVAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDkuMS1jMDAxIDc5LmE4ZDQ3NTM0OSwgMjAyMy8wMy8yMy0xMzowNTo0NSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDI0LjYgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkIyODU3QkM4MUM5QzExRUVCQzNBQjVBMjA3QjM3NzIxIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkIyODU3QkM5MUM5QzExRUVCQzNBQjVBMjA3QjM3NzIxIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QjI4NTdCQzYxQzlDMTFFRUJDM0FCNUEyMDdCMzc3MjEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QjI4NTdCQzcxQzlDMTFFRUJDM0FCNUEyMDdCMzc3MjEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4chkfVAAAAFElEQVR42mK4f/8+EwMDAwgDBBgAGkYCooHL1goAAAAASUVORK5CYII=");
		background-position: 0 50%;
		background-repeat: repeat-x;
	}

	.simplebar-track.simplebar-horizontal.simplebar-hover,
	.simplebar-track.simplebar-horizontal.simplebar-hover .simplebar-scrollbar {
		pointer-events: all;
		cursor: pointer;
	}

	.simplebar-track.simplebar-horizontal {
		left: 0;
		height: 20px;
	}

	.simplebar-scrollbar:before {
		border-radius: 0;
		opacity: 1;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: transparent;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAJCAIAAAB8JNGzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAydpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDkuMS1jMDAxIDc5LjE0NjI4OTk3NzcsIDIwMjMvMDYvMjUtMjM6NTc6MTQgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjA4NDQxMjhFQkEwMTExRUU5OTZDODE3M0Y3Q0UwN0U3IiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjA4NDQxMjhEQkEwMTExRUU5OTZDODE3M0Y3Q0UwN0U3IiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCAyNC42IChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjI1QTc4QjIyMUM5QzExRUVCODE0OEY0NzBDRERGODc4IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjI1QTc4QjIzMUM5QzExRUVCODE0OEY0NzBDRERGODc4Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+x8I0jQAAABRJREFUeNpicA8KZ2JgYMCHAQIMACEUAQHj2OMJAAAAAElFTkSuQmCC");
		background-position: 0 50%;
		background-repeat: repeat-x;
		background-size: 3px 3px;
	}

	.simplebar-scrollbar.simplebar-visible:before {
		opacity: 1;
	}

	.simplebar-track.simplebar-hover,
	.simplebar-hover .simplebar-scrollbar:before,
	.simplebar-scrolling .simplebar-scrollbar:before {
		background-size: 4px 4px;
	}

	.simplebar-dragging .simplebar-track .simplebar-scrollbar:before {
		background-size: 6px 6px;
	}

	.using-keyboard .simplebar-scrolling .simplebar-track {
		outline: 2px solid var(--bge-colour-theme-focusColourBackgroundColourOuter, $resetLinkFocusOutlineColor);
	}
}
