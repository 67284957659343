// wrapper
.formWrapper 
{

	@include critical {
		border-bottom: 1px solid $formWrapperBorderColor;
		margin: 0 0 15px 0;
		padding: 0 0 15px 0;
		&Last 
		{
			border-bottom: 0;
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
}

// labels
label {

	@include critical {
		display: block;
		margin: 0;
		padding: 0 0 3px 0;
	}
}

// rows
.formRow {

	@include critical {
		margin: 0;
		padding: 0 0 15px 0;
		&Inline 
		{
			float: left;
			margin: 0;
			padding: 0 1.3% 0 0;
			width: 32%;
			max-width: 127px;
			&:last-child 
			{
				padding-right: 0;
			}
		}
	}
}

.formRow--flush {
	@include critical {
		margin: 0;
		padding: 0;
	}
}

// actions
.formAction
{

	@include critical {
		margin: 0;
		padding: 5px 0 15px 0;
		&Last 
		{
			padding-bottom: 0;
		}
	}
}

// required
.formReq,
.umbraco-forms-indicator {

	@include critical {
		color: $formReqColor !important;

		label & {
			font-family: Helvetica, Arial, Verdana, sans-serif;
			font-size: rem($fontSize + 1);
		}
	}
}

// inputs
.formText,
.formTextarea,
.formSelect,
.formUpload,
.StripeElement  {

	@include critical {
		border: 1px solid $formTextTextareaSelectUploadBorderColor;
		@include border-radius(0);
		@include box-sizing();
		color: $formTextTextareaSelectUploadColor;
		display: inline-block;
		font-family: Helvetica, Arial, Verdana, sans-serif;
		font-size: rem(16px);
		@include mq-min($bpDesktop) {
			font-size: rem($fontSize - 1);
		}
		height: rem(36px);
		line-height: rem($fontHeight - 1);
		margin: 0;
		padding: 5px 8px;
		@include placeholder-text($formTextTextareaSelectUploadPlaceholderTextFallbackColor, $formTextTextareaSelectUploadPlaceholderTextColor);
		vertical-align: middle;
		width: 100%;
		max-width: 315px;

		&::-moz-focus-inner {
			border: 0;
			margin: 0;
			padding: 0;
		}

		&[type=search] {
			-webkit-appearance: none;
		}
	}

	&:focus {

		@include defer {
			border-color: $formTextTextareaSelectUploadFocusBorderColor;
			@include box-shadow($formTextTextareaSelectUploadFocusBoxShadow);
			color: $formTextTextareaSelectUploadFocusColor;
		}
	}

	&.disabled, &[disabled] {

		@include critical {
			background: $formTextTextareaSelectUploadDisabledBackgroundColor;
			cursor: not-allowed;
		}
	}

	&.input-validation-error {

		@include defer {
			background-image: $formTextTextareaSelectUploadValidationErrorBackgroundImageUrl;
			background-position: -1px 0;
			background-repeat: repeat-y;
			padding-left: 10px;
		}
	}
}

.StripeElement--focus {
	@include defer {
		border-color: $formTextTextareaSelectUploadFocusBorderColor;
		@include box-shadow($formTextTextareaSelectUploadFocusBoxShadow);
		color: $formTextTextareaSelectUploadFocusColor;
	}
}

.formStatic 
{

	@include critical {
		padding-top: 6px;
	}
}

.formText, .formSelect 
{
	
	@include critical {
		&Small 
		{
			margin-right: 5px;
			width: 40%;
			max-width: 100px;
		}
		&Medium
		{
			margin-right: 5px;
			width: 60%;
			max-width: 150px;
		}
		&Large
		{
			margin-right: 5px;
			width: 80%;
			max-width: 250px;
		}
	}
}

.formSelect 
{

	@include critical {
		padding-left: 4px;
		padding-right: 4px;
	}

	&.input-validation-error
	{

		@include defer {
			padding-left: 6px;
			&:focus 
			{
				padding-left: 4px;
			}
		}
	}
}

.formTextarea,
.umbraco-forms-form textarea {

	@include critical {
		height: 150px;
		resize: vertical;
		vertical-align: top;
	}
}

// checks & radios
.formChecks,
.formRadios {

	@include critical {
		list-style: none;
		margin: 0;
		padding: 0 0 15px 0;
	}

	.level1 {

		@include critical {
			list-style: none;
			min-height: rem(13px);
			margin: 0;
			padding: 5px 0 0 25px;
			position: relative;
			z-index: 1;
		}

		input {

			@include critical {
				cursor: pointer;
				left: 5px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: rem(8px);
				z-index: 1;
			}

			@include defer {
				&:focus {
					outline: 1px dotted $formChecksRadiosInputFocusOutlineColor;
				}

				&.input-validation-error {
					@include box-shadow($formChecksRadiosInputInputValidationErrorBoxShadow);
				}
			}
		}

		label {

			@include critical {
				cursor: pointer;
				display: inline;
				font-weight: normal;
				margin: 0;
				padding: 0;
			}
		}

		@include critical {
			&:first-child {
				padding-top: 0;

				input {
					top: rem(3px);

					.has-scaled-up-text-large & {
						top: rem(5px);
					}
				}
			}
		}
	}

	&--spaced {
		.level1 {
			@include critical {
				padding-top: rem(10px);
				input {
					top: rem(12px);
				}
				&:first-child {
					input {
						top: rem(2px);
					}
				}
			}
		}
	}

	&Inline {

		@include critical {
			padding-bottom: 10px;

			.level1 {
				float: left;
				padding: 0 5px 5px 25px;
				min-width: 75px;

				input {
					top: 2px;
				}
			}
		}
	}

	&Rating {

		@include critical {
			padding-top: 5px;
			max-width: none;
		}

		.level1 {

			@include critical {
				float: left;
				margin: 0;
				overflow: hidden;
				padding: 0 15px 0 0;
			}

			label {
				.js & {

					@include defer {
						background: $formChecksRadiosRatingsBackgroundUrl no-repeat -80px -26px;
						display: block;
						height: 15px;
						margin: 0 auto;
						padding: 0;
						width: 15px;

						&.hover, &.active {
							background-position: 0 -26px;
						}
					}
				}
			}

			input {

				@include critical {
					position: static;
					top: 2px;

					.js & {
						visibility: hidden;
					}
				}
			}

			span {
				.js & {

					@include critical {
						display: none;
					}
				}
			}

			.js & {

				@include critical {
					padding: 0 10px 0 0;
					@include min-height(15px);
					width: 15px;
				}
			}

			.js.no-touch & {

				@include critical {
					padding: 0 3px 0 0;
				}
			}
		}
	}

	&Divider {

		@include critical {
			border-bottom: 1px solid $formChecksRadiosDividerBorderColor;
			margin: 0 0 15px 0;
			padding: 0 0 15px 0;
		}
	}

	&Full {

		@include critical {
			max-width: none !important;
		}
	}

	.formRow & {

		@include critical {
			padding-bottom: 0 !important;
		}
	}

	@include mq-min($bpTablet) {

		@include critical {
			max-width: 70%;
		}
	}
}

// buttons
.formButton
{

	@include critical {
		background: $formButtonBackgroundColor;
		border: none;
		@include border-radius(0);
		@include box-sizing();
		color: $formButtonColor !important;
		cursor: pointer;
		display: inline-block;
		font-family: $fontBase;
		font-size: rem($fontSize + 2);
		font-weight: 400;
		height: rem(36px);
		line-height: rem($fontHeight + 2);
		margin: 0 5px 0 0;
		padding: 5px 15px;
		text-align: center;
		text-decoration: none !important;
		@include user-select();
		vertical-align: middle;
		white-space: nowrap;
		min-width: 90px;
		&::-moz-focus-inner 
		{
			border: 0;
			margin: 0;
			padding: 0;
		}
	}

	@include defer {
		html:not(.matchmedia-no-hover) &:hover 
		{
			background: $formButtonHoverBackgroundColor;
			color: $formButtonHoverColor !important;
			text-decoration: none !important;
		}
		&:focus  
		{
			background: $formButtonFocusBackgroundColor;
			outline: 1px dotted $formButtonFocusColor;
		}
	}
	&.disabled, &[disabled] 
	{

		@include critical {
			background: $formButtonDisabledBackgroundColor !important;
			@include box-shadow(none);
			cursor: not-allowed !important;
			pointer-events: none !important;
			@include opacity(.5);
		}
	}
}

a.formButton
{

	@include critical {
		padding-bottom: 7px;
		padding-top: 7px;
	}
}

// custom buttons
.formButton 
{
	&Toggle
	{

		@include critical {
			font-size: rem($fontSize + 1);
			font-weight: 600;
			height: auto;
			line-height: rem($fontHeight + 1);
			padding: 11px 50px 12px 25px !important;
			position: relative;
			z-index: 1;
			&:before
			{
				background: $formButtonToggleBeforeBackgroundUrl no-repeat 0 0;
				content: "";
				display: block;
				height: 8px;
				margin: 0;
				padding: 0;
				position: absolute;
				right: 20px;
				top: 17px;
				width: 14px;
				z-index: 1;
			}
		}
		&.active
		{

			@include defer {
				&:before
				{
					background-position: 0 -8px;
				}
			}
		}
	}
	&Action
	{
		@include critical {
			background-color: $formButtonActionBackgroundColor !important;
			font-weight: 600;
			min-width: 150px;
		}
		html:not(.matchmedia-no-hover) &:hover 
		{

			@include defer {
				background-color: $formButtonActionHoverBackgroundColor !important;
			}
		}
	}
	&Green 
	{
		@include critical {
			background-color: $formButtonGreenBackgroundColor !important;
		}
		&:hover 
		{

			@include defer {
				background-color: $formButtonGreenHoverBackgroundColor !important;
			}
		}
	}
	&Grey 
	{

		@include critical {
			background-color: $formButtonGreyBackgroundColor !important;
		}
		&:hover 
		{

			@include defer {
				background-color: $formButtonGreyHoverBackgroundColor !important;
			}
		}
	}
	&Red 
	{

		@include critical {
			background-color: $formButtonRedBackgroundColor !important;
		}
		&:hover 
		{

			@include defer {
				background-color: $formButtonRedHoverBackgroundColor !important;
			}
		}
	}
	&Block 
	{

		@include critical {
			display: block;
			padding-left: 0;
			padding-right: 0;
			width: 100%;
		}
	}
	&Stretch
	{

		@include critical {
			width: 100%;
			max-width: 315px
		}
	}
	&Small 
	{

		@include critical {
			height: 32px;
			font-size: rem($fontSize - 1);
			line-height: rem($fontHeight - 1);
			padding: 3px 13px;
			min-width: 0;
		}
	}
	&Large 
	{

		@include critical {
			font-weight: 600;
			height: auto;
			padding: 10px 25px !important;
			min-width: 0;
		}
	}
	&Link 
	{

		@include critical {
			background: none;
			border-color: transparent;
			color: $formButtonLinkBackgroundColor !important;
			height: auto;
			margin: 0;
			padding: 5px !important;
			text-decoration: none !important;
			min-width: 0;
		}

		@include defer {
			&:hover 
			{
				background: none;
				border-color: transparent;
				color: $formButtonLinkHoverBackgroundColor !important;
				text-decoration: none !important;
			}
			&:focus
			{
				background: none;
				border-color: transparent;
			}
		}
	}
	&Search 
	{

		@include critical {
			background: $formButtonSearchBackgroundColor $formButtonSearchBackgroundUrl no-repeat 7px 7px;
			padding: 0;
			text-indent: -9999px;
			width: 36px;
			min-width: 36px;
		}
		&:hover 
		{

			@include defer {
				background: $formButtonSearchHoverBackgroundColor $formButtonSearchHoverBackgroundUrl no-repeat 7px 7px;
			}
		}
	}
	&Checkout
	{

		@include critical {
			background: $formButtonCheckoutBackgroundColor;
			display: block;
		}
		&:hover,
		&:active
		{

			@include defer {
				background: $formButtonCheckoutHoverBackgroundColor;
			}
		}
	}
}

// messages
.formMessage,
.formSuccess,
.formWarning,
.formError 
{

	@include critical {
		background: $formMessageSuccessWarningErrorBackgroundColor;
		color: $formMessageSuccessWarningErrorColor;
		clear: both;
		display: block;
		font-size: rem($fontSize);
		line-height: rem($fontHeight);
		margin: 0 0 15px 0;
		padding: 8px 10px;
	}
	a 
	{

		@include critical {
			color: $formMessageSuccessWarningErrorAColor;
			text-decoration: underline;
		}

		&:hover 
		{

			@include defer {
				color: $formMessageSuccessWarningErrorAHoverColor;
				text-decoration: none;
			}
		}
	}
	.formRow &,
	.c-form__row &,
	.formChecks &,
	.formRadios & 
	{

		@include critical {
			background: none;
			display: none;
			font-size: rem($fontSize - 1);
			line-height: rem($fontHeight - 1);
			margin: 0;
			padding: 5px 0 0 0;
			max-width: 315px;
		}

		@include defer {
			&.field-validation-error 
			{
				display: block;
			}
		}
	}
	.formRowChecks & 
	{

		@include critical {
			padding-left: 25px;
		}
	}
	&.formError--large {
		font-size: rem(16px);
		line-height: rem(21px);
		padding: 10px 0 0 0;
	}
}

.formSuccess
{

	@include critical {
		background: $formSuccessBackgroundColor;
		color: $formSuccessColor;
	}
}

.formWarning
{

	@include critical {
		background: $formWarningBackgroundColor;
		color: $formWarningColor;
	}
}

.formError 
{

	@include critical {
		background: $formErrorBackgroundColor;
		color: $formErrorColorOnBackground;
	}
	a 
	{

		@include critical {
			color: $formErrorAColor;
		}
		&:hover 
		{

			@include defer {
				color: $formErrorAHoverColor;
			}
		}
	}
	.formRow &,
	.c-form__row &,
	.formChecks &,
	.formRadios & 
	{

		@include critical {
			color: $formRowChecksRadiosErrorColor;
		}
	}
}

// info text
.formInfo 
{

	@include critical {
		color: $formInfoColor;
		clear: both;
		display: block;
		font-size: rem($fontSize - 1);
		line-height: rem($fontHeight - 1);
		margin: 0;
		padding: 5px 0 0 0;
		max-width: 315px;
	}
}

// stripe
.StripeElement {
	@include critical {
		height: auto;
		padding: 10px 8px;
	}
}

.StripeElement__label {
	@include critical {
		display: block;
		padding: 0 0 3px 0;
	}
}

// character counter
.formLabelCounter {
	@include critical {
		display: flex;
		position: relative;
		max-width: 315px;
	}
}

.formLabelCounter__text {
	@include critical {
		margin-left: auto;
		&.formLabelCounter__text--over {
			color: $formRowChecksRadiosErrorColor;
		}
	}
}
