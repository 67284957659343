// COLOURS

$colorBlack: #000 !default; // black
$colorWhite: #fff !default; // white
$colorBase: #4a5050 !default; // primarily used as base font color
$colorDark: #222 !default; // primary used as darker font color
$colorOffWhite: #f4f4f4 !default; // primarily used as a subtle background color for various elements

$colorAlpha: #297c9d !default; // 92 references
$colorBeta: #315062 !default; // 60 references
$colorGamma: #00abee !default; // 29 references
$colorDelta: #cde5ee !default; // 14 references

$colorEmphasise: #d33254 !default; // 63 references (this is red on Tap - errrors / sale etc)

$colorLink: $colorAlpha !default; // 35 references
$colorLinkHover: $colorBase !default; // 11 references

$colorSuccess: #08a500 !default; // 14 references
$colorWarning: #fcdd62 !default; // 2 references

$colorGrey: #999 !default; // 117 references
$colorGreyDark: #666 !default; // 16 references
$colorGreyDarker: #333 !default; // 14 references
$colorGreyLight: #e0e0e0 !default; // 3 references
$colorGreyLightAlt: #dedede !default; // 6 references
$colorGreySafe: #707070 !default;

$colorBorder: #e3e3e3 !default; // 39 references
$colorDivider: #ddd !default; // 78 references

$cTrustpilotCardTitleColor: $colorAlpha !default;

// FONTS AND SIZES

$fontBase: "Source Sans Pro", Trebuchet MS, Helvetica, Arial, Verdana, sans-serif !default;
$fontSize: 14px !default;
$fontHeight: 18px !default;

// BREAKPOINTS

$bpMobileMid: 360px !default;
$bpMobileLarge: 460px !default;
$bpTablet: 540px !default;
$bpTabletLarge: 640px !default;
$bpTabletMassive: 880px !default;
$bpDesktop: 980px !default;
$bpLarge: 1045px !default;
$bpMassive: 1225px !default;
$bpMega: 1420px !default;
$bpExMega: 1565px !default;
$bpMammoth: 1650px !default;

$bpSmallLogoHeaderMax: 350px !default; // Breakpoint to use small logo in header

$bpTwoColProducts: 640px !default; // Breakpoint to flick to 2 products
$bpThreeColProducts: 980px !default; // Breakpoint to flick to 3 products
$bpFourColProducts: $bpMassive !default; // Breakpoint to flick to 4 products

$spriteVersion: "3" !default;
$baseSitePath: "" !default;
