.c-product {

	@include critical {
		background: $cProductBackgroundColor;
		position: relative;
		padding: 0;
		min-height: 100%;
		box-sizing: border-box;
		scroll-margin-top: 80px;
	}

	@include mq-min($bpDesktop) {

		@include critical {
			border: 1px solid transparent;
			box-shadow: none;
			will-change: box-shadow, border;
			transition: box-shadow 0.25s ease, border 0.25s ease;
		}

		@include defer {

			&:hover {
				box-shadow: $cProductHoverBoxShadow;
				border-color: $cProductHoverBorderColor;
			}
		}
	}
}

.c-product {
	@include defer {
		cursor: pointer;
	}
}

.c-product-page--standard .c-product-page__column .c-product-page__row--recommended-extras .c-product {
	@include critical {
		height: 100%;
		min-height: $cRecommendedExtrasPlaceholderHeightSmall;

		@include mq-min($bpTablet) {
			min-height: $cRecommendedExtrasPlaceholderHeightTablet;
		}

		@include mq-min($bpTabletLarge) {
			min-height: $cRecommendedExtrasPlaceholderHeightTabletLarge;
		}

		@include mq-min($bpDesktop) {
			min-height: $cRecommendedExtrasPlaceholderHeightDesktop;
		}

		@include mq-min($bpLarge) {
			min-height: $cRecommendedExtrasPlaceholderHeightLarge;
		}

		@include mq-min($bpMassive) {
			min-height: $cRecommendedExtrasPlaceholderHeightMassive;
		}

		@include mq-min($bpMammoth) {
			min-height: $cRecommendedExtrasPlaceholderHeightMammoth;
		}
	}
}

.c-product-page--standard .c-product-page__column .c-product {
	@include critical {
		height: 100%;
		min-height: $cColumnPlaceholderHeightSmall;

		@include mq-min($bpTablet) {
			min-height: $cColumnPlaceholderHeightTablet;
		}

		@include mq-min($bpDesktop) {
			min-height: $cColumnPlaceholderHeightDesktop;
		}

		@include mq-min($bpLarge) {
			min-height: $cColumnPlaceholderHeightLarge;
		}

		@include mq-min($bpMassive) {
			min-height: $cColumnPlaceholderHeightMassive;
		}

		@include mq-min($bpMammoth) {
			min-height: $cColumnPlaceholderHeightMammoth;
		}
	}
}

.c-product .c-product__inner {

	@include critical {

		@include mq-min($bpDesktop) {
			padding: rem(15px);
		}

		@include mq-min($bpMega) {
			padding-bottom: rem(15px);
		}
	}
}

.c-product__image {

	@include critical {
		display: block;
		margin-bottom: 5px;
	}
}


@include defer {
	@include interaction-mq(hover, true) {
		.c-product__image:hover {
			img {
				@include opacity(1);
			}
		}
	}
}

.c-product__content {

	@include critical {
		padding: 10px 7px;

		@include mq-min($bpDesktop) {
			padding: 0;
		}
	}
}

.c-product__content--no-previews {

	@include critical {
		padding-top: 50px;

		@include mq-min($bpDesktop) {
			padding-top: 52px;
		}
	}
}

.c-product__variants {

	@include critical {
		margin: 0;
		padding: 0;
		font-size: 0;
		line-height: 0;
		container-type: inline-size;
	}
}

.c-product__variants__list {
	margin: 0 -4px;
	padding: 0 0 4px 0;
	display: inline-block;
	vertical-align: top;

	@include mq-max($bpDesktop) {
		.c-cms & {
			padding-left: 7px;
		}
	}
}

.c-product__variants__item {

	@include critical {
		width: 35px;
		display: inline-block;
		margin: 0;
		padding: 4px;
		vertical-align: top;

		.cms &,
		.c-cms & {
			padding-left: 4px !important;

			&:before {
				display: none !important;
			}
		}

		@include mq-max($bpDesktop - 1px) {
			&:nth-child(n+4):not(.c-product__variants__item--more) {
				@container (max-width: 178px) {
					display: none;
				}
			}

			&:nth-child(n+5):not(.c-product__variants__item--more) {
				@container (max-width: 220px) {
					display: none;
				}
			}
		}


		@include mq-min($bpDesktop) {
			width: 40px;

			&:nth-child(n+4):not(.c-product__variants__item--more) {
				@container (max-width: 184px) {
					display: none;
				}
			}

			&:nth-child(n+5):not(.c-product__variants__item--more) {
				@container (max-width: 233px) {
					display: none;
				}
			}
		}
	}
}

.c-product__variants__link {

	@include critical {
		display: block;
		padding: 0;
		height: 35px;
		width: 100%;
		border: 2px solid $cProductVariantsLinkBorderColor;
		box-sizing: border-box;
		transition: border 0.25s ease;
		cursor: pointer;

		* {
			pointer-events: none;
		}
	}

	&:hover,
	&:focus,
	&.is-selected {

		@include defer {
			outline: none;
			border-color: $cProductVariantsLinkActiveBorderColor;
		}
	}

	@include critical {

		@include mq-min($bpDesktop) {
			height: 40px;
		}
	}
}

.c-product__variants__link--active {

	@include defer {
		border-color: $cProductVariantsLinkActiveBorderColor;
	}
}

.c-product__variants__link__text {

	@include critical {
		display: inline-block;
		text-transform: lowercase;
	}
}

.c-product__variants__link__text:first-letter {

	@include critical {
		text-transform: uppercase;
	}
}

.c-product__variants__item--more {

	@include critical {
		width: auto;
	}

	.c-product__variants__link {

		@include critical {
			padding: 8px 9px 7px;
			font-size: rem(15px);
			line-height: rem(18px);
			color: $cProductVariantsMoreTextColor;
			border-width: 1px;
			text-align: center;
			transition: background 0.25s ease, color 0.25s ease;
			height: auto;
		}

		&:hover,
		&:focus {

			@include defer {
				color: $cProductVariantsMoreHoverTextColor;
				background: $cProductVariantsMoreHoverBackgroundColor;
				border-color: $cProductVariantsLinkBorderColor;
			}
		}

		@include critical {

			@include mq-min($bpDesktop) {
				padding: 10px 9px;
			}

			@include mq-min($bpMammoth) {
				min-width: 139px;
			}
		}
	}
}

.c-product__variants__item--more-when-four {

	@include mq-min($bpMammoth) {

		@include critical {
			display: none;
		}
	}
}

.c-product__variants--large {
	@include critical {
		.c-product__variants__item:not(.c-product__variants__item--more),
		.c-product__variants__link:not(.c-product__variants__link--more) {
			width: 40px;
		}

		.c-product__variants__link {
			height: 40px;
		}

		.c-product__variants__item--more .c-product__variants__link {
			padding: 9px;
		}

		@include mq-min($bpMassive) {
			.c-product__variants__item:not(.c-product__variants__item--more),
			.c-product__variants__link:not(.c-product__variants__link--more) {
				width: 44px;
			}

			.c-product__variants__link {
				height: 44px;
			}

			.c-product__variants__item--more .c-product__variants__link {
				padding: 12px;
			}
		}
	}
}


.c-product__title {

	@include critical {
		font-size: rem(15px);
		line-height: rem(22px);
		min-height: 44px;
		padding: 0;
		margin: 0 0 5px 0;

		@include mq-min($bpTwoColProducts) {
			font-size: rem(13px);
			line-height: rem(20px);
			min-height: 60px;
		}

		@include mq-min($bpTablet) {
			font-size: rem(15px);
			line-height: rem(22px);
			min-height: 66px;
		}

		@include mq-min($bpTabletMassive) {
			min-height: 44px;
		}

		@include mq-min($bpDesktop) {
			font-size: rem(16px);
		}
	}
}

.c-product__title__link {

	@include critical {
		font-weight: 600;
		color: $cProductTitleLinkTextColor;
		text-decoration: none;
		display: block;
	}

	&:hover,
	&:focus {

		@include defer {
			text-decoration: underline;
		}
	}
}

.c-product__price {

	@include critical {
		margin: 0 0 7px 0;

		@include mq-min($bpDesktop) {
			margin: 0 0 5px 0;
		}
	}
}

.c-product__footer {

	@include critical {
		display: flex;
		flex-direction: column;
		padding-right: 0;
		min-height: 41px;
		box-sizing: border-box;
	}
}


.c-product__action {

	@include critical {
		position: absolute;
		bottom: 10px;
		right: 10px;

		@include mq-min($bpDesktop) {
			display: block;
			bottom: 15px;
			left: 15px;
			right: 15px;
		}

		@include mq-min($bpMega) {
			left: auto;
			bottom: 40px;
			right: 15px;
			top: auto;
			width: 125px;
			margin: 0;

			.has-scaled-up-text & {
				bottom: 10px;
			}
		}

		.c-button {

			@include mq-max($bpDesktop - 1) {
				padding: 0 10px;
			}
		}
	}
}

.c-product__delivery {

	@include critical {
		margin: 11px 0 0 0;
		padding: 0;

		.c-product__footer--no-reviews & {
			margin-top: auto;
			padding: 0;
		}
	}
}

.c-product__overlay {

	@include critical {
		position: absolute;
		top: -6px;
		left: -6px;

		@include mq-min($bpDesktop) {
			top: 23px;
			left: 23px;
		}
	}
}

.c-product--constrain {
	@include critical {
		@include mq-max($bpDesktop - 1) {
			max-width: 400px;
			margin: 0 auto;
		}

		@include mq-min($bpDesktop) {
			margin: 0 0 14px 0;
		}
	}
}

.c-article .c-product--constrain {
	@include critical {
		margin-bottom: 0;

		@include mq-min(768px) {
			@include mq-max($bpDesktop - 1px) {
				max-width: none;
			}
		}
	}
}

.c-article .c-product,
.c-section--generic-content .c-product,
.c-product--outline {

	@include critical {
		box-shadow: $cProductEmphasiseBoxShadow;
		border-color: $cProductEmphasiseBorderColor;
	}
}

.c-article .c-product {
	@include critical {
		box-shadow: none;
		border: 1px solid $cProductInArticleBorderColor;
	}

	@include defer {
		&:hover {
			box-shadow: none;
			border-color: $cProductInArticleBorderColor;
		}
	}
}

.c-article .c-product__action {
	@include critical {
		@include mq-max($bpDesktop - 1) {
			margin: 0 0 10px 0;
		}
	}
}

.c-article .bge__block--productBlock .c-product,
.c-carousel .c-product,
.c-product--wishlist,
.atb .c-product {

	@include critical {

		.c-product__variants {

			@include mq-max($bpDesktop - 1) {
				padding: 0 7px;
			}
		}

		.c-product__content--no-previews {

			@include mq-max($bpDesktop - 1) {
				padding-top: rem(57px);
			}
		}

		.c-product__footer {

			@include mq-min($bpMega) {
				position: static;
				padding-right: 0;
			}
		}


		.c-product__action {

			@include mq-max($bpDesktop - 1) {
				position: absolute;
				bottom: 7px;
				left: 7px;
				right: 7px;
				margin: 0;
			}

			@include mq-min($bpMega) {
				position: static;
				width: auto;
				margin-top: 10px;
			}
		}

		.c-product__title {
			min-height: 66px;
		}
	}
}

@include critical {

	.c-carousel .c-product .c-product__title {
		margin-bottom: 10px;
	}

	.c-carousel .c-product .c-product__title span {
		position: relative;
		max-height: 66px; /* 22px line-height x 3 lines */
		overflow: hidden;
		display: block;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
	}
}
/*
Mobile and tablet overrides
- Scoped to search and category pages only
*/

@include mq-max(($bpDesktop - 1)) {

	.mRange,
	.c-product-page__pc-alt-products--grid,
	.c-product-page__product-list-grid,
	.c-multiple-product-block__grid {

		@include critical {

			.c-product__inner {
				display: flex;
			}

			.c-product__image-variants {
				width: 45%;
			}

			.c-product__image {
				margin-bottom: 0;
			}

			.c-product__content--no-previews {
				padding-top: 10px;
			}

			.c-product__action {
				position: absolute;
				bottom: 10px;
				right: 10px;
				left: inherit;
				width: calc(55% - 20px);
			}

			.c-product__content {
				flex: 1;
				padding: rem(10px);
			}

			.c-product__title {
				min-height: inherit;
				margin: 0 0 3px 0;
			}

			.c-product__price {
				margin: 0 0 -4px 0;

				.c-price__main {
					margin: 0 0 -6px 0;
					display: inline-block;
				}
			}

			.c-product__overlay {
				position: relative;
				left: inherit;
				top: inherit;
				font-size: 0;
				line-height: 0;
				padding: 12px 0 0 0;

				.c-overlay {
					display: inline-block;
					width: auto;
					height: auto;
					padding: 0 10px;
					margin: 0;
					border-radius: 0;
					transform: none;
					font-size: rem(12px);
					line-height: rem(20px);
					height: rem(20px);
					overflow: hidden;

					&:empty {
						display: none;
					}

					b,
					> .extra,
					> .value,
					> .sign > .percent {
						font-size: rem(12px);
						line-height: rem(20px);
						display: inline-block;
					}

					.br {
						display: inline-block;
						width: 3px;
					}
				}

				.c-overlay--percentOffCodeOverlay,
				.c-overlay--percentOffCodeOverlayRetail {
					height: auto;
					padding: 4px 10px 6px 10px;
					font-size: rem(12px);

					.c-overlay__content-extra,
					.c-overlay__content-percentage,
					.c-overlay__content-use-code {
						font-size: rem(12px);
						display: inline;
					}

					.c-overlay__content-code {
						padding: 0;
					}
				}
			}

			.c-product__overlay + .c-product__delivery {
				margin-top: 11px;
			}

			.c-product__rating {
				padding: 11px 0 0 0;
			}

			.c-product__variants {
				padding: 15px 10px 10px 10px;
			}
		}
	}
}

.c-article .bge__block--productBlock .c-product,
.c-carousel .c-product,
.c-product-page__product-list-grid .c-product,
.c-product--wishlist,
.atb .c-product {

	@include critical {

		.c-product__footer {

			@include mq-min($bpMega) {
				position: static;
				padding-right: 0;
			}
		}

		.c-product__action {

			@include mq-min($bpMega) {
				left: 15px;
				bottom: 15px;
				right: 15px;
				top: auto;
				width: auto;
				position: absolute;
			}
		}
	}
}

@include critical {

	.c-product--sticky-floater {
		border: 0;

		.js-tooltip-trigger-wrapper {
			position: relative;
			top: -2px;
		}

		.js-tooltip {
			top: 0 !important;
			left: 0 !important;
			transform: translate(-50%, -100%);
		}

		.c-product__inner {

			@include mq-min($bpDesktop) {
				padding: 15px;
			}
		}

		.c-product__delivery {
			margin: 0;
		}

		.c-product__delivery__estimate {
			position: relative;
			min-height: 24px;

			&.is-loading {
				min-height: 52px;
			}

			p {
				margin: 0;
				padding: 0;
			}
		}

		.c-product__delivery__estimate__icon {
			position: absolute;
			top: 5px;
			left: 0;
			width: 30px;
			height: 19px;
			color: $cProductDeliveryEstimateIconColor;
		}

		.c-product__delivery__estimate__stock--in {
			color: $cProductDeliveryEstimateStockInColor;
		}

		.c-product__content {
			display: flex;
			align-items: flex-start;
			margin-bottom: 10px;
		}

		.c-product__price {
			margin: 0 0 0 auto;
			padding: 0 0 0 20px;
			text-align: right;
		}

		.c-price__main,
		.c-price__was {
			display: block;
			font-size: rem(34px);
			line-height: rem(34px);
			font-weight: 600;
			text-align: right;

			.pence {
				font-size: rem(34px);
				line-height: rem(34px);
			}
		}

		.c-price__main__from {
			line-height: 1;
		}

		.c-product__overlay {
			z-index: 100;
		}
	}
}

@include critical {

	.c-product--with-dynamic-add,
	.c-carousel .c-product--with-dynamic-add,
	.c-product-page__product-list-grid .c-product--with-dynamic-add {

		@include mq-max($bpDesktop - 1) {
			padding-bottom: 74px;
		}

		@include mq-min($bpDesktop) {

			.c-product__inner {
				padding-bottom: rem(103px);
			}
		}
	}
}

@include critical {
	.c-product--basket-you-may-also-like.c-product--with-dynamic-add {
		padding-bottom: rem(54px);

		@include mq-min($bpDesktop) {

			.c-product__inner {
				padding-bottom: rem(20px);
			}
		}
	}
}

@include critical {
	.c-product--range {
		border: none;

		.c-product__inner {
			@include mq-min($bpDesktop) {
				position: absolute;
				bottom: 0;
				top: 0;
			}
		}
	}
}


.c-product__title__link--range {
	@include critical {
		@include rem('font-size', 16px);
		@include rem('line-height', 22px);
		@include rem('margin-bottom', 10px);
	}
}

.c-product__image--range {
	@include critical {
		@include rem('margin-bottom', 20px);
	}
}

.c-product--range-alt {
	@include critical {
		.c-product__title__link--range {
			color: #FFF;
			font-size: 1.25rem;
			line-height: 1.2;
			text-shadow: 1px 1px 1px rgba(0,0,0,0.7);

			@include mq-min($bpDesktop) {
				font-size: 1.75rem;
			}
		}

		.c-product__content {
			@include mq-max($bpDesktop) {
				@include rem('padding', 15px);
			}
		}
	}
}

.c-product__wishlist-button {
	@include critical {
		position: relative;
	}
}

.c-product__wishlist-button--top {
	@include critical {
		position: relative;

		@include mq-min($bpDesktop) {
			position: absolute;
			top: 15px;
			right: 15px;
			z-index: 2;
		}
	}
}

.c-product__price-wishlist-group {
	@include critical {
		display: grid;
		align-items: center;
		grid-template-columns: 1fr 34px;
		gap: 10px;
		margin-top: 10px;

		.c-wishlist-add-button {
			transform: translateY(-5px);
		}
	}
}

.c-product--wishlist {
	@include critical {

		@include mq-max($bpDesktop - 1) {
			padding-bottom: rem(47px);
		}

		.c-add-product {
			min-height: 0;
		}

		.c-add-product .c-add-product__controls {
			box-sizing: border-box;
			width: 135px;
		}

		.c-add-product__message-error {
			padding: 0;
			margin-top: 5px;
			white-space: nowrap;
		}

		.c-product__inner {
			padding-bottom: 70px;

			@include mq-min($bpDesktop) {
				padding-bottom: 135px;
			}

			@include mq-min($bpMega) {
				padding-bottom: 115px;
			}
		}

		.c-product__action {
			display: block;
		}

		@media screen and (max-width: 767px) {
			.c-product__title {
				min-height: 0;
			}
		}

		.c-product__footer {

			@include mq-min($bpMega) {
				margin-bottom: 20px; /* Space for quantity error message */
			}
		}
	}

	@include defer {
		transition: filter 300ms;
	}
}

.c-product--wishlist.is-loading {
	@include defer {
		filter: blur(3px);
	}
}

.c-product__wishlist-placeholder {
	@include critical {
		height: 620px;
		width: 100%;
		background-color: #dedede;
	}
}


.mRange product-card, .mRange .c-product-card__placeholder {
	@include critical {
		display: block;
		background-color: #FFF;
		min-height: $cProductCategoryPlaceholderHeightSmall;
		height: 100%;

		@include mq-min(390px) {
			min-height: $cProductCategoryPlaceholderHeight390;
		}

		@include mq-min(430px) {
			min-height: $cProductCategoryPlaceholderHeight430;
		}

		@include mq-max($bpDesktop - 1) {
			overflow-x: hidden;
		}

		@include mq-min($bpTabletLarge) {
			min-height: $cProductCategoryPlaceholderHeightTabletLarge;
		}

		@include mq-min($bpDesktop) {
			min-height: $cProductCategoryPlaceholderHeightDesktop;
		}

		@include mq-min($bpLarge) {
			min-height: $cProductCategoryPlaceholderHeightLarge;
		}

		@include mq-min($bpMassive) {
			min-height: $cProductCategoryPlaceholderHeightMassive;
		}

		@include mq-min($bpMammoth) {
			min-height: $cProductCategoryPlaceholderHeightMammoth;
		}
	}
}


.c-product__image product-viewer-open-button {
	@include critical {
		display: none !important;
	}

	@include defer {
		position: absolute;
		z-index: 100;
		top: calc(50% - 20px);
		left: 0;
		right: 0;
		width: auto;
		margin: auto;
		text-align: center;
	}
}

.c-product__preview-button {
	@include defer {
		display: inline-block;
		width: auto;
	}
}


@include defer {
	@-webkit-keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}
}

@media (hover: hover) {
	.c-product__image:hover {
		@include defer {
			product-viewer-open-button {
				display: block !important;
				-webkit-animation: fadeIn 0.25s;
				animation: fadeIn 0.25s;
			}
		}
	}
}
