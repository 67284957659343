﻿@include defer {

	@keyframes loaderAnimation {
		to {
			transform: rotate(1turn);
		}
	}

	.c-loader {
		position: relative;
		display: block;
		width: rem(24px);
		height: rem(24px);
		overflow: hidden;
		animation: loaderAnimation 1s infinite steps(8) !important;
		font-size: rem(6px);
		margin: 0 auto 0.5rem auto;
	}

	.c-loader--inline {
		display: inline-block;
		vertical-align: middle;
	}

	.c-loader:before,
	.c-loader:after,
	.c-loader > div:before,
	.c-loader > div:after {
		content: '';
		position: absolute;
		top: 0;
		left: rem(11px); /* (container width - part width)/2  */
		width: rem(2px);
		height: rem(7px);
		border-radius: rem(1px);
		background: #eee;
		box-shadow: 0 rem(17px) #eee; /* container height - part height */
		transform-origin: 50% rem(12px); /* container height / 2 */
	}

	.c-loader:before {
		background: #555;
	}

	.c-loader:after {
		transform: rotate(-45deg);
		background: #777;
	}

	.c-loader > div:before {
		transform: rotate(-90deg);
		background: #999;
	}

	.c-loader > div:after {
		transform: rotate(-135deg);
		background: #bbb;
	}

	.c-loader--large {
		width: rem(48px);
		height: rem(48px);
	}

	.c-loader--large:before,
	.c-loader--large:after,
	.c-loader--large > div:before,
	.c-loader--large > div:after {
		left: rem(22px); /* (container width - part width)/2  */
		width: rem(4px);
		height: rem(14px);
		box-shadow: 0 rem(34px) #eee; /* container height - part height */
		transform-origin: 50% rem(24px); /* container height / 2 */
	}

	.c-loader__full-overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(40, 40, 40, 0.75);
		z-index: 9999;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.c-loader__full-overlay-content {
		padding: rem(20px);
		margin: rem(10px);
		background-color: #FFF;
		max-width: 375px;
	}

	.c-loader__full-overlay-loader {
		margin: rem(20px) auto;
	}

	.c-loader__full-overlay-text--title {
		font-size: rem(19px);
		padding: 0;

		@include mq-min($bpDesktop) {
			font-size: rem(22px);
		}
	}

	.c-loader__full-overlay-text--body {
		font-size: rem(18px);
		line-height: 1.2;
		padding: 0;

		@include mq-min($bpDesktop) {
			font-size: rem(19px);
		}
	}
}
