.c-skip {

	@include critical {
		@include accessible-hide();
	}

	&:focus {

		@include defer {
			@include accessible-show();
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			background: $colorBlack;
			color: $colorWhite;
			padding: 10px 20px;
			z-index: 99999;
		}
	}
}

.c-skip--inline:focus {
	@include defer {
		position: relative;
		top: auto;
		left: auto;
		margin: 0 0 10px 0;
	}
}
