@include critical {

	#popupFade,
	.mPopup {
		display: none;
	}
}


@include defer {

	#popupFade 
	{
		background: $mPopupFadeBackgroundColor;
		height: 100%;
		left: 0;
		margin: 0;
		@include opacity(.4);
		padding: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 9990;
	}

	.mPopup 
	{
		background: $mPopupBackgroundColor;
		@include box-shadow($mPopupBoxShadow);
		left: 50%;
		margin: 0 0 0 -320px;
		position: absolute;
		padding: 35px 0;
		top: 50px;
		width: 640px;
		z-index: 9999999999;
		&Inner 
		{
			@include min-height(200px);
			margin: 0 20px;
			padding: 0 10px;
			text-align: left;
			iframe 
			{
				display: block;
				height: 360px;
				margin: 0 auto;
				padding: 0;
				width: 480px;
			}
		}
		&Gallery
		{
			margin: 0 auto;
			padding: 0;
			position: relative;
			max-width: 800px;
			z-index: 1;
			&Menu
			{
				list-style: none;
				margin: 0;
				padding: 0;
				.level1
				{
					display: none;
					list-style: none;
					margin: 0;
					padding: 0;
					> .image
					{
						display: block;
						height: auto;
						margin: 0;
						padding: 0;
						width: auto;
					}
					&:first-child
					{
						display: block;
					}
				}
			}
			.bx-pager
			{
				font-size: 0;
				line-height: 0;
				margin: 0;
				padding: 7px 0 0 0;
				text-align: center;
				&-item
				{
					display: inline-block;
					margin: 0;
					padding: 1px;
				}
				&-link
				{
					background: $mPopupGalleryLinkBackgroundColor;
					border: 4px solid $mPopupGalleryLinkBorderColor;
					@include border-radius(100%);
					display: block;
					height: 10px;
					margin: 0;
					padding: 0;
					@include text-hidden();
					@include transition(background .5s);
					width: 10px;
					&:hover
					{
						background: $mPopupGalleryLinkHoverBackgroundColor;
					}
					&.active
					{
						background: $mPopupGalleryLinkActiveBackgroundColor;
					}
				}
			}
			.bx-prev, .bx-next
			{
				background: $mPopupGalleryPrevNextBackgroundColor;
				display: block;
				height: 47px;
				margin: -38px 0 0 0;
				padding: 15px 10px;
				position: absolute;
				@include text-hidden();
				top: 50%;
				width: 24px;
				z-index: 1000;
				&:before
				{
					background: $mPopupGalleryPrevNextBeforeBackgroundUrl no-repeat 0 0;
					content: "";
					display: block;
					height: 47px;
					left: 50%;
					margin: 0 0 0 -12px;
					padding: 0;
					position: absolute;
					top: 16px;
					width: 24px;
					z-index: 1;
				}
			}
			.bx-prev
			{
				left: -10px;
			}
			.bx-next
			{
				right: -10px;
				&:before
				{
					background-position: -24px 0;
				}
			}
		}
		&Close 
		{
			background: $mPopupCloseBackgroundUrl no-repeat 0 0;
			display: block;
			height: 24px;
			margin: 0;
			overflow: hidden;
			padding: 0;
			position: absolute;
			right: 20px;
			top: 20px;
			width: 24px;
			z-index: 1;
			border: none;
			&:hover 
			{
				@include opacity(.5);
			}
		}
	}

	#popupLoader 
	{
		background: $mPopupLoaderBackgroundColor $mPopupLoaderBackgroundUrl no-repeat 50% 50%;
		height: 60px;
		margin: -30px 0 0 -30px;
		padding: 0;
		position: fixed;
		top: 50%;
		width: 60px;
	}

	#popupVideo 
	{
		margin-left: -250px;
		padding: 0;
		width: 500px;
		&Title 
		{
			font-size: rem($fontSize + 8);
			height: 30px;
			left: 10px;
			line-height: rem($fontSize + 8);
			margin: 0;
			padding: 8px 0 0 0;
			position: absolute;
			@include text-overflow();
			top: 0;
			width: 448px;
			z-index: 1;
		}
		.mPopup 
		{
			&Inner 
			{
				margin: 0;
				padding: 44px 10px 10px 10px;
				.touch & 
				{
					max-height: none;
					overflow: visible;
				}
			}
			&Close 
			{
				right: 10px;
				top: 10px;
			}
		}
	}

	#popupGallery 
	{
		padding: 42px 0;
		.mPopup 
		{
			&Close 
			{
				right: 10px;
				top: 10px;
			}
		}
		@include mq-max($bpTabletMassive - 1)
		{
			margin: 0;
			left: 15px;
			right: 15px;
			width: auto;
		}
		@include mq-min($bpTabletMassive)
		{
			margin-left: -400px;
			width: 800px;
		}
		@include mq-min($bpTabletMassive + 50)
		{
			margin-left: -430px;
			width: 860px;
		}
	}

	#popupConfirm 
	{
		margin-left: -140px;
		padding: 60px 0 10px 0;
		width: 280px;
		&Title 
		{
			font-size: rem($fontSize + 8);
			line-height: rem($fontHeight + 8);
			left: 15px;
			margin: 0;
			overflow: hidden;
			padding: 0;
			position: absolute;
			top: 17px;
			z-index: 1;
		}
		.mPopup 
		{
			&Inner 
			{
				margin: 0;
				padding: 0 15px;
				@include min-height(50px);
			}
		}
	}
}
