@include critical {

	.c-image {
		@include ratio(16,9);
		position: relative;
		display: block;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;

			&[loading="lazy"] { /* Stop alt text flash in Firefox */
				color: white;
			}
		}
	}

	.c-image--natural {
		padding-top: 0;
		position: static;
		display: block;

		img {
			position: static;
			width: 100%;
			height: auto;
		}
	}

	.c-image--original-width {
		padding-top: 0;
		position: static;
		display: inline-block;

		img {
			position: static;
			width: auto;
			height: auto;
		}
	}

	.c-image--square {
		@include ratio(1,1);
	}

	.c-image--feature {
		@include ratio(495,281);
	}

	.c-image--blog {
		@include ratio(455,256);
	}

	.c-image--banner-grid-large {
		@include ratio(612, 790);

		@include mq-min($bpTabletMassive) {
			@include ratio(1010, 621);
		}
	}

	.c-image--lazy:not(.c-image--no-background) {

		.js & {
			background: $cImageLazyBackground;
			background-repeat: no-repeat;
			background-size: 50% auto;
			background-position: 50% 50%;

			img {
				opacity: 1;
			}
		}
	}

	.c-image__hover-image {
		display: none;
	}

	.c-image--rounded {

		img {
			border-radius: 100%;
		}
	}
}

@include defer {

	@-webkit-keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	@include interaction-mq(hover, true) {
		.c-image--hover,
		.js .c-image--hover {

			&:hover {

				.c-image__hover-image {
					display: block;
					-webkit-animation: fadeIn 0.25s;
					animation: fadeIn 0.25s;
				}
			}
		}
	}
}
