@use "sass:color";

///////////////
// OVERRIDES //
///////////////

// IMAGE ROOT

$baseSitePath: "" !default;
$imageRoot: $baseSitePath + '/images/overrides/onlyradiators/';
$spriteVersion: "3";

// GLOBAL

$colorBase: #373737;

$colorWhite: #fff;
$colorOffWhite: #f4f4f4;

$colorAlpha: #dd3636;
$colorBeta: #dd3636;
$colorGamma: #dd3636;
$colorDelta: #dd3636;

$colorEmphasise: $colorAlpha;

$colorLink: $colorBase;
$colorLinkHover: #9B9B9B;

$fontBase: "MuseoSansRounded-300", Helvetica, Arial, Verdana, sans-serif;

$colorAlphaLight: #E5572B; // radiators specific

// RESET

$resetHeadingFontFamily: "MuseoSlab-700";
$resetHeadingFontWeight: normal;
$resetHeading1FontWeight: normal;
$resetHeading2FontWeight: normal;
$resetHeading3FontWeight: normal;
$resetHeading4FontWeight: normal;
$resetHeading5FontWeight: normal;
$resetHeading6FontWeight: normal;
$resetStrongFontWeight: normal;
$resetStrongFontFamily: "MuseoSansRounded-700";
$resetBoldestFontWeight: normal;
$resetBoldestFontFamily: $resetStrongFontFamily;
$resetTableThTdColor: $colorBase;
$resetCMSLinkTextDecoration: underline;

// FORMS

// _forms.scss

$formButtonActionBackgroundColor: $colorBase;
$formButtonActionHoverBackgroundColor: #9B9B9B;

$formButtonBackgroundColor: $colorBase;
$formButtonHoverBackgroundColor: #9B9B9B;
$formButtonFocusBackgroundColor: #9B9B9B;
$formButtonActionBackgroundColor: $colorBase;
$formButtonActionHoverBackgroundColor: #9B9B9B;

$formButtonCheckoutBackgroundColor: $colorBase;
$formButtonCheckoutHoverBackgroundColor: #9B9B9B;

$formTextTextareaSelectUploadBorderColor: #9B9B9B;
$formTextTextareaSelectUploadFocusBorderColor: #373737; 

$formInfoColor: $colorBase;

$formTextTextareaSelectUploadColor: $colorBase;

$formUmbracoBackgroundColor: transparent;

// LAYOUT

// layout/_back-to-top.scss

$lBackToTopPrimaryBackgroundUrl: url(#{$imageRoot}layout/back-to-top/arrow.png) !default;

// LEGACY MODULES

// modules/_typeahead.scss

$mTypeaheadMenuBackgroundColor: $colorOffWhite;
$mTypeaheadMenuBeforeBorderColor: $colorOffWhite;
$mTypeaheadMenuSuggestionLinkHoverColor: $colorBase;
$mTypeaheadMenuSuggestionLinkCategoryColor: $colorBase;
$mTypeaheadMenuSuggestionLinkCategoryHighlightColor: $colorBase;
$mTypeaheadMenuSuggestionProductContentTitleHoverColor: $colorBase;
$mTypeaheadMenuSuggestionProductHoverBackgroundColor: $colorWhite;
$mTypeaheadMenuSuggestionLinkHoverBackgroundColor: $colorWhite;
$mTypeaheadMenuSuggestionLinkHighlightHoverColor: $colorBase;
$mTypeaheadMenuHighlightColor: $colorBase;
$mTypeaheadMenuFooterLinkBackgroundColor: $colorBase;
$mTypeaheadMenuFooterLinkHoverBackgroundColor: #9B9B9B;
$mTypeaheadMenuSuggestionTTCursorLinkBackgroundColor: $colorWhite;
$mTypeaheadMenuSuggestionTTCursorProductBackgroundColor: $colorWhite;

// modules/_filter-slide-out.scss

$mFilterCloseContainerBackgroundColor: $colorBase;
$mFilterTitleColor: $colorBase; 
$mFilterSlideOutControlsOpenLinkBackgroundColor: #878787;
$mFilterCloseContainerCloseLinkBeforeBackgroundUrl: url(#{$imageRoot}modules/filter/sprite-filter-slide-out.png) !default;
$mFilterSubmitBackgroundColor: $colorBase;
$mFilterSlideOutControlsSortHeight: 35px;

// modules/_filter.scss

$mFilterTitleBeforeMobileTabletBackgroundUrl: url(#{$imageRoot}modules/filter/sprite-filter-slide-out.png);
$mFilterFormChecksRadiosInputCheckedSpanBeforeBackgroundUrl: url(#{$imageRoot}modules/filter/sprite-filter-slide-out.png);
$mFilterFormChecksImageSpanBeforeBackgroundUrl: url(#{$imageRoot}modules/filter/sprite-filter-slide-out.png);
$mFilterShowMoreAfterBackgroundUrl: url(#{$imageRoot}modules/filter/sprite-filter-slide-out.png);

// modules/_add-to-basket-overlay.scss

$mAtbMainHeaderMessageBeforeBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbMainAddedMessageBeforeBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbMainFooterBasketButtonBeforeBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbMainFooterCloseBeforeBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbDetailItemPrevNextBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbDetailItemSpecsTitleAfterBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbQuantityAddAfterBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbQuantityWidgetButtonAfterBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbQuantityWidgetButtonHoverBackgroundColor: $formButtonHoverBackgroundColor;
$mAtbAddedMessageBeforeBackgroundUrl: url(#{$imageRoot}modules/atb/sprite.png?v=#{$spriteVersion});
$mAtbQuantityWidgetButtonBackgroundColor: $colorLink;
$mAtbMainProductActionViewColor: $colorLink;
$mAtbItemActionLinkColor: $colorLink;
$mAtbItemActionLinkColorHover: $colorLinkHover;

// modules/_popups.scss

$mPopupCloseBackgroundUrl: url(#{$imageRoot}misc/close.png) !default;

// modules/_basket.scss
$mBasketTableTdThDetailsComponentsColor: $colorBase;
$mBasketAddressesTitleColor: $colorBase;
$mBasketAddressesCardDtColor: $colorBase;
$mBasketAddressesMessageColor: $colorBase;

// modules/_calculator.scss

$mCalculatorFormResultsItemResultColor: $colorAlpha !default;

// modules/_js-tooltip.scss

$mJSTooltipTriggerBorderColor: $colorBase;
$mJSTooltipTriggerBackgroundColor: $colorBase;
$mJSTooltipTriggerHoverBeforeColor: $colorBase;

// modules/_block.scss

$mBlockQuietTitleColor: $colorBase;

// modules/category-page.scss

$mCategoryPageContentBlockHeaderColor: $colorBase;

// modules/_full-width-block.scss

$mFullWidthBlockTitleColor: $colorBase;

// modules/_need-some-help.scss

$mNeedSomeHelpColor: $colorBase;

// scripts/_jquery.gibegallery.scss

$gibe-gallery-overlay-primary-color: $colorGamma; 

// NEW COMPONENTS

// components/add-product.scss
$cAddProductControlsTextFontWeight: normal;

// components/_alert-message.scss
$cAlertMessageInfoBackgroundColor: #FFB3B3;

// components/_button.scss

$cButtonBackgroundColor: $colorBase;
$cButtonHoverBackgroundColor: #9B9B9B;
$cButtonCtaOnDarkTextColor: #e5572b;
$cButtonCtaOnDarkHoverTextColor: #e5572b;
$cButtonSecondaryBorderColor: $colorBase;
$cButtonSecondaryHoverBackgroundColor: $colorBase;
$cButtonSecondaryHoverBorderColor: $colorBase;
$cButtonSecondaryCheckedBackgroundColor: $colorBase;
$cButtonSecondaryCheckedHoverBorderColor: $colorBase;
$cButtonEmphasizeBackgroundColor: $colorBase;
$cButtonEmphasizeHoverBackgroundColor: #9B9B9B;
$cButtonDarkBackgroundColor: #373737;

// components/_footer.scss

$cFooterBottomBackgroundColor: #f0f0f0;
$cFooterLinkTextColor: #32312f;
$cFooterLinkHoverTextColor: #db383b;
$cFooterLinkTitleTextColor: #32312f;
$cFooterSocialLinkTextColor: $colorAlpha;
$cFooterSocialLinkHoverTextColor: #e36365;
$cFooterInlineListLinkTextColor: $colorBase;
$cFooterCopyrightTextColor: $colorBase;
$cFooterLinkAltTextColor: $colorBase;
$cFooterContactLinkTextColor: #32312f;

// components/_header.scss

$cHeaderTopDesktopBackgroundColor: $colorWhite;
$cHeaderCallTextColor: $colorBase;
$cHeaderCallLinkTextColor: $colorBase;
$cHeaderQuickLinksDividerColor: #878787;
$cHeaderQuickLinksTextColor: $colorBase;
$cHeaderMiddleBackgroundColor: $colorWhite;
$cHeaderSearchTextColor: $colorBase;
$cHeaderSearchTextBackgroundColor: #f0f0f0;
$cHeaderSearchBorderColor: $colorBase;
$cHeaderSearchFocusBorderColor: $colorAlpha;
$cHeaderUtilsLinkTextColor: $colorBase;
$cHeaderUtilsDesktopCountTextColor: $colorBase;
$cHeaderSearchToggleIconColor: $colorBase;
$cHeaderSearchButtonBackgroundColor: $colorBase;
$cHeaderSearchWidth: 250px;
$cHeaderSearchWidthBpLarge: 300px;

// components/_image.scss

$cImageLazyBackground: $colorWhite url(#{$imageRoot}components/image/lazy.svg) !default;

// components/_newsletter-signup.scss

$cNewsletterTitleTextColor: #32312f;
$cNewsletterSignupIntroTextColor: #32312f;
$cNewsletterSignupButtonBackgroundColor: #db383b;
$cNewsletterSignupButtonHoverBackgroundColor: #e36365;
$cNewsletterSignupInputFocusBorderColor: $colorAlpha;

// components/_usps.scss

$cUSPSDesktopLinkTextColor: $colorBase;
$cUSPSLinkHoverTextColor: #878787;
$cUSPSDesktopLinkHoverTextColor: $colorAlpha;
$cUSPSBackgroundColor: $colorBase;
$cUSPSDesktopBackgroundColor: $colorBase;

$cUSPSInlineBp: 1200px;
$cUSPSMaxWidth: 100%;

// components/_price.scss

$cPriceMainSaleTextColor: $colorEmphasise;
$cPriceRRPTextColor: $colorBase;

// components/_delivery.scss

$cDeliveryTextColor: $colorBase;
$cDeliveryUnavailableBackgroundUrl: url(#{$baseSitePath}/images/overrides/onlyradiators/components/delivery/unavailable.svg);

// components/_nav.scss

$cNavBackground: $colorBase;
$cNavMobileBackgroundImage: none;
$cNavTitleBackgroundImage: url(#{$imageRoot}components/nav/toggle.svg);
$cNavLinkHoverColor: $colorBase;
$cNavSiteWrapperCoverBackground: $colorBase;
$cNavDesktopBackgroundColor: $colorWhite;
$cNavSecondaryBackground: #555;
$cNavMegaNavTitleColor: $colorBase;
$cNavMegaNavListNoLinkColor: $colorBase;
$cNavMegaNavListTitleNoLinkColor: $colorBase;
$cNavMegaNavListLinkColor: $colorBase;
$cNavMegaNavListTitleLinkColor: $colorBase;
$cNavMegaNavListTitleColor: $colorBase;
$cNavMegaNavDimmerBackgroundColor: $colorBase;
$cNavItemSaleBackgroundColor: $colorAlpha;
$cNavItemSaleHoverBackgroundColor: color.adjust($cNavItemSaleBackgroundColor, $lightness: -7%, $space: hsl);
$cNavItemSaleBorderTopColor: color.adjust($colorAlpha, $lightness: 10%, $space: hsl);
$cNavItemSaleBorderBottomColor: color.adjust($colorAlpha, $lightness: -10%, $space: hsl);
$cNavItemSaleMoreBorderColor1: color.adjust($colorAlpha, $lightness: -10%, $space: hsl); 
$cNavItemSaleMoreBorderColor2: color.adjust($colorAlpha, $lightness: 10%, $space: hsl);
$cNavItemFeatureBackgroundColor: #FFCB3F;
$cNavItemFeatureHoverBackgroundColor: color.adjust($cNavItemFeatureBackgroundColor, $lightness: -7%, $space: hsl);
$cNavItemFeatureFontColor: $colorBase;
$cNavItemFeatureBorderTopColor: color.adjust($cNavItemFeatureBackgroundColor, $lightness: 10%, $space: hsl);
$cNavItemFeatureBorderBottomColor: color.adjust($cNavItemFeatureBackgroundColor, $lightness: -10%, $space: hsl); 
$cNavItemAltBackgroundColor: $colorBase;
$cNavMobileRenameThisBackgroundColor: #696969;

$cNavItemBorderColorOne: #444;
$cNavItemBorderColorTwo: #2a2a2a;
$cNavItemBorderColorThree: #515151;
$cNavItemBorderColorFour: $colorBase;
$cNavItemBorderColorFive: #5d5d5d;
$cNavItemBorderColorSix: #444;
$cNavItemBorderColorSeven: #2a2a2a;
$cNavItemBorderColorEight: #515151;
$cNavItemBorderColorNine: $colorBase;

// components/_highlight-card.scss

$cHighlightCardBackgroundColor: $colorAlphaLight;

// components/_article-card.scss

$cArticleCardAuthorColor: $colorAlphaLight;
$cArticleCardCategoryColor: $colorAlphaLight;
$cArticleSummaryFontWeight: normal;

// components/_article.scss

$cArticleMetaColor: $colorAlphaLight;

// components/_blog-nav.scss

$cBlogNavLinkMobileColor: $colorAlphaLight;

// components/_product-page.scss

$cProductPageMainPriceColor: #dd3636;
$cProductPageLinkColor: $colorBase;

$cProductCategoryPlaceholderHeightSmall: 240.75px; // < 375
$cProductCategoryPlaceholderHeight390: 247.5px; // 390
$cProductCategoryPlaceholderHeight430: 265.5px; // 430
$cProductCategoryPlaceholderHeightTabletLarge: 259px; // 640
$cProductCategoryPlaceholderHeightDesktop: 445px; // 980
$cProductCategoryPlaceholderHeightLarge: 529.66px; // 1045
$cProductCategoryPlaceholderHeightMassive: 570px; // 1225
$cProductCategoryPlaceholderHeightMammoth: 625.66px; // 1650

// components/_rating.scss

$cRatingTextColor: $colorBase;
$cRatingLinkColor: $colorBase;

// components/_section.scss

$cSectionSplashBackgroundColor: #FFF1CA;

// components/_carousel-paging.scss

$cCarouselPagingMessageFontWeight: normal;

// components/_overlay.scss

$cOverlayCyberSaleBackgroundColor: #F7CDCD;
$cOverlayCyberSaleTextColor: #373737;
$cOverlayCyberMondayBackgroundColor: #F7CDCD;
$cOverlayCyberMondayTextColor: #373737;
$cOverlayPromoCodeBackgroundColor: #ffffff;
$cOverlayPromoCodeBorderColor: #373737;
$cOverlayPromoCodeTextColor: #c52424;
$cOverlayPercentOffCodeBackgroundColor: #C52424;

// components/_carousel.scss

$cCarouselAlternativePrevNextBackgroundUrl: url(#{$imageRoot}modules/top-sellers/arrows.png);
$cCarouselButtonBackgroundImage: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbDpzcGFjZT0icHJlc2VydmUiIHZpZXdCb3g9IjAgMCA0MCA0MCIgaGVpZ2h0PSI0MCIgd2lkdGg9IjQwIj4NCgk8Y2lyY2xlIGN4PSIyMCIgY3k9IjIwIiByPSIxOC43IiBmaWxsPSIjZmZmIiBzdHJva2U9IiMzNzM3MzciIHN0cm9rZS1taXRlcmxpbWl0PSIyLjE0MyIgc3Ryb2tlLXdpZHRoPSIyLjU3MiIvPg0KCTxwYXRoIGZpbGw9IiMzNzM3MzciIGQ9Ik0xNS43IDMyLjFjLS40IDAtLjctLjEtMS0uNC0uNS0uNS0uNS0xLjQgMC0ybDkuNy05LjctOS43LTkuN2MtLjUtLjUtLjUtMS40IDAtMiAuNS0uNSAxLjQtLjUgMiAwTDI3LjMgMTljLjUuNS41IDEuNCAwIDJMMTYuNyAzMS42Yy0uMy4zLS43LjUtMSAuNXoiLz4NCjwvc3ZnPg==");;

// components/_checkout-card.scss

$cCheckoutCardLinkColor: #373737;
$cCheckoutCardLinkColorHover: #000;

// components/_checkout-header.scss

$cCheckoutHeaderBackgroundColor: #FFF;
$cCheckoutHeaderFontColor: #373737;
$cCheckoutHeaderFontFamily: $resetHeadingFontFamily;

// components/_checkout-form.scss

$cCheckoutFormInputFontFamily: MuseoSansRounded-300,Helvetica,Arial,Verdana,sans-serif;
$cCheckoutFormInputColor: #373737;
$cCheckoutFormInputFocusBorderColor: #373737;
$cCheckoutFormRadioColor: #373737;
$cCheckoutFormCheckoutColor: #373737;
$cCheckoutFormAddInputColor: #373737;
$cCheckoutFormAddInputColorHover: #000;

// components/_checkout-step-indicator.scss

$cCheckoutStepIndicatorColor: #dd3636;
$cCheckoutStepIndicatorFontSize: 25px;

// components/checkout/_checkout-overlay.scss

$cCheckoutOverlayCloseButtonColor: #373737;

// components/checkout/_checkout-tooltip.scss

$cCheckoutTooltipBackgroundColor: #373737;
$cCheckoutTooltipCloseColor: #373737;

// components/checkout/_checkout-payment-selector.scss

$cCheckoutPaymentSelectorBackgroundColor: #373737;

// components/_card.scss

$cCardTitleLinkColor: $colorBase;
$cCardPinnedLinkColor: $colorAlphaLight;

// components/_sidebar-nav.scss

$cSidebarNavLinkColor: $colorBase !default;
$cSidebarNavLinkColorActive: $colorBase !default;

//////////////////////////////////////////
// ADDITIONAL COLOURS NOT PRESENT IN TW //
//////////////////////////////////////////
// overrides/components/_header.scss
$overrideHeaderUtilsCountColor: $colorWhite;
$overrideHeaderCallLinkHoverFocusColor: $colorAlpha;
$overrideHeaderQuickLinksLinkHoverFocusColor: $colorAlpha;
$overrideHeaderGenericLinkHoverFocusColor: $colorAlpha;
$overrideHeaderUtilsSubLinkHoverFocusColor: $colorWhite;
$overrideHeaderCallHoursColor: $colorBase;

// overrides/components/_footer.scss

$overrideFooterTopBackgroundColor: #e0e0e0;
$overrideFooterSocialContactLinkColor: #e36365;
$overrideFooterTitleColor: $colorBase;

// components/_nav.scss

$overrideNavSaleHoverBackgroundColor: #e14c4c;

// components/_cms.scss

$cCmsLinkTextDecoration: underline;

// components/_battenberg.scss

$cBattenbergBackgroundColor1: #ffffff !default;
$cBattenbergBackgroundColor2: #f8d6d6 !default;
$cBattenbergBackgroundColor3: #373737 !default;
$cBattenbergBackgroundColor4: #e5572b !default;

// components/_trustpilot-card.scss

$cTrustpilotCardTitleColor: $colorBase;

// components/_pager.scss

$cPagerSelectHeight: 34px;


// components/_load-content-placeholder.scss
$cColumnPlaceholderHeightSmall: 467px;
$cColumnPlaceholderHeightTablet: 473px;
$cColumnPlaceholderHeightDesktop: 451px;
$cColumnPlaceholderHeightLarge: 469px;
$cColumnPlaceholderHeightMassive: 481px;
$cColumnPlaceholderHeightMammoth: 482px;

$cRecommendedExtrasPlaceholderHeightSmall: 435px;
$cRecommendedExtrasPlaceholderHeightTablet: 520px;
$cRecommendedExtrasPlaceholderHeightTabletLarge: 510px;
$cRecommendedExtrasPlaceholderHeightDesktop: 520px;
$cRecommendedExtrasPlaceholderHeightLarge: 550px;
$cRecommendedExtrasPlaceholderHeightMassive: 530px;
$cRecommendedExtrasPlaceholderHeightMammoth: 570px;