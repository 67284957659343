﻿.c-carousel-paging__container {

	@include defer {

		@include mq-min($bpDesktop) {
			position: relative;
			padding-right: 100px;
		}
	}
}

.c-carousel-paging__container--paging-only.has-pagination {
	@include critical {
		@include mq-min($bpDesktop) {
			min-height: 52px;
		}
	}
}

.c-carousel-paging__container--recommended-extras.has-pagination {
	@include critical {
		@include mq-min($bpDesktop) {
			margin-top: -30px;
		}
	}
}

.c-carousel-paging {

	@include critical {
		display: none;
	}

	@include defer {

		@include mq-min($bpDesktop) {
			display: block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			font-size: rem($cCarouselPagingMessageFontSize);
			line-height: rem($cCarouselPagingMessageLineHeight);
			font-weight: $cCarouselPagingMessageFontWeight;
			color: $colorBase;
		}
	}
}
