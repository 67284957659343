@include defer {

	.br-typeahead {
		position: static !important;
		vertical-align: top;

		.tt {
			&-menu {
				background: $mTypeaheadMenuBackgroundColor;
				@include box-shadow($mTypeaheadMenuBoxShadow);
				@include box-sizing();
				color: $mTypeaheadMenuColor;
				//display: none !important;
				@include min-height(200px);
				margin: 19px 0 0 0;
				padding: 15px 0 0 0;
				text-align: left;
				width: 940px;
				min-height: 511px;
				z-index: 9990;

				&:before {
					border-color: transparent transparent $mTypeaheadMenuBeforeBorderColor transparent;
					border-style: solid;
					border-width: 0 15px 15px 15px;
					content: "";
					display: block;
					height: 0;
					left: 20px;
					margin: 0;
					padding: 0;
					width: 0;
					position: absolute;
					top: -10px;
					z-index: 1;
				}

				&:after {
					background: $mTypeaheadMenuAfterBackgroundColor;
					content: "";
					display: block;
					height: 100%;
					left: 265px;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 0;
					width: 1px;
					z-index: 1;

					@include mq-min($bpLarge) {
						left: 285px;
					}
				}

				@include mq-min($bpLarge) {
					width: 1024px;
					margin-left: 0;
				}

				@include mq-min($bpMassive) {
					width: 1200px;
				}
			}

			&-dataset {
				@include box-sizing();
				clear: left;
				float: left;
				margin: 0 26px 0 0;
				padding: 0 0 20px 15px;
				width: 250px;

				@include mq-min($bpLarge) {
					width: 270px;
				}

				&-products {
					clear: none;
					float: none;
					font-size: 0;
					line-height: 0;
					margin: 0;
					padding: 0 0 15px 5px;
					overflow: hidden;
					width: 660px;

					.tt-suggestion {
						@include box-sizing();
						display: inline-block;
						font-size: rem($fontSize);
						line-height: rem($fontHeight);
						margin: 0;
						padding: 0 15px 15px 0;
						vertical-align: top;
						width: 50%;
					}

					@include mq-min($bpLarge) {
						width: 715px;
					}

					@include mq-min($bpMassive) {
						width: 890px;
					}
				}
			}

			&-highlight {
				color: $mTypeaheadMenuHighlightColor;
			}

			&-header {
				font-size: rem($fontSize);
				line-height: rem($fontHeight);
				margin: 0;
				padding: 0 0 5px 0;

				.title {
					color: $mTypeaheadMenuHeaderTitleColor;
					font-size: rem($fontSize + 4);
					line-height: rem($fontHeight + 4);
					margin: 0;
					padding: 0 0 5px 0;
				}

				.summary {
					margin: 0;
					padding: 5px 0 10px 0;
				}

				.loading {
					color: $mTypeaheadMenuHeaderLoadingColor;
					margin: 0;
					text-align: center;
				}
			}

			&-footer {
				clear: both;
				margin: 0;
				padding: 15px 0;
				text-align: center;

				> .link {
					background: $mTypeaheadMenuFooterLinkBackgroundColor;
					border: none;
					@include box-sizing();
					color: $mTypeaheadMenuFooterLinkColor;
					cursor: pointer;
					display: inline-block;
					font-size: rem($fontSize);
					line-height: rem($fontHeight);
					margin: 0 auto;
					padding: 8px 15px;
					text-align: center;
					text-decoration: none;
					vertical-align: middle;
					white-space: nowrap;
					min-width: 90px;

					&:hover {
						background: $mTypeaheadMenuFooterLinkHoverBackgroundColor;
						color: $mTypeaheadMenuFooterLinkHoverColor;
						text-decoration: none;
					}
				}
			}

			&-suggestion {
				margin: 0;
				padding: 0;

				> .link {
					color: $mTypeaheadMenuSuggestionLinkColor;
					display: block;
					margin: 0;
					padding: 5px 0;

					> .category {
						color: $mTypeaheadMenuSuggestionLinkCategoryColor;
						font-weight: normal;

						.tt-highlight {
							color: $mTypeaheadMenuSuggestionLinkCategoryHighlightColor;
							font-weight: normal;
						}
					}

					&:hover {
						background-color: $mTypeaheadMenuSuggestionLinkHoverBackgroundColor !important;
						color: $mTypeaheadMenuSuggestionLinkHoverColor;

						.tt-highlight {
							color: $mTypeaheadMenuSuggestionLinkHighlightHoverColor;
						}
					}
				}

				> .product {
					display: block;
					margin: 0;
					overflow: hidden;
					padding: 0;

					> picture > .image {
						border: 1px solid $mTypeaheadMenuSuggestionProductPictureBorderColor;
						float: left;
						margin: 0;
						overflow: hidden;
						padding: 0;
					}

					> .content {
						font-size: 0;
						line-height: 0;
						margin: 0;
						overflow: hidden;
						padding: 10px 0 0 15px;

						> .title {
							color: $mTypeaheadMenuSuggestionProductContentTitleColor;
							font-family: $fontBase;
							font-size: rem($fontSize + 2);
							font-weight: normal;
							line-height: rem($fontHeight + 2);
							margin: 0;
							padding: 0;
						}

						> .rating {
							color: $mTypeaheadMenuSuggestionProductContentRatingColor;
							font-size: rem($fontSize - 2);
							line-height: rem($fontHeight - 2);
							margin: 5px 0 0 0;
							padding: 0;

							> .stars {
								background: $mTypeaheadMenuSuggestionProductContentRatingStarsBackgroundUrl no-repeat -83px 0;
								display: inline-block;
								height: 13px;
								margin: 0 5px 0 0;
								padding: 0;
								position: relative;
								@include text-hidden();
								top: 2px;
								width: 81px;
								z-index: 1;

								&05 {
									background-position: -66px -13px;
								}

								&1 {
									background-position: -66px 0;
								}

								&15 {
									background-position: -50px -13px;
								}

								&2 {
									background-position: -50px 0;
								}

								&25 {
									background-position: -33px -13px;
								}

								&3 {
									background-position: -33px 0;
								}

								&35 {
									background-position: -17px -13px;
								}

								&4 {
									background-position: -17px 0;
								}

								&45 {
									background-position: 0 -13px;
								}

								&5 {
									background-position: 0 0;
								}
							}
						}

						> .price {
							color: $mTypeaheadMenuSuggestionProductContentPriceColor;
							font-weight: 600;
							font-size: rem($fontSize + 4);
							line-height: rem($fontHeight + 4);
							margin: 0;
							padding: 5px 0 0 0;

							> .from {
								font-size: rem($fontSize);
								font-weight: 400;
								line-height: rem($fontHeight);
							}
						}

						> .price--sale {
							color: $mTypeaheadMenuSuggestionProductContentPriceSaleColor;
						}

						.price__was {
							color: $mTypeaheadMenuSuggestionProductContentPriceWasColor;
							font-size: rem(15px);
							line-height: rem(20px);
							font-weight: 400;
							display: inline-block;
							@include line-through();
						}

						.c-overlay {
							margin-bottom: -4px;
							margin-left: 3px;
						}
					}

					&:hover {
						background-color: $mTypeaheadMenuSuggestionProductHoverBackgroundColor !important;

						> .image {
							@include opacity();
						}

						> .content {
							> .title {
								color: $mTypeaheadMenuSuggestionProductContentTitleHoverColor;

								.tt-highlight {
									color: $mTypeaheadMenuSuggestionProductContentTitleHighlightHoverColor;
								}
							}
						}
					}
				}

				.no-touch & {

					.link:focus {
						color: $mTypeaheadMenuSuggestionTTCursorLinkColor;
						background-color: $mTypeaheadMenuSuggestionTTCursorLinkBackgroundColor !important;

						.tt-highlight {
							color: $mTypeaheadMenuSuggestionTTCursorLinkHighlightColor;
						}
					}

					.product:focus {
						background-color: $mTypeaheadMenuSuggestionTTCursorProductBackgroundColor !important;

						> .image {
							@include opacity();
						}

						> .content {
							> .title {
								color: $mTypeaheadMenuSuggestionTTCursorProductContentTitleColor;

								.tt-highlight {
									color: $mTypeaheadMenuSuggestionTTCursorProductContentTitleHighlightColor;
								}
							}
						}
					}
				}
			}
		}
	}

	.lookupFields {

		.br-typeahead {
			position: relative !important;
			display: inline-block;
			width: 100%;
			max-width: 315px;

			.tt-menu {
				position: absolute;
				top: 100%;
				left: 0px;
				z-index: 100;
				display: block;
				width: 100%;
				max-width: 400px;
				margin: 0;
				padding: 0;
				border: 1px solid $mTypeaheadLookupBorderColor;
				background-color: #FFF;
				box-shadow: 0 2px 2px rgba(black,0.25);
				max-height: 300px;
				overflow-y: auto;
				min-height: 0;

				&:before,
				&:after {
					display: none;
				}
			}

			.tt-dataset {
				float: none;
				width: 100%;
				float: none;
				clear: none;
				padding: 0;
			}

			.tt-suggestion__button {
				display: block;
				width: 100%;
				background-color: $colorWhite;
				border: none;
				text-align: left;
				@include appearance-none();
				cursor: pointer;
				font-family: inherit;
				padding: 5px 8px;
				font-size: rem(16px);
				line-height: normal;
				font-family: inherit;
				color: inherit;
			}

			.tt-suggestion__button:focus {
				outline: none;
				background-color: #f2f2f2;
			}

			.tt-highlight {
				@include bold();
			}
		}
	}

	.c-form__row .br-typeahead {
		max-width: none;

		.tt-menu {
			overflow-x: hidden;

			@include mq-min($bpDesktop) {
				width: 100%;
				max-width: 400px;
			}
		}
	}
}
