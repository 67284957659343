@use "sass:color";

// _reset.scss

$resetSelectionBackgroundColor: $colorGamma !default;
$resetSelectionColor: $colorWhite !default;
$resetLinkColor: $colorLink !default;
$resetLinkHoverColor: $colorLinkHover !default;
$resetLinkFocusOutlineColor: $colorLink !default;
$resetItalicFontStyle: italic !default;
$resetItalicFontFamily: $fontBase !default;
$resetStrongFontWeight: 600 !default;
$resetStrongFontFamily: $fontBase !default;
$resetBoldestFontWeight: 700 !default;
$resetBoldestFontFamily: $fontBase !default;
$resetBlockquoteBackgroundColor: color.adjust($colorDivider, $lightness: 5%, $space: hsl) !default;
$resetBlockquoteFontSize: $fontSize + 2 !default;
$resetBlockquoteLineHeight: $fontHeight + 2 !default;
$resetBlockquoteAuthorColor: $colorGreySafe !default;
$resetBlockquoteAuthorFontSize: $fontSize !default;
$resetBlockquoteAuthorLineHeight: $fontHeight !default;
$resetBlockquoteAuthorAColor: $colorGrey !default;
$resetBlockquoteAuthorAHoverColor: $colorGrey !default;
$resetBlockquoteBeforeColor: $colorGrey !default;
$resetBlockquoteBeforeFontSize: $fontSize + 30 !default;
$resetBlockquoteBeforeLineHeight: $fontHeight + 30 !default;
$resetBlockquoteBeforeFontWeight: 600 !default;
$resetSmallColor: $colorGreySafe !default;
$resetSmallFontSize: $fontSize - 2 !default;
$resetSmallLineHeight: $fontHeight - 2 !default;
$resetSubFontSize:  $fontSize - 2 !default;
$resetTableFontSize:  $fontSize !default;
$resetTableLineHeight:  $fontHeight !default;
$resetTableThTdBorderColor: $colorDivider !default;
$resetTableThTdColor: $colorGreySafe !default;
$resetTableThTdFontFamily: $fontBase !default;
$resetTableThTdFontWeight: 400 !default;
$resetTableThTdHoverBackgroundColor: color.adjust($colorAlpha, $lightness: 55%, $space: hsl) !default;
$resetTableThTdStripeBackgroundColor: color.adjust($colorDivider, $lightness: 8%, $space: hsl) !default;
$resetHrBackgroundColor: $colorDivider !default;
$resetCMSH1BackgroundColor: color.adjust($colorEmphasise, $lightness: 55%, $space: hsl) !default;
$resetCMSH1BorderColor: $colorEmphasise !default;
$resetCMSH1Color: $colorEmphasise !default;
$resetCMSUlLiBeforeBackgroundColor: $colorAlpha !default;
$resetCMSTableThTdBackgroundColor: color.adjust($colorDivider, $lightness: 8%, $space: hsl) !default;
$resetCMSSummaryFontSize: $fontSize + 2 !default;
$resetCMSSummaryLineHeight: $fontHeight + 2 !default;
$resetCMSGreenColor: $colorAlpha !default;
$resetCMSRedColor: $colorEmphasise !default;
$resetCMSGreyColor: $colorGrey !default;
$resetCMSButtonBackgroundColor: $colorGamma !default;
$resetCMSButtonColor: $colorWhite !default;
$resetCMSButtonFontFamily: $fontBase !default;
$resetCMSButtonFontSize: $fontSize + 2 !default;
$resetCMSButtonLineHeight: $fontHeight + 2 !default;
$resetCMSButtonFontWeight: normal !default;
$resetCMSButtonHoverBackgroundColor: color.adjust($colorGamma, $lightness: -5%, $space: hsl) !default;
$resetCMSButtonHoverColor: $colorWhite !default;
$resetCMSNameBorderColor: red !default;
$resetCMSFigureFigcaptionBackgroundColor: #eaeaea !default;
$resetCMSFigureFigcaptionFontSize: 12px !default;
$resetCMSFigureFigcaptionLineHeight: 18px !default;
$resetAddressFontFamily: $fontBase !default;

$resetHeadingFontFamily: $fontBase !default;
$resetHeadingFontSize: $fontSize !default;
$resetHeadingLineHeight: $fontHeight !default;
$resetHeadingFontWeight: 400 !default;

$resetHeading1FontSize:  $fontSize + 8 !default;
$resetHeading1LineHeight: $fontHeight + 8 !default;
$resetHeading1FontWeight: 400 !default;

$resetHeading2FontSize:  $fontSize + 4 !default;
$resetHeading2LineHeight: $fontHeight + 4 !default;
$resetHeading2FontWeight: 400 !default;

$resetHeading3FontSize:  $fontSize + 3 !default;
$resetHeading3LineHeight: $fontHeight + 3 !default;
$resetHeading3FontWeight: 400 !default;

$resetHeading4FontSize:  $fontSize !default;
$resetHeading4LineHeight: $fontHeight !default;
$resetHeading4FontWeight: 700 !default;

$resetHeading5FontSize:  $fontSize !default;
$resetHeading5LineHeight: $fontHeight !default;
$resetHeading5FontWeight: 600 !default;

$resetHeading6FontSize:  $fontSize !default;
$resetHeading6LineHeight: $fontHeight !default;
$resetHeading6FontWeight: 400 !default;
$resetHeading6TextTransform: none !default;

$resetCMSLinkTextDecoration: underline !default;
