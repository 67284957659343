.c-alert {

	@include critical {
		background: $cAlertBackgroundColor;
		color: $cAlertTextColor;
		overflow: hidden;
		transition: max-height 300ms linear;
		max-height: 1000px;
	}
}

.c-alert__inner {
	@include critical {
		padding: 20px;
	}
}

.c-alert[aria-expanded="false"] {

	@include defer {
		max-height: 0;
	}
}

.c-alert__inner__inner {

	@include critical {
		
		@include mq-min($bpTabletMassive) {
			position: relative;
			padding-right: 230px;
		}
	}
}

.c-alert__title {

	@include critical {
		font-weight: 600;
		margin: 0 0 10px 0;
		padding: 0;
		font-size: rem(18px);
		line-height: rem(24px);
	}
}

.c-alert__content {

	@include critical {
		padding: 0;
		margin: 0 0 15px 0;

		a {
			color: $cAlertTextColor;
			text-decoration: underline;
			font-weight: 600;
		}

		@include mq-min($bpTabletMassive) {
			margin: 0;
		}
	}

	@include defer {

		a {

			&:hover,
			&:focus {
				color: inherit;
				text-decoration: none;
			}
		}
	}
}

.c-alert__action {

	@include critical {
		padding: 0;
		margin: 0;

		@include mq-min($bpTabletMassive) {
			position: absolute;
			bottom: 3px;
			right: 0;
		}
	}
}

.c-alert__close {

	@include critical {
		display: inline;
		margin: 0;
		padding: 0;
		height: auto;
		background: none;
		color: $cAlertTextColor;
		font-size: rem(18px);
		line-height: rem(18px);
		@include appearance-none();
		cursor: pointer;
		box-sizing: border-box;
		text-align: left;
		border: 0;
		font-family: $fontBase;
		font-weight: 600;
		text-decoration: underline;
	}

	@include defer {

		&:hover,
		&:focus {
			color: inherit;
			text-decoration: none;
		}

		@include focusState();
	}
}

.c-alert--with-mobile-message {

	@include critical {

		.c-alert__content--desktop {

			@include mq-max($bpDesktop - 1) {
				display: none;
			}
		}

		.c-alert__content--mobile {

			@include mq-min($bpDesktop) {
				display: none;
			}
		}
	}
}
