@include critical {

	.m-mobile-search {
		display: none;
	}
}

@include defer {

	.m-mobile-search {
		background: $mMobileSearchFallbackBackgroundColor;
		background: $mMobileSearchBackgroundColor;
		bottom: 0;
		left: 0;
		margin: 0;
		padding: 0;
		position: fixed;
		right: 0;
		text-align: left;
		top: 0;
		z-index: 1;

		&__inner {
			margin: 0;
			padding: 0;
		}

		&__form {
			background: $mMobileSearchFormBackgroundColor;
			border-bottom: 1px solid $mMobileSearchFormBorderColor;
			height: 36px;
			left: 47px;
			margin: 0;
			overflow: hidden;
			padding: 5px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;

			.br-typeahead {
				display: block !important;
			}

			.formText {
				background: $mMobileSearchFormTextBackgroundColor;
				border: 0;
				@include box-sizing();
				display: block;
				float: left;
				margin: 0;
				position: relative;
				width: 70%;
				width: calc(100% - 36px);
				max-width: none;
				z-index: 1;
				padding: 10px 5px;
				color: $mMobileSearchFormTextColor;
				@include placeholder-text($a: #fff, $b: rgba(255,255,255,1));
				font-family: $fontBase;
				font-size: rem(16px);
				line-height: rem(16px);
				font-weight: 700;
				letter-spacing: 1px;
				caret-color: #FFF;

				&::-webkit-search-cancel-button {
					-webkit-appearance: none;
					width: 12px;
					height: 12px;
					background: url($mMobileSearchFormTextClearIconBackgroundUrl);
					background-size: 100% 100%;
				}

				&:focus {
					@include box-shadow(none);
				}
			}

			.formButton {
				background: $mMobileSearchFormButtonBackgroundColor;
				@include box-sizing();
				height: 36px;
				float: right;
				margin: 0;
				padding: 0;
				text-align: left;
				@include text-hidden();
				width: 36px;
				min-width: 0;
				z-index: 2;
			}
		}

		&__content {
			background: $mMobileSearchContentBackgroundColor;
			bottom: 0;
			display: none;
			left: 0;
			margin: 0;
			overflow-x: hidden;
			overflow-y: auto;
			padding: 0 0 20px 0;
			position: absolute;
			right: 0;
			top: 47px;
			z-index: 2;
		}

		&__intro {
			bottom: 0;
			color: $mMobileSearchIntroColor;
			left: 0;
			margin: 0;
			padding: 15px 10px;
			position: absolute;
			right: 0;
			text-align: center;
			top: 47px;
			z-index: 1;
		}

		&__results {
			margin: 0;
			padding: 0 0 10px 0;

			&__title {
				background: $mMobileSearchResultsTitleBackgroundColor;
				color: $mMobileSearchResultsTitleColour;
				font-weight: 600;
				margin: 0;
				padding: 5px 10px 6px 10px;
			}

			&__info, &__empty {
				@include box-sizing();
				color: $mMobileSearchResultsInfoEmptyColor;
				font-size: rem($fontSize - 1);
				line-height: rem($fontHeight);
				margin: 0 0 -10px 0;
				padding: 10px;
				position: relative;
				z-index: 1;

				.loading & {
					display: none;
				}
			}

			&__empty {
				font-style: italic;
				margin: 0;
				padding: 12px 10px;
			}

			&__menu {
				list-style: none;
				margin: 0;
				padding: 0;
				position: relative;
				z-index: 2;

				&__item {
					border-top: 1px solid $mMobileSearchMenuItemBorderColor;
					list-style: none;
					margin: 0;
					padding: 0;

					&:first-child {
						border-top: 0;
					}
				}

				&__link {
					color: $mMobileSearchMenuLinkColor;
					display: block;
					margin: 0;
					padding: 10px;

					b, mark {
						background: $mMobileSearchMenuLinkBMarkBackgroundColor;
						font-style: normal;
						font-weight: 700;
					}

					i {
						color: $mMobileSearchMenuLinkIColor;
						font-style: normal;
						font-weight: 600;
					}
				}

				.loading & {
					display: none;
				}
			}

			&__all {
				margin: 0;
				padding: 5px 10px 15px 10px;
				text-align: center;

				.loading & {
					display: none;
				}
			}

			&__loader {
				background: $mMobileSearchLoaderBackgroundColor;
				color: $mMobileSearchLoaderColor;
				display: none;
				font-size: rem($fontSize - 1);
				font-style: italic;
				line-height: rem($fontHeight - 1);
				margin: 0;
				padding: 12px 10px 13px 10px;
				position: relative;
				z-index: 1;

				.loading & {
					display: block;
				}
			}
		}

		&__product {
			font-size: 0;
			line-height: 0;
			margin: 0;
			overflow: hidden;
			padding: 10px;
			position: relative;
			z-index: 1;

			&__image {
				border: 1px solid $mMobileSearchProductImageBorderColor;
				float: left;
				height: 94px;
				margin: 0;
				overflow: hidden;
				padding: 0;
				width: 94px;
			}

			&__content {
				margin: 0;
				padding: 10px 0 0 15px;
				overflow: hidden;
			}

			&__title {
				color: $mMobileSearchProductTitleColor;
				font-size: rem($fontSize + 2);
				line-height: rem($fontHeight + 2);
				margin: 0;
				padding: 0;

				b, mark {
					background: none;
					font-style: normal;
					font-weight: 600;
				}
			}

			&__rating {
				margin: 5px 0 0 0;
				padding: 0;
			}

			&__price {
				color: $mMobileSearchProductPriceColor;
				font-weight: 600;
				font-size: rem($fontSize + 4);
				line-height: rem($fontHeight + 4);
				margin: 0;
				padding: 5px 0 0 0;

				.from {
					font-size: rem($fontSize);
					font-weight: 400;
					line-height: rem($fontHeight);
				}
			}

			&__price--sale {
				color: $mMobileSearchProductPriceSaleColor;
			}

			&__price__was {
				color: $mMobileSearchProductPriceWasColor;
				font-size: rem(15px);
				line-height: rem(20px);
				font-weight: 400;
				display: inline-block;
				@include line-through();
			}

			.c-overlay {
				margin-bottom: -4px;
				margin-left: 3px;
			}

			&__link {
				bottom: 0;
				left: 0;
				display: block;
				margin: 0;
				padding: 0;
				position: absolute;
				right: 0;
				@include text-hidden();
				top: 0;
				z-index: 1;
			}
		}

		&__close {
			display: block;
			width: 47px;
			height: 47px;
			background: $mMobileSearchCloseBackgroundColor;
			border: 0;
			border-bottom: 1px solid $mMobileSearchCloseBorderColor;
			border-right: 1px solid $mMobileSearchCloseBorderColor;
			color: $mMobileSearchCloseColor;
			cursor: pointer;
			left: 0;
			margin: 0;
			overflow: hidden;
			padding: 0;
			position: absolute;
			@include text-hidden();
			top: 0;
			z-index: 1;
			padding: 0;
			@include appearance-none();
		}

		.m-mobile-search__icon {
			display: block;
			width: 20px;
			height: 20px;
			border: 0;
			margin: -8px 0 0 0;
			padding: 0;
			position: absolute;
			right: 15px;
			top: 50%;
		}

		.m-mobile-search__close-icon {
			color: $mMobileSearchCloseIconColor;
			width: 30px;
			height: 30px;
			display: block;
			position: absolute;
			top: 8px;
			left: 8px;
			pointer-events: none;
		}
	}

	.m-mobile-search__results--categories.loading {
		min-height: 279px;
	}

	.m-mobile-search__results--products.loading {
		min-height: 1041px;
	}

	.m-mobile-search__results--content.loading {
		min-height: 240px;
	}
}
