﻿.lBackToTop 
{

	@include critical {
		margin: 0 auto;
		padding: 0;
		max-width: 960px;
		border-bottom: 1px solid $lBackToTopBorderColor;
	}

	@include defer {
		background: $lBackToTopBackgroundColor;
	}
	.primary 
	{

		@include critical {
			display: block;
			height: 40px;
			margin: 0;
			overflow: hidden;
			padding: 0;
			text-indent: -9999px;
		}

		@include defer {
			background: $lBackToTopPrimaryBackgroundUrl no-repeat 50% 50%;

			&:hover 
			{
				.no-touch &
				{
					@include opacity();
				}
			}
		}
	}
	@include mq-min($bpDesktop)
	{

		@include critical {
			display: none;
		}
	}
}