@use "sass:color";

// COMPONENTS

// components/_button.scss

$cButtonBackgroundColor: $colorBeta !default;
$cButtonTextColor: $colorWhite !default;
$cButtonTextHoverColor: $colorWhite !default;
$cButtonHoverBackgroundColor: color.adjust($colorBeta, $lightness: 15%, $space: hsl) !default;

$cButtonDisabledBackgroundColor: #999 !default;
$cButtonDisabledTextColor: $cButtonTextColor !default;

$cButtonSecondaryBackgroundColor: $colorWhite !default;
$cButtonSecondaryBorderColor: $colorBase !default;
$cButtonSecondaryColor: $colorBase !default;
$cButtonSecondaryHoverBackgroundColor: $colorBase !default;
$cButtonSecondaryHoverBorderColor: $colorBase !default;
$cButtonSecondaryHoverColor: $colorWhite !default;
$cButtonCtaOnDarkBackgroundColor: $colorWhite !default;
$cButtonCtaOnDarkTextColor: #297C9D !default;
$cButtonCtaOnDarkHoverBackgroundColor: $colorOffWhite !default;
$cButtonCtaOnDarkHoverTextColor: #297C9D !default;

$cButtonEmphasizeBackgroundColor: #08a500 !default;
$cButtonEmphasizeTextColor:  $colorWhite !default;
$cButtonEmphasizeHoverBackgroundColor: #078c00 !default;
$cButtonEmphasizeTextHoverColor: $colorWhite !default;

$cButtonDarkBackgroundColor: #373737 !default;
$cButtonDarkTextColor: $colorWhite !default;
$cButtonDarkHoverBackgroundColor: color.adjust($cButtonDarkBackgroundColor, $lightness: 15%, $space: hsl) !default;
$cButtonDarkHoverTextColor: $colorWhite !default;


$cButtonSecondaryCheckedBackgroundColor: #4a5050 !default;
$cButtonSecondaryCheckedBorderColor: #4a5050 !default;
$cButtonSecondaryCheckedColor: #fff !default;

$cButtonOutlineBorderColor: #373737 !default;
$cButtonOutlineHoverBackgroundColor: #373737 !default;

// components/_breadcrumbs.scss

$cBreadcrumbsInnerContentListColor: $colorGreySafe !default;
$cBreadcrumbsInnerContentListLevel1BeforeColor: color.adjust($colorGreySafe, $lightness: 10%, $space: hsl) !default;
$cBreadcrumbsAIntroCategoryColor: $colorAlpha !default;
$cBreadcrumbsAIntroCategoryHoverColor: $colorAlpha !default;
$cBreadcrumbsListIntroAltColor: $colorWhite !default;
$cBreadcrumbsListLevel1BeforeIntroAltColor: $colorWhite !default;
$cBreadcrumbsAIntroAltColor: $colorWhite !default;
$cBreadcrumbsAIntroAltHoverColor: $colorWhite !default;

// components/_delivery.scss

$cDeliveryTextColor: $colorGreySafe !default;
$cDeliveryBackgroundUrl: url(#{$baseSitePath}/images/components/delivery/delayed.svg) !default;
$cDeliveryInBackgroundUrl: url(#{$baseSitePath}/images/components/delivery/normal.svg) !default;
$cDeliveryExpeditedBackgroundUrl: url(#{$baseSitePath}/images/components/delivery/expedited.svg) !default;
$cDeliveryUnavailableBackgroundUrl: url(#{$baseSitePath}/images/components/delivery/unavailable.svg) !default;

// components/_dialog.scss

$cDialogBackgroundColor: $colorWhite !default;
$cDialogBoxShadow: 0 0 10px rgba(0,0,0,.3) !default;
$cDialogCloseBackgroundUrl: url(#{$baseSitePath}/images/misc/close.png) !default;

// components/_footer.scss

$cFooterBackgroundColor: #e1e1e1 !default;
$cFooterBottomBackgroundColor: #e9e9e9 !default;
$cFooterLinkTextColor: #373737 !default;
$cFooterLinkAltTextColor: $colorBeta !default;
$cFooterLinkHoverTextColor: $colorGamma !default;
$cFooterLinkTitleTextColor: $colorBeta !default;
$cFooterSocialLinkTextColor: #345162 !default;
$cFooterSocialLinkHoverTextColor: $colorGamma !default;
$cFooterContactTextColor: $colorBeta !default;
$cFooterContactLinkTextColor: $colorBeta !default;
$cFooterInlineListLinkTextColor: $colorBeta !default;
$cFooterCopyrightTextColor: $colorBeta !default;
$cFooterLinkTextColor: #373737 !default;

// components/_header.scss

$cHeaderTopDesktopBackgroundColor: #397c9e !default;
$cHeaderTopDesktopBackgroundUrl: url(#{$baseSitePath}/images/layout/header/grad.png) !default;
$cHeaderCallTextColor: $colorWhite !default;
$cHeaderCallLinkTextColor: $colorWhite !default;
$cHeaderQuickLinksDividerColor: $colorWhite !default;
$cHeaderQuickLinksTextColor: $colorWhite !default;
$cHeaderMiddleBackgroundColor: $colorAlpha !default;
$cHeaderLogoLinkTextColor: $colorWhite !default;
$cHeaderSearchTextColor: $colorBeta !default;
$cHeaderSearchTextBackgroundColor: $colorWhite !default;
$cHeaderSearchBorderColor: $colorWhite !default;
$cHeaderSearchFocusBorderColor: $colorWhite !default;
$cHeaderSearchButtonIconColor: $colorWhite !default;
$cHeaderSearchToggleIconColor: $colorWhite !default;
$cHeaderSearchButtonBackgroundColor: $colorBeta !default;
$cHeaderSearchWidth: 305px !default;
$cHeaderSearchWidthBpLarge: 325px !default;
$cHeaderSearchButtonWidth: 40px !default;
$cHeaderUtilsLinkTextColor: $colorWhite !default;
$cHeaderUtilsCountBackgroundColor: $colorEmphasise !default;
$cHeaderUtilsDesktopCountBackgroundColor: $colorWhite !default;
$cHeaderUtilsDesktopCountTextColor: $colorAlpha !default;
$cHeaderUtilsSubListBackgroundColor: color.adjust($colorAlpha, $lightness: 5%, $space: hsl) !default;
$cHeaderUtilsSubLinkTextColor: $colorWhite !default;
$cHeaderGenericLinkColor: $colorWhite !default;
$cHeaderTradeLogoMobileWidth: 110px !default;
$cHeaderTradeLogoTabletWidth: 140px !default;
$cHeaderTradeLogoDesktopWidth: 192px !default;

// components/_newsletter.scss

$cNewsletterIconColor: #373737 !default;

// components/_newsletter-sign-up.scss

$cNewsletterTitleTextColor: $colorBeta !default;
$cNewsletterSignupIntroTextColor: $colorBeta !default;
$cNewsletterBorderColor: $colorGrey !default;
$cNewsletterSignupInputBackgroundColor: $colorWhite !default;
$cNewsletterSignupInputBorderColor: #d7d7d7 !default;
$cNewsletterSignupInputFocusBorderColor: #345162 !default;
$cNewsletterSignupInputPlaceholderColor: $colorGrey !default;
$cNewsletterSignupButtonBackgroundColor: #345162 !default;
$cNewsletterSignupButtonHoverBackgroundColor: $colorGamma !default;
$cNewsletterSignupButtonTextColor: $colorWhite !default;

// components/_overlay.scss

$cOverlayBackgroundColor: $colorAlpha !default;
$cOverlayTextColor: $colorWhite !default;
$cOverlayBestSellerBackgroundColor: #ad7782 !default;
$cOverlaySaleBackgroundColor: $colorEmphasise !default;
$cOverlayBlackFridayBackgroundColor: $colorBlack !default;
$cOverlayPercentOffBackgroundColor: $colorEmphasise !default;
$cOverlayDealBackgroundColor: $colorEmphasise !default;
$cOverlayClearanceBackgroundColor: #b91c1c !default;
$cOverlayWaterSavingBackgroundColor: #53bbc4 !default;
$cOverlayExpertPickBackgroundColor: $colorBeta !default;
$cOverlayWowFactorBackgroundColor: $colorBeta !default;
$cOverlayLastfewBackgroundColor: $colorEmphasise !default;
$cOverlayEasyCleanBackgroundColor: #53bbc4 !default;
$cOverlayPackPriceBackgroundColor: $colorEmphasise !default;
$cOverlayTopBrandBackgroundColor: $colorBeta !default;
$cOverlayPercentOffOverStockBackgroundColor: #ffea03 !default;
$cOverlayPercentOffOverStockColor: $colorBlack !default;
$cOverlayFreeDeliveryBackgroundColor: #61b5a2 !default;
$cOverlayCyberSaleBackgroundColor: $colorBeta !default;
$cOverlayCyberSaleTextColor: $colorWhite !default;
$cOverlayCyberMondayBackgroundColor: $colorBeta !default;
$cOverlayCyberMondayTextColor: $colorWhite !default;
$cOverlayFlashSaleBackgroundColor: #d33254 !default;
$cOverlayPromoCodeBackgroundColor: #d33254 !default;
$cOverlayPromoCodeTextColor: $colorWhite !default;
$cOverlayPercentOffCodeBackgroundColor: #D33254 !default;
$cOverlayPercentOffCodeColor: $colorWhite !default;

// components/_pager.scss

$cPagerLinkTextColor: #373737 !default;
$cPagerLinkBorderColor: #656565 !default;
$cPagerLinkFocusBackgroundColor: $colorWhite !default;
$cPagerLinkActiveBackgroundColor: $colorWhite !default;
$cPagerMoreTextColor: #373737 !default;
$cPagerSelectHeight: 35px !default;

// components/_price.scss

$cPriceRRPTextColor: $colorGreySafe !default;
$cPriceMainSaleTextColor: #D23254 !default;

// components/_product.scss

$cProductBackgroundColor: $colorWhite !default;
$cProductVariantsMoreTextColor: $colorGreySafe !default;
$cProductVariantsLinkBorderColor: #cccecd !default;
$cProductVariantsLinkActiveBorderColor: $colorBeta !default;
$cProductVariantsMoreHoverTextColor: #9B9B9B !default;
$cProductVariantsMoreHoverBackgroundColor: #f3f3f3 !default;
$cProductHoverBorderColor: #EFEFEF !default;
$cProductEmphasiseBorderColor: #EFEFEF !default;
$cProductEmphasiseBoxShadow: 0 0 10px 0 rgba(color.adjust($colorOffWhite, $lightness: -15%), 1) !default;
$cProductHoverBoxShadow: 0 0 10px 0 rgba(color.adjust($colorOffWhite, $lightness: -15%), 1) !default;
$cProductVariantsCountTextColor: $colorGreySafe !default;
$cProductVariantsCountBorderColor: #cccecd !default;
$cProductTitleLinkTextColor: #373737 !default;
$cProductDeliveryEstimateIconColor: $colorSuccess !default;
$cProductDeliveryEstimateStockInColor: $colorSuccess !default;
$cProductInArticleBorderColor: #9B9B9B !default;
$cProductPageMainPriceColor: #d33254 !default;
$cProductCategoryPlaceholderHeightSmall: 240.75px !default; // < 375
$cProductCategoryPlaceholderHeight390: 247.5px !default; // 390
$cProductCategoryPlaceholderHeight430: 265.5px !default; // 430
$cProductCategoryPlaceholderHeightTabletLarge: 259px !default; // 640
$cProductCategoryPlaceholderHeightDesktop: 445px !default; // 980
$cProductCategoryPlaceholderHeightLarge: 472.66px !default; // 1045
$cProductCategoryPlaceholderHeightMassive: 515px !default; // 1225
$cProductCategoryPlaceholderHeightMammoth: 623.66px !default; // 1650

// components/_rating.scss

$cRatingTextColor: #4a5050 !default;
$cRatingLinkColor: $colorAlpha !default;
$cRatingInnerBackgroundUrl: url(#{$baseSitePath}/images/components/rating/star.svg) !default;
$cRatingRevealBackgroundUrl: url(#{$baseSitePath}/images/components/rating/star-reveal.svg) !default;

// components/_reveal.scss

$cRevealGradientColor: $colorWhite !default;
$cRevealIntroGradientColor: $colorOffWhite !default;
$cRevealIntroAltGradientColor: #667c89 !default;
$cRevealButtonArrowColor: $colorBase !default;
$cRevealIntroAltButtonArrowColor: $colorWhite !default;
$cRevealIntroAltButtonTextColor: $colorWhite !default;
$cRevealButtonTextColor: $colorBase !default;

// components/_section.scss

$cSectionBackgroundColor: $colorWhite !default;
$cSectionAltBackgroundColor: $colorOffWhite !default;
$cSectionCategoryBackgroundColor: $colorOffWhite !default;
$cSectionIntroBackgroundColor: $colorOffWhite !default;
$cSectionIntroDesktopBackgroundColor: $colorWhite !default;
$cSectionIntroAltBackgroundColor: #667c89 !default;
$cSectionIntroAltTextColor: $colorWhite !default;
$cSectionHighlightBackgroundColor: $colorOffWhite !default;

$cSectionTitleColor: $colorAlpha !default;
$cSectionWithGridDividerColor: $colorGreyLight !default;
$cSectionDividerTopBorderColor: $colorGreyLight !default;
$cSectionBlogArticleHeaderDividerColor: $colorGreyLight !default;
$cSectionBlogSnippetDividerColor: #9B9B9B !default;

$cSectionSplashBackgroundColor: $colorOffWhite !default;

// components/_select.scss

$cSelectTextColor: $colorBase !default;
$cSelectFocusBackgroundColor: $colorWhite !default;
$cSelectBackgroundUrl: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAPCAYAAAD3T6+hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkI3QUM2QTQyNzY1MzExRTlBQ0QxODM0NTc1MUMzMjI5IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkI3QUM2QTQzNzY1MzExRTlBQ0QxODM0NTc1MUMzMjI5Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QjdBQzZBNDA3NjUzMTFFOUFDRDE4MzQ1NzUxQzMyMjkiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QjdBQzZBNDE3NjUzMTFFOUFDRDE4MzQ1NzUxQzMyMjkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7IWy4TAAABOUlEQVR42rSUvWrCUBhAb64R7CI4tS6duroVk4foU0jBpe9g38GlID6Fi48QcdTNyaHUboJLBLU9H3yBi8R448+Fkxu+v0NCboIoiv6MMb/wZC5YSZJ41cVxvGJ7tFw2cgNLc6eFbKmOjQjrkMIzLO4gW+jslLdRtxp/gC28wPSGsqnO3CITh7FOvgY7eIXJDWQTnbVDVsvi9qiuCntow+gK2Uhn7JFV3ZzNqQ/hAG+XSFUmvQdk4XHenuir6C6NgxKygfbIcank1diC/kD3d+h7yPpaK7LgVJ09Mydr/IBegaynNYUyH2F2ZGR9QjdH1tWcW3uVUH4KDb3/go6T62hMVoOnS88NCz2/hzU04QeG8K3xoe5NZGufQWGJL15+vi2YwdiJt5CtfIfYksdsrlJXNi8z4F+AAQBsEldXhVdNnwAAAABJRU5ErkJggg==') !default;

// components/_usps.scss

$cUSPSBackgroundColor: $colorBeta !default;
$cUSPSDesktopBackgroundColor: #f0f0f0 !default;
$cUSPSLinkTextColor: $colorWhite !default;
$cUSPSDesktopLinkTextColor: $colorBeta !default;
$cUSPSLinkHoverTextColor: color.adjust($colorBeta, $lightness: 50%, $space: hsl) !default;
$cUSPSDesktopLinkHoverTextColor: $colorGrey !default;
$cUSPSPrevNextBeforeBackgroundUrl: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDI0IDI0Ij4NCiAgPHBhdGggZD0iTTkuNzA3IDE4LjcwN2w2LTZhLjk5OS45OTkgMCAwIDAgMC0xLjQxNGwtNi02YS45OTkuOTk5IDAgMSAwLTEuNDE0IDEuNDE0TDEzLjU4NiAxMmwtNS4yOTMgNS4yOTNhLjk5OS45OTkgMCAxIDAgMS40MTQgMS40MTR6IiBmaWxsPSIjZmZmZmZmIi8+DQo8L3N2Zz4=');

$cUSPSInlineBp: $bpDesktop !default;
$cUSPSMaxWidth: 1260px !default;

// components/_image.scss

$cImageLazyBackground: $colorWhite url(#{$baseSitePath}/images/components/image/lazy.svg) !default;

// components/_banner-grid.scss

$cBannerGridSpacing: 20px !default;

// components/_pod.scss

$cPodBackground: $colorWhite !default;
$cPodDarkBackground: $colorBeta !default;
$cPodTitleLinkColor: $colorBase !default;
$cPodTitleLinkHoverColor: $colorAlpha !default;
$cPodListLinkColor: $colorAlpha !default;
$cPodListLinkHoverColor: $colorAlpha !default;

// components/_icon-navigation.scss

$cIconNavigationLinkColor: $colorBase !default;
$cIconNavigationLinkActiveColor: $colorBase !default;
$cIconNavigationLinkHoverFocusColor: $colorBase !default;
$cIconNavigationFadeColor: #FFF;
$cIconNavigationFadeColorAlt: $cSectionCategoryBackgroundColor !default;
$cIconNavigationArrowColor: $colorBase;
$cIconNavigationArrowHoverColor: $colorAlpha;
$cIconNavigationActiveLineBackgroundColor: $colorBase;
$cIconNavigationBorderColor: #9B9B9B;

// components/_nav.scss

$cNavWidth: 260px !default;
$cNavBackground: $colorBeta !default;
$cNavMobileBackgroundImage: url(#{$baseSitePath}/images/layout/mobile-nav/icon.png) !default;
$cNavColor: $colorWhite !default;
$cNavInnerMaxWidth: 1200px !default;
$cNavTitleBackgroundImage: url(#{$baseSitePath}/images/components/nav/toggle.svg) !default;
$cNavLinkColor: $colorWhite !default;
$cNavLinkHoverBackground: $colorWhite !default;
$cNavLinkHoverColor: $colorBeta !default;
$cNavSiteWrapperCoverBackground: $colorBlack !default;
$cNavMoreArrowColor: $colorWhite !default;
$cNavDesktopBackgroundColor: $colorWhite !default;
$cNavSecondaryBackground: #3a5e73 !default;
$cNavSecondaryLinkColor: rgba($colorWhite, 0.8) !default;
$cNavMegaNavTitleColor: #315062 !default;
$cNavMegaNavListNoLinkColor: $colorLinkHover !default;
$cNavMegaNavListTitleNoLinkColor: $colorBeta !default;
$cNavMegaNavListLinkColor: $colorLinkHover !default;
$cNavMegaNavListTitleLinkColor: $colorBeta !default;
$cNavMegaNavListLinkSaleColor: $colorEmphasise !default;
$cNavMegaNavListTitleColor: $colorBeta !default;
$cNavMegaNavDimmerBackgroundColor: $colorBeta !default;

$cNavItemBorderColorOne: #3a5e73 !default;
$cNavItemBorderColorTwo: #294251 !default;
$cNavItemBorderColorThree: #426c84 !default;
$cNavItemBorderColorFour: #315062 !default;
$cNavItemBorderColorFive: #4b7a95 !default;
$cNavItemBorderColorSix: #2e8cb1 !default;
$cNavItemBorderColorSeven: #246c89 !default;
$cNavItemBorderColorEight: #d33254 !default;
$cNavItemBorderColorNine: #c22a4a !default;

$cNavItemSaleBackgroundColor: #d33254 !default;
$cNavItemSaleHoverBackgroundColor: color.adjust($cNavItemSaleBackgroundColor, $lightness: -7%, $space: hsl) !default;
$cNavItemSaleFontColor: #fff !default;
$cNavItemSaleBorderTopColor: color.adjust($cNavItemBorderColorNine, $lightness: 10%, $space: hsl) !default;
$cNavItemSaleBorderBottomColor: $cNavItemBorderColorNine !default;
$cNavItemSaleMoreBorderColor1: $cNavItemBorderColorNine !default;
$cNavItemSaleMoreBorderColor2: $cNavItemBorderColorEight !default;

$cNavItemFeatureBackgroundColor: #B1DFDA !default;
$cNavItemFeatureHoverBackgroundColor: color.adjust($cNavItemFeatureBackgroundColor, $lightness: -7%, $space: hsl) !default;
$cNavItemFeatureFontColor: #315062 !default;
$cNavItemFeatureBorderTopColor: color.adjust($cNavItemFeatureFontColor, $lightness: 10%, $space: hsl) !default;
$cNavItemFeatureBorderBottomColor: $cNavItemFeatureFontColor !default;
$cNavItemFeatureMoreBorderColor1: color.adjust($cNavItemFeatureBackgroundColor, $lightness: -10%, $space: hsl) !default;
$cNavItemFeatureMoreBorderColor2: color.adjust($cNavItemFeatureBackgroundColor, $lightness: 10%, $space: hsl) !default;

$cNavItemAltBackgroundColor: #297c9d !default;
$cNavMobileRenameThisBackgroundColor: #426c84 !default;

$cNavSideBannerWidth: 400px !default;
$cNavSideBannerHeight: 240px !default;
$cNavBottomBannerWidth: 1200px !default;
$cNavBottomBannerHeight: 188px !default;

// components/_product-page.scss

$cProductPageHeaderInnerMaxWidth: $bpExMega - 220px !default;
$cProductPageDesktopGallerySize: 535px !default;
$cProductPageMegaGallerySize: 657.5px !default;
$cProductVariantsErrorBackgroundColor: $colorEmphasise !default;
$cProductVariantsErrorColor: $colorWhite !default;
$cProductPageAddMaxWidth: 490px !default;
$cProductPageLinkColor: $colorAlpha !default;
$cProductPageStockInColor: $colorSuccess !default;


// components/_product-main-price.scss

$cProductMainPriceRRPSaveColor: $colorEmphasise !default;
$cProductMainPriceRRPSaveBeforeBackgroundColor: $colorBase !default;
$cProductMainPriceMainSaleColor: $colorEmphasise !default;
$cProductMainPriceMainSaleWasColor: $colorBase !default;

// components/_gallery.scss

$cGalleryGenericBorderColor: #ddd !default;

$cGalleryLinkBorderColor: transparent !default;
$cGalleryLinkActiveBorderColor: $colorAlpha !default;
$cGalleryLinkIconColor: #fff !default;
$cGalleryLinkIconDarkColor: $colorBase !default;

// components/_accordion.scss

$cAccordionTitleBackgroundColor: $colorWhite !default;
$cAccordionContentBackgroundColor: $colorWhite  !default;
$cAccordionArrowColor: $colorBase  !default;

// components/_carousel.scss

$cCarouselButtonGradientColor: #f4f4f4 !default;
$cCarouselButtonOnWhiteGradientColor: $colorWhite !default;
$cCarouselPagerItemColor: $colorGamma !default;
$cCarouselPagerLinkColor: $colorBlack !default;
$cCarouselPagerLinkHoverColor: $colorAlpha !default;
$cCarouselButtonBackgroundImage: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbDpzcGFjZT0icHJlc2VydmUiIHZpZXdCb3g9IjAgMCA0MCA0MCIgaGVpZ2h0PSI0MCIgd2lkdGg9IjQwIj4NCgk8Y2lyY2xlIGN4PSIyMCIgY3k9IjIwIiByPSIxOC43IiBmaWxsPSIjZmZmIiBzdHJva2U9IiM0YTUwNTAiIHN0cm9rZS1taXRlcmxpbWl0PSIyLjE0MyIgc3Ryb2tlLXdpZHRoPSIyLjU3MiIvPg0KCTxwYXRoIGZpbGw9IiM0YTUwNTAiIGQ9Ik0xNS43IDMyLjFjLS40IDAtLjctLjEtMS0uNC0uNS0uNS0uNS0xLjQgMC0ybDkuNy05LjctOS43LTkuN2MtLjUtLjUtLjUtMS40IDAtMiAuNS0uNSAxLjQtLjUgMiAwTDI3LjMgMTljLjUuNS41IDEuNCAwIDJMMTYuNyAzMS42Yy0uMy4zLS43LjUtMSAuNXoiLz4NCjwvc3ZnPg==") !default;

// components/_cms.scss

$cCmsOlBeforeColor: $colorAlpha !default;
$cCmsBlockquoteBackgroundColor: rgba($colorAlpha, .1) !default;
$cCmsBlockquoteColor: $colorAlpha !default;
$cCmsBlockquoteBeforeColor: $colorAlpha !default;
$cCmsBlockquoteAuthorColor: rgba($colorAlpha, .8) !default;
$cCmsFontSize: 16px !default;
$cCmsLineHeight: 26px !default;
$cCmsH2FontSize: 24px !default;
$cCmsH2LineHeight: 32px !default;
$cCmsH3FontSize: 21px !default;
$cCmsH3LineHeight: 30px !default;
$cCmsH4FontSize: 20px !default;
$cCmsH4LineHeight: 28px !default;
$cCmsH5H6FontSize: 16px !default;
$cCmsH5H6LineHeight: 24px !default;
$cCmsBlockquoteFontSize: 18px !default;
$cCmsBlockquoteLineHeight: 26px !default;
$cCmsBlockquoteAuthorFontSize: 14px !default;
$cCmsBlockquoteAuthorLineHeight: 18px !default;
$cCmsEmphasiseFontSize: 18px !default;
$cCmsEmphasiseLineHeight: 26px !default;
$cCmsLinkTextDecoration: underline !default;
$cCmsUlLiBeforeBackgroundColor: $colorBase !default;
$cCmsSmallFontSize: 14px !default;
$cCmsSmallLineHeight: 18px !default;

// components/_table-striped.scss

$cTableStripedFontSize: 16px !default;
$cTableStripeLineHeight: 24px !default;
$cTableStripedBackgroudColor: #f2f2f0 !default;
$cTableStripedBorderColor: #d4d4d4 !default;

// components/add-product.scss

$cAddProductMessageColor: $colorSuccess !default;
$cAddProductControlsIconColor: $cButtonSecondaryBorderColor !default;
$cAddProductControlsIconHoverColor: $colorWhite !default;
$cAddProductControlsTextBackgroundColor: $colorWhite !default;
$cAddProductControlsTextBorderColor: $cButtonSecondaryBorderColor !default;
$cAddProductControlsTextFontFamily: inherit !default;
$cAddProductControlsTextFontWeight: 600 !default;
$cAddProductControlsDisabledBackgroundColor: #dcdbdb !default;
$cTableStripeLineHeight: 26px !default;

// components/_list.scss

$cListLinkColor: $colorBase !default;
$cListLinkToggleColor: $colorBlack !default;
$cListLinkHoverColor: $colorAlpha !default;
$cListLinkHoverColorCategory: $colorBase !default;

// components/_article.scss

$cArticleMetaColor: $colorAlpha !default;
$cArticleAuthorImageOutlineColor: #e0e0e0 !default;

// components/_article-card.scss

$cArticleCardBackgroundColor: $colorWhite !default;
$cArticleCardBodyFontColor: $colorBase !default;
$cArticleCardFooterBorderColor: #919191 !default;
$cArticleCardAuthorColor: $colorAlpha !default;
$cArticleCardCategoryColor: $colorAlpha !default;
$cArticleCardDateColor: #737373 !default;
$cArticleCardReadingTimeColor: #737373 !default;
$cArticleSummaryFontWeight: 300 !default;

// components/_article-search.scss

$cArticleSearchInputBorderColor: #373737 !default;
$cArticleSearchInputBorderFocusColor: $colorGrey !default;
$cArticleSearchInputColor: #373737 !default;
$cArticleSearchPlaceholderColor: #373737 !default;
$cArticleSearchSubmitButtonColor: #373737 !default;

// components/_newsletter.scss

$cNewsletterBackground: $colorWhite !default;
$cNewsletterInputBorderColor: #373737 !default;
$cNewsletterInputColor: #373737 !default;
$cNewsletterInputBoxShadowColor: rgba(#cdcdcd, 1) !default;
$cNewsletterColor: #373737 !default;
$cNewsletterErrorColor: $colorEmphasise !default;

// components/_highlight-card.scss

$cHighlightCardBackgroundColor: $colorAlpha !default;
$cHighlightCardColor: $colorWhite !default;
$cHighlightCardTitleBorderColor: $colorWhite !default;
$cHighlightCardAsideBorderColor:  $colorGrey !default;

// components/_icon-link.scss

$cIconLinkColor: $colorAlpha !default;

// components/_blog-nav.scss

$cBlogNavLinkColor: #373737 !default;
$cBlogNavLinkHoverColor: #000 !default;
$cBlogNavActiveBorderColor: #373737 !default;
$cBlogNavLinkMobileColor: $colorAlpha !default;

// components/_social.scss

$cSocialLinkColor: #373737 !default;
$cSocialIconColor: #373737 !default;

// components/_articles-tabs.scss

$cArticlesTabsTriggerColor: #707070 !default;
$cArticlesTabsTriggerActiveColor: #373737 !default;
$cArticlesTabsTriggerFocusColor: $colorAlpha !default;
$cArticlesTabsTriggerBackgroundColor: #f4f4f4 !default;
$cArticlesTabsContentBackgroundColor: #f4f4f4 !default;
$cArticlesTabsBorderColor: #dfdfdf !default;
$cArticlesTabsArticleTitleColor: #373737 !default;
$cArticlesTabsArticleDateColor: #707070 !default;

// components/_article-list.scss


$cArticleListSeperatorBorderColor: #9B9B9B;

// components/_alert.scss

$cAlertBackgroundColor: #ffcd00;
$cAlertTextColor: #000;

// components/_video.scss

$cVideoBackgroundColor: $colorBlack !default;

// components/_load-content-placeholder.scss

$cLoadContentPlaceholderBackgroundColor: $colorWhite !default;
$cLoadContentPlaceholderOnWhiteBackgroundColor: $colorOffWhite !default;

$cLoadContentPlaceholderPPCHeightSmall: 218px !default;
$cLoadContentPlaceholderPPCHeight390: 227px !default;
$cLoadContentPlaceholderPPCHeight430: 225px !default;
$cLoadContentPlaceholderPPCHeightTabletLarge: 263px !default;
$cLoadContentPlaceholderPPCHeightDesktop: 530px !default;
$cLoadContentPlaceholderPPCHeightLarge: 550px !default;
$cLoadContentPlaceholderPPCHeightMassive: 510px !default;
$cLoadContentPlaceholderPPCHeightMammoth: 588px !default;


// components/_carousel-paging.scss

$cCarouselPagingMessageFontSize: 16px !default;
$cCarouselPagingMessageLineHeight: 16px !default;
$cCarouselPagingMessageFontWeight: 600 !default;

// components/_alert-message.scss

$cAlertMessageFontColor: $colorWhite !default;
$cAlertMessageBackgroundColor: #08a500 !default;
$cAlertMessageErrorFontColor: $colorWhite !default;
$cAlertMessageErrorBackgroundColor: #d33254 !default;
$cAlertMessageInfoFontColor: $colorBase;
$cAlertMessageInfoBackgroundColor: #E6F3F8 !default;

// components/_basket.scss
$cBasketDeliveryFontColor: $colorAlpha !default;
$cBasketDeliveryIndicatorBorderColor: $colorAlpha !default;
$cBasketDeliveryBarFillBackgroundColor: $colorAlpha !default;
$cBasketDeliveryBarEmptyBackgroundColor: #cee1e8 !default;
$cBasketDeliveryIconColor: $colorAlpha !default;
$cBasketDeliveryBorderTopColor: #cdcdcd !default;

// components/checkout/_checkout-card.scss

$cCheckoutCardBackgroundColor: #FFF !default;
$cCheckoutCardLinkColor: #4a5050 !default;
$cCheckoutCardLinkColorHover: #000 !default;

// components/checkout/_checkout-header.scss

$cCheckoutHeaderBackgroundColor: #297c9d !default;
$cCheckoutHeaderFontColor: #FFF !default;
$cCheckoutHeaderFontFamily: $resetStrongFontFamily !default;
$cCheckoutHeaderFontWeight: $resetStrongFontWeight !default;

// components/checkout/_checkout-form.scss

$cCheckoutFormInputFontFamily: Helvetica,Arial,Verdana,sans-serif !default;
$cCheckoutFormInputColor: #4a5050 !default;
$cCheckoutFormInputFocusBorderColor: #297c9d !default;
$cCheckoutFormRadioColor: #297c9d !default;
$cCheckoutFormCheckoutColor: #297c9d !default;
$cCheckoutFormAddInputColor: #373737 !default;
$cCheckoutFormAddInputColorHover: #373737 !default;

// components/checkout/_checkout-step-indicator.scss

$cCheckoutStepIndicatorColor: #297c9d !default;
$cCheckoutStepIndicatorFontSize: 24px !default;

// components/checkout/_checkout-overlay.scss

$cCheckoutOverlayCloseButtonColor: #297c9d !default;

// components/checkout/_checkout-tooltip.scss

$cCheckoutTooltipBackgroundColor: #315062 !default;
$cCheckoutTooltipCloseColor: #00abee !default;

// components/checkout/_checkout-payment-selector.scss

$cCheckoutPaymentSelectorBackgroundColor: #297c9d !default;

// components/_order-complete.scss

$cOrderCompletePrimaryTitleColor: $colorAlpha !default;
$cOrderCompleteFooterIconColor: $colorAlpha !default;
$cOrderCompleteIncorrectColor: $colorAlpha !default;

// components/_sidebar-nav.scss

$cSidebarNavLinkColor: $colorAlpha !default;
$cSidebarNavLinkColorActive: #373737 !default;

// components/_help-list.scss

$cHelpListLinkColor: #373737 !default;

// components/_card.scss

$cCardTitleLinkColor: $colorAlpha !default;
$cCardTitleLinkColorHover: $colorLinkHover !default;
$cCardPinnedLinkColor: $colorAlpha !default;

// components/_battenberg.scss

$cBattenbergBackgroundColor1: #ffffff !default;
$cBattenbergBackgroundColor2: #b7e2df !default;
$cBattenbergBackgroundColor3: #373737 !default;
$cBattenbergBackgroundColor4: #315062 !default;
$cBattenbergFontColorLight: $colorWhite !default;

// components/_finance.scss
$cFinanceLegalMessageColour: #787471 !default;

// components/_load-content-placeholder.scss
$cColumnPlaceholderHeightSmall: 460px !default;
$cColumnPlaceholderHeightTablet: 462x !default;
$cColumnPlaceholderHeightDesktop: 445px !default;
$cColumnPlaceholderHeightLarge: 463px !default;
$cColumnPlaceholderHeightMassive: 475px !default;
$cColumnPlaceholderHeightMammoth: 480px !default;

$cRecommendedExtrasPlaceholderHeightSmall: 536px !default;
$cRecommendedExtrasPlaceholderHeightTablet: 545px !default;
$cRecommendedExtrasPlaceholderHeightTabletLarge: 536px !default;
$cRecommendedExtrasPlaceholderHeightDesktop: 533px !default;
$cRecommendedExtrasPlaceholderHeightLarge: 551px !default;
$cRecommendedExtrasPlaceholderHeightMassive: 563px !default;
$cRecommendedExtrasPlaceholderHeightMammoth: 568px !default;
