@include critical {

	// RESET: http://meyerweb.com/eric/tools/css/reset/
	html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, 
	blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, 
	img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, 
	center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, 
	tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, 
	figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video
	{
		border: 0;
		font: inherit;
		font-size: 100%;
		margin: 0;
		outline: 0;
		padding: 0;
		vertical-align: baseline;
	}

	main, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, audio, video, canvas
	{
		display: block;
	}

	body
	{
		line-height: 1;
	}

	blockquote, q
	{
		quotes: none;
		&:before, &:after
		{
			content: '';
			content: none;
		}
	}

	:focus
	{
		outline: none;
	}

	table
	{
		border-collapse: collapse;
		border-spacing: 0;
	}

	abbr[title] 
	{
		cursor: help;
		text-decoration: none;
	}

	fieldset 
	{
		border: 0;
		display: block;
		margin: 0;
		padding: 0;
		position: static;
	}

	legend 
	{
		left: auto;
		margin: 0;
		padding: 0;
		position: static;
		top: auto;
	}

	address 
	{
		display: block;
		font-family: $resetAddressFontFamily;
		font-style: normal;
		margin: 0;
		padding: 0;
	}

	iframe
	{
		display: block;
		margin: 0;
		padding: 0;
		width: 100%;
	}

	// selections
	::-moz-selection
	{
		background: $resetSelectionBackgroundColor;
		color: $resetSelectionColor;
	}
	::selection
	{
		background: $resetSelectionBackgroundColor;
		color: $resetSelectionColor;
	}


	// BASE
	h1, h2, h3, h4, h5, h6, 
	.h1, .h2, .h3, .h4, .h5, .h6
	{
		font-family: $resetHeadingFontFamily;
		font-size: rem($resetHeadingFontSize);
		font-weight: $resetHeadingFontWeight;
		line-height: rem($resetHeadingLineHeight);
		margin: 0;
		padding: 0 0 10px 0;
	}

	h1, .h1 
	{
		font-size: rem($resetHeading1FontSize);
		line-height: rem($resetHeading1LineHeight);
		font-weight: $resetHeading1FontWeight;
	}

	h2, .h2 
	{
		font-size: rem($resetHeading2FontSize);
		line-height: rem($resetHeading2LineHeight);
		font-weight: $resetHeading2FontWeight;
	}

	h3, .h3 
	{
		font-size:rem($resetHeading3FontSize);
		line-height: rem($resetHeading3LineHeight);
		font-weight: $resetHeading3FontWeight;
		padding-bottom: 5px;
	}

	h4, .h4 
	{
		font-size:rem($resetHeading4FontSize);
		line-height: rem($resetHeading4LineHeight);
		font-weight: $resetHeading4FontWeight;
		padding-bottom: 0;
	}

	h5, .h5
	{
		font-size:rem($resetHeading5FontSize);
		line-height: rem($resetHeading5LineHeight);
		font-weight: $resetHeading5FontWeight;
		padding-bottom: 0;
	}

	h6, .h6
	{
		padding-bottom: 0;
		font-size:rem($resetHeading6FontSize);
		line-height: rem($resetHeading6LineHeight);
		font-weight: $resetHeading6FontWeight;
		text-transform: $resetHeading6TextTransform;
	}

	p 
	{
		margin: 0;
		padding: 0 0 15px 0;
	}

	a 
	{
		color: $resetLinkColor;
		text-decoration: none;
		&:hover, &:focus 
		{
			color: $resetLinkHoverColor;
			text-decoration: none;
		}
		&:focus 
		{
			outline: 1px dotted $resetLinkFocusOutlineColor;
		}
	}

	em, i
	{
		font-style: $resetItalicFontStyle;
		font-family: $resetItalicFontFamily;
	}

	strong, b 
	{
		font-weight: $resetStrongFontWeight;
		font-family: $resetStrongFontFamily;
	}

	code, pre 
	{
		font-family: monospace, serif;
		font-size: rem(13px);
		line-height: rem(19px);
		margin: 0;
		padding: 0 0 15px 0;
	}

	pre 
	{
		white-space: pre-wrap;
	}

	blockquote 
	{
		background: $resetBlockquoteBackgroundColor;
		font-size: $resetBlockquoteFontSize;
		line-height: $resetBlockquoteLineHeight;
		margin: 0 0 20px 0;
		padding: 15px 15px 5px 15px;
		position: relative;
		z-index: 1;
		p 
		{
			margin: 0;
			overflow: hidden;
			padding: 0 0 10px 0;
		}
		.author 
		{
			color: $resetBlockquoteAuthorColor;
			font-size: rem($resetBlockquoteAuthorFontSize);
			line-height: rem($resetBlockquoteAuthorLineHeight);
			a 
			{
				color: $resetBlockquoteAuthorAColor;
				text-decoration: underline;
				&:hover 		
				{
					color: $resetBlockquoteAuthorAHoverColor;
					text-decoration: none;
				}
			}
			&:before 
			{
				content: "- ";
			}
		}
		&:before 
		{
			color: $resetBlockquoteBeforeColor;
			content: "“";
			float: left;
			font-size: rem($resetBlockquoteBeforeFontSize);
			font-weight: $resetBlockquoteBeforeFontWeight;
			line-height: rem($resetBlockquoteBeforeLineHeight);
			margin: -5px 0 0 0;
			padding: 0 5px 5px 0;
			position: relative;
			z-index: 1;
		}
	}

	small 
	{
		color: $resetSmallColor;
		font-size: rem($resetSmallFontSize);
		line-height: rem($resetSmallLineHeight);
	}

	sub, sup 
	{
		font-size: rem($resetSubFontSize);
		line-height: 0;
		margin: 0;
		padding: 0;
		position: relative;
		vertical-align: baseline;
	}

	sup 
	{
		top: -0.5em;
	}

	sub 
	{
		bottom: -0.25em;
	}

	img 
	{
		border: 0;
		display: block;
		margin: 0 auto;
		padding: 0;
		max-width: 100%;
	}
	
	@include interaction-mq(hover, true) {
		a:hover img 
		{
			@include opacity();
		}
	}

	button, html input[type="button"], html input[type="reset"], html input[type="submit"] 
	{
		-webkit-appearance: button;
	}

	button::-moz-focus-inner, input::-moz-focus-inner 
	{
		border: 0;
		margin: 0;
		padding: 0;
	}
	
	table 
	{
		font-size: rem($resetTableFontSize);
		line-height: rem($resetTableLineHeight);
		margin: 0 0 20px 0;
		padding: 0;
		width: 100%;
		tr 
		{
			th, td 
			{
				border-bottom: 1px solid $resetTableThTdBorderColor;
				margin: 0;
				padding: 5px;
				text-align: left;
				vertical-align: top;
			}
			&.clickRow 
			{
				th, td
				{
					cursor: pointer;
				}
				&:hover 
				{
					th, td 
					{
						background: $resetTableThTdHoverBackgroundColor !important;
					}
				}
			}
		}
		thead 
		{
			tr 
			{
				th, td
				{
					color: $resetTableThTdColor;
					font-family: $resetTableThTdFontFamily;
					font-weight: $resetTableThTdFontWeight;
					padding: 0 5px 5px 5px;
				}
			}
		}
		&.stripe 
		{
			tbody 
			{
				tr 
				{
					&:nth-child(odd) 
					{
						th, td 
						{
							background: $resetTableThTdStripeBackgroundColor;
						}
					}
				}		
			}
		}
		.tableWrapper &
		{
			margin-bottom: 0;
		}
	}

	.tableWrapper 
	{
		margin: 0 0 20px;
		padding: 0;
	}

	@media screen and (max-width: 460px) 
	{
		.tableWrapper
		{
			padding-bottom: 5px;
			overflow-x: auto;
		}
	}

	ul 
	{
		list-style: disc;
		margin: 0;
		padding: 0 0 10px 25px;
		li 
		{
			margin: 0;
			padding: 0 0 5px 0;
		}
		ul, ol 
		{
			padding-bottom: 0;
			padding-top: 5px;
		}
	}

	ol 
	{
		list-style: decimal;
		margin: 0;
		padding: 0 0 10px 26px;
		li 
		{
			margin: 0;
			padding: 0 0 5px 0;
		}
		ul, ol 
		{
			padding-bottom: 0;
			padding-top: 5px;
		}
		ol 
		{
			list-style: lower-alpha;
			ol 
			{
				list-style: lower-roman;
			}
		}
	}

	dl 
	{
		margin: 0;
		padding: 0 0 10px 0;
		dt 
		{
			display: block;
			font-weight: 700;
			margin: 0;
			padding: 0 0 5px 0;
		}
		dd 
		{
			display: block;
			margin: 0;
			padding: 0 0 5px 0;
		}
	}

	hr
	{
		background: $resetHrBackgroundColor;
		border: 0;
		height: 1px;
		margin: 25px 0 20px 0;
		padding: 0;
		&.close
		{
			margin-top: 5px;
		}
		&.tight
		{
			margin-bottom: 5px;
		}
	}


	// CMS
	.cms 
	{
		h1 
		{
			background: $resetCMSH1BackgroundColor;
			border: 2px dotted $resetCMSH1BorderColor;
			color: $resetCMSH1Color;
			margin: 0 0 15px 0;
			padding: 10px;
			&:before 
			{
				content: "Remove H1 tag(s)";
				display: block;
				font-size: rem($fontSize - 2);
				line-height: rem($fontHeight - 2);
				margin: 0;
				padding: 0;
				text-transform: uppercase;
			}
		}
		ul 
		{
			list-style: none;
			padding-left: 0;
			> li 
			{
				list-style: none;
				padding-left: 26px;
				position: relative;
				z-index: 1;
				&:before
				{
					background: $resetCMSUlLiBeforeBackgroundColor;
					@include border-radius(100%);
					content: "";
					display: block;
					height: 5px;
					left: 13px;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 8px;
					width: 5px;
					z-index: 1;
				}
			}
		}
		table 
		{		
			tbody 
			{
				tr 
				{
					&:nth-child(even) 
					{
						th, td 
						{
							background: $resetCMSTableThTdBackgroundColor;
						}
					}
				}		
			}
		}
		.summary 
		{
			font-size: $resetCMSSummaryFontSize;
			line-height:$resetCMSSummaryLineHeight;
		}
		.imageLeft, .imageRight 
		{
			clear: both;
			display: block;
			margin: 0 0 15px 0;
			padding: 0;
			p & 
			{
				margin-bottom: 0;
			}
			img 
			{
				p & 
				{
					margin-bottom: 0;
				}
			}
		}
		.imageLeft
		{
			@include mq-min($bpTablet)
			{
				float: left;
				margin: 0 15px 8px 0;
				max-width: 50%;
			}
		}
		.imageRight
		{
			@include mq-min($bpTablet)
			{
				float: right;
				margin: 0 0 8px 15px;
				max-width: 50%;
			}
		}
		.green 
		{
			color: $resetCMSGreenColor;
		}
		.red 
		{
			color: $resetCMSRedColor;
		}
		.grey 
		{
			color: $resetCMSGreyColor;
		}
		.button 
		{
			display: block;
			padding-top: 5px;
		}
		.button a, a.button, a .button
		{
			background: $resetCMSButtonBackgroundColor;
			border: none;
			@include border-radius(0);
			@include box-sizing();
			color: $resetCMSButtonColor !important;
			cursor: pointer;
			display: inline-block;
			font-family: $resetCMSButtonFontFamily;
			font-size: rem($resetCMSButtonFontSize);
			font-weight: $resetCMSButtonFontWeight;
			line-height: rem($resetCMSButtonLineHeight);
			margin: 0;
			padding: 7px 15px 9px 15px;
			text-align: center;
			text-decoration: none !important;
			@include user-select();
			vertical-align: middle;
			white-space: nowrap;
			&:hover 
			{
				background: $resetCMSButtonHoverBackgroundColor;
				color: $resetCMSButtonHoverColor !important;
				text-decoration: none !important;
			}
		}
		figure {

			figcaption {
				font-size: rem($resetCMSFigureFigcaptionFontSize);
				line-height: rem(18px);
				padding: 10px;
				background: $resetCMSFigureFigcaptionBackgroundColor;
			}
		}

		a:not([class]) {
			text-decoration: $resetCMSLinkTextDecoration;
		}
	}

	// debug
	[name*=" "] 
	{
		border: 2px solid $resetCMSNameBorderColor !important;
	}
}

figure.c-image {
	figcaption {
		font-size: rem($resetCMSFigureFigcaptionFontSize);
		line-height: rem(18px);
		padding: 10px;
		background: $resetCMSFigureFigcaptionBackgroundColor;
	}
}

html 
{

	@include critical {
		background: $siteHTMLBackgroundColor;
		margin: 0;
		padding: 0;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		@include text-size-adjust(100%);
		min-width: 320px;
		overflow-y:scroll;
	}

	&.disable-scroll
	{

		@include defer {
			overflow: hidden;
		}
	}
}

.html--lockout {

	@include defer {
		height: 100%;
		position: fixed;
		overflow: hidden;
		width: 100%;

		.body {
			position: static;
		}
	}
}

body 
{

	@include critical {
		background: $siteBodyBackgroundColor;
		color: $siteBodyColor;
		font-family: $fontBase;
		font-size: rem($fontSize);
		line-height: rem($fontHeight);
		margin: 0;
		padding: 0;
		text-align: center;
		min-width: 320px;
		@include mq-min($bpDesktop)
		{
			min-width: 960px;
		}
	}

	@include defer {
		.slideout-open &
		{
			overflow: hidden;
		}
	}
}


@include defer {
	.js-focus-visible .focus-visible:not(input,textarea,select) {
		@include focusStyles();
	}
}
