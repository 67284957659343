﻿@use "sass:math";
@use "sass:list";
@use "sass:meta";

@mixin min-height($a: 5px)
{
	min-height: $a;
	height: auto !important;
	height: $a;
}

@mixin opacity($a: .8)
{
	opacity: $a;
}

@mixin gradient($a: #eee, $b: #fff) {
	background-image: linear-gradient(to bottom, $b 0%, $a 100%);
}

@mixin gradient-alt($a: #eee, $b: #fff)
{
	background-image: linear-gradient(to right, $b, $a);
}

@mixin transition($a: all .3s)
{
	transition: $a;
}

@mixin animation ($a, $b: .2s, $c: linear, $e: 1, $f: forwards, $d: .1s)
{
	animation: $a $b $c $d $e $f;
}

@mixin background-size($a: 100% auto)
{
	background-size: $a;
}

@mixin background-clip($a: padding-box)
{
	background-clip: $a;
}

@mixin box-shadow($a: 0 0 10px 0 rgba(0,0,0,.25))
{
	box-shadow: $a;
}

@mixin box-sizing($a: border-box)
{
	box-sizing: $a;
}

@mixin border-radius($a: 10px)
{
	border-radius: $a;
}

@mixin border-radius-adv($tl: 10px, $tr: 10px, $bl: 10px, $br: 10px)
{
	border-top-right-radius: $tr;
	border-bottom-right-radius: $br;
	border-bottom-left-radius: $bl;
	border-top-left-radius: $tl;
	@include background-clip();
}

@mixin text-shadow($a: 1px 1px 1px rgba(0,0,0,.25))
{
	text-shadow: $a;
}

@mixin text-overflow($a: ellipsis)
{
	overflow: hidden;
	text-overflow: $a;
	white-space: nowrap;
}

@mixin text-hidden()
{
	overflow: hidden;
	text-indent: 200%;
	white-space: nowrap;
}

@mixin text-size-adjust($value: none) {
	-webkit-text-size-adjust: $value;
	-moz-text-size-adjust: $value;
	-ms-text-size-adjust: $value;
	-o-text-size-adjust: $value;
	text-size-adjust: $value;
}

@mixin placeholder-text($a: #888, $b: rgba(0,0,0,.75))
{
	&:-ms-input-placeholder
	{
		color: $a;
		color: $b;
		opacity: 1;
	}

	&::-webkit-input-placeholder
	{
		color: $a;
		color: $b;
	}

	&::placeholder
	{
		color: $a;
		color: $b;
	}
}

@mixin transform($a)
{
	transform: $a;
}

@mixin rotate($a: -5deg)
{
	transform: rotate($a);
}

@mixin user-select($a: none)
{
	-webkit-user-select: $a;
	-moz-user-select: $a;
	-ms-user-select: $a;
	user-select: $a;
}

@mixin appearance-none() {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

@mixin ratio($width, $height, $value: 'padding-top') {
  #{$value}: (math.div($height, $width) * 100) * 1%;
}

@mixin clearfix() {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin bold() {
	font-weight: $resetStrongFontWeight;
	font-family: $resetStrongFontFamily;
}

@mixin boldest() {
	font-weight: $resetBoldestFontWeight;
	font-family: $resetBoldestFontFamily;
}

@mixin heading() {
	font-weight: $resetStrongFontWeight;
	font-family: $resetHeadingFontFamily;
}

@mixin base-font() {
	font-weight: normal;
	font-family: $fontBase;
}

@mixin background-fill() {
	background: url('data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==') repeat 0 0;
}

@mixin accessible-hide() { // Use sparingly as introduces bloat
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	white-space: nowrap;
}

@mixin accessible-show() {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	padding: 0;
	position: static;
	width: auto;
	white-space: normal;
}

@mixin focusStyles() {
	outline: 2px solid $resetLinkFocusOutlineColor;
}

@mixin focusState() {
	&:focus {
		outline: 1px dotted #00abee;
	}
}

// MEDIA QUERY MIXIN
$mq: false !default;

@mixin mq-min($a)
{
	@if $mq
	{
		@if $mq >= $a
		{
			@content;
		}
	}
	@else
	{
		@media screen and (min-width: $a)
		{
			@content;
		}
	}
}

@mixin mq-max($a)
{
	@if $mq
	{
		@if $mq <= $a
		{
			@content;
		}
	}
	@else
	{
		@media screen and (max-width: $a)
		{
			@content;
		}
	}
}

// CRITICAL / DEFERRED

@mixin critical {
	@if $critical { @content; }
}

@mixin defer {
	@if $deferred { @content; }
}

// REMS

$rem-baseline: 16px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

@function rem-separator($list, $separator: false) {
	@if $separator == "comma" or $separator == "space" {
		@return list.append($list, null, $separator);
	}

	@if meta.function-exists("list.separator") == true {
		@return list.separator($list);
	}

	// list.separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
	$test-list: ();

	@each $item in $list {
		$test-list: list.append($test-list, $item, space);
	}

	@return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
	font-size: math.div($zoom, 16px) * $rem-baseline;
}

@function rem-convert($to, $values...) {
	$result: ();
	$separator: rem-separator($values);

	@each $value in $values {
		@if meta.type-of($value) == "number" and math.unit($value) == "rem" and $to == "px" {
			$result: list.append($result, math.div($value, 1rem) * $rem-baseline, $separator);
		}
		@else if meta.type-of($value) == "number" and math.unit($value) == "px" and $to == "rem" {
			$result: list.append($result, math.div($value, $rem-baseline) * 1rem, $separator);
		}
		@else if meta.type-of($value) == "list" {
			$value-separator: rem-separator($value);
			$value: rem-convert($to, $value...);
			$value: rem-separator($value, $value-separator);
			$result: list.append($result, $value, $separator);
		}
		@else {
			$result: list.append($result, $value, $separator);
		}
	}

	@return if(list.length($result) == 1, list.nth($result, 1), $result);
}

@function rem($values...) {
	@if $rem-px-only {
		@return rem-convert(px, $values...);
	}
	@else {
		@return rem-convert(rem, $values...);
	}
}

@mixin rem($properties, $values...) {
	@if meta.type-of($properties) == "map" {
		@each $property in map-keys($properties) {
			@include rem($property, map-get($properties, $property));
		}
	}
	@else {
		@each $property in $properties {
			@if $rem-fallback or $rem-px-only {
				#{$property}: rem-convert(px, $values...);
			}

			@if not $rem-px-only {
				#{$property}: rem-convert(rem, $values...);
			}
		}
	}
}

// interaction mq with fallback

@mixin interaction-mq($mq, $fallback) {
	@media ($mq) {
		@content;
	}

	@if $fallback {
		@at-root {
			.interaction-media-query-not-supported {
				@content;
			}
		}
	}
}

@mixin line-through() {
	position: relative;
	&::after {
		border-bottom: 2px solid currentColor;
		content: "";
		left: 0;
		margin-top: -0.5px;
		position: absolute;
		right: 0;
		top: 50%;
	}
}

@mixin reset-button() {
	border: none;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	background-color: transparent;
	@include appearance-none();
}
