.c-newsletter {

	@include critical {
		background: $cNewsletterBackground;
		padding: 40px;
		border: 2px solid $cNewsletterBorderColor;
		text-align: center;
		color: $cNewsletterColor;

		.c-article-list__list-item & {
			height: 100%;
			display: flex;
			align-items: center;
			border: 0;
		}
	}
}

.c-newsletter--subscribed {
	@include critical {
		padding: 20px;

		@include mq-min($bpDesktop) {
			padding: 30px;
		}
	}
}

.c-newsletter__title {

	@include critical {
		font-size: rem(26px);
		line-height: rem(32px);
		font-weight: 600;
		margin: 0 0 15px 0;
		padding: 0;
	}
}

.c-newsletter__description {

	@include critical {
		margin: 0 0 25px 0;
	}
}

.c-newsletter__icon {

	@include critical {
		margin: 0 auto 25px;
		width: 60px;
		height: 66px;
		color: $cNewsletterIconColor;
	}
}

.c-newsletter__input {

	@include critical {
		width: 100%;
		margin: 0 0 15px 0;
		height: 40px;
		line-height: rem(40px);
		border: 1px solid $cNewsletterInputBorderColor;
		padding: 0 15px;
		@include border-radius(0);
		@include box-sizing();
		font-family: Helvetica, Arial, Verdana, sans-serif;
		@include appearance-none();
		box-shadow: inset 0px 0px 0px 1px $cNewsletterInputBoxShadowColor;
		color: $cNewsletterInputColor;
	}
}

.c-newsletter__button {

	@include critical {
		width: 100%;
	}
}

.c-newsletter__error {

	@include critical {
		margin: -10px 0 15px 0;
		color: $cNewsletterErrorColor;
		display: block;
		font-weight: 600;
		text-align: left;

		&:empty {
			display: none;
		}
	}
}

.c-newsletter__social {

	@include critical {
		margin: 40px 0 0 0;
	}
}

.c-newsletter__social--subscribed {

	@include critical {
		margin: 0;
	}
}

.c-newsletter__form-checkbox {
	@include critical {
		display: flex;
		align-items: baseline;
		margin-bottom: rem(30px);
		font-size: rem(18px);
	}
}

.c-newsletter__form-checkbox-input:focus {
	@include defer {
		border-color: $formTextTextareaSelectUploadFocusBorderColor;
		@include box-shadow($formTextTextareaSelectUploadFocusBoxShadow);

		&.focus-visible {
			@include focusStyles();
		}
	}
}

.c-newsletter__form-checkbox-label-container {
	@include critical {
		margin-left: rem(5px);
	}
}

.c-newsletter__form-checkbox-label {
	@include critical {
		cursor: pointer;
		text-align: left;
	}
}

.c-newsletter__disclaimer {
	@include critical {
		margin-top: rem(15px);
		font-size: rem(14px);
		text-align: center;

		a {
			color: inherit;
			text-decoration: underline;
		}

		p {
			padding: 0;
		}

	}

	@include defer {
		a:focus,
		a:hover {
			text-decoration: none;
		}
	}
}

.c-newsletter--horizontal {

	@include critical {
		border: 0;
		padding: 0;

		.c-section--spaced-desktop-equal & {
			padding-bottom: 10px;
		}

		.c-newsletter__icon {
			display: none;
		}

		.c-newsletter__input {

			@include mq-min($bpDesktop) {
				margin: 0;
			}
		}

		.c-newsletter__button {
			width: auto;
			min-width: 200px;

			@include mq-min($bpDesktop) {
				position: absolute;
				top: 0;
				right: 0;
			}
		}

		.c-newsletter__wrapper {
			max-width: 600px;
			margin: 0 auto;

			@include mq-min($bpDesktop) {
				position: relative;
				padding-right: 230px;
			}
		}

		.c-newsletter__error {
			margin: 15px 0 0 0;
			text-align: center; 
		}
	}
}

.c-newsletter__help-container {
	@include defer {
		background-color: $colorWhite;

		.c-newsletter {
			border: none;
		}

		@include mq-min($bpDesktop) {
			.c-newsletter__button {
				height: 3rem;
				line-height: 3rem;
			}
		}
	}
}

.c-newsletter--horizontal--full {
	border: none;
	padding: 0;
	max-width: 650px;
	margin-left: auto;
	margin-right: auto;
}

	.c-newsletter--horizontal--full .c-newsletter__icon {
		display: none;
	}

	.c-newsletter--horizontal--full .c-newsletter-input {
		margin-bottom: 15px;
	}

	.c-newsletter--horizontal--full .c-newsletter__button {
		width: 100%;
		max-width: 300px;
	}
