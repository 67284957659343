.c-wishlist-add-button {

	@include critical {
		display: flex;
		align-items: center;
		cursor: pointer;
		font-size: inherit;
		font-family: inherit;
		@include bold();
		border: none;
		background-color: $colorWhite;
		color: $colorBase;
		padding: 5px;
		text-align: center;
		justify-content: center;
		width: 34px;
		height: 34px;
	}
}

.c-wishlist-add-button--small {
	@include critical {
		background-color: transparent;
		width: 48px;
		height: 48px;
	}
}

.c-wishlist-add-button__small-inner {
	@include critical {
		background-color: rgba($colorWhite, 0.7);
		display: flex;
		width: 32px;
		height: 32px;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		pointer-events: none;

		.c-wishlist-add-button__heart,
		.c-wishlist-add-button__heart-full {
			transform: translateY(1px);
		}
	}
}

.c-wishlist-add-button__group {

	@include critical {
		display: flex;
		gap: 10px;
		align-items: center;
	}
}

.c-wishlist-add-button__heart {

	@include critical {
		margin-right: 10px;

		[data-in-wishlist] & {
			display: none;
		}
	}
}

.c-wishlist-add-button__heart-full {

	@include critical {
		margin-right: 10px;

		[data-not-in-wishlist] & {
			display: none;
		}
	}
}

.c-wishlist-add-button__heart,
.c-wishlist-add-button__heart-full,
.c-wishlist-add-button__text {

	@include critical {
		pointer-events: none;
		margin: 0;
	}
}

.c-wishlist-add-button--large {
	@include critical {
		height: 64px;
		width: 64px;
		flex-shrink: 0;

		@include mq-min($bpDesktop) {
			width: 48px;
		}

		.c-wishlist-add-button__tooltip {
			top: -90px;
			left: 0;

			&:before {
				left: calc(50% + 31px);
			}

			@include mq-min($bpDesktop) {
				left: 24px;

				&:before {
					left: 50%;
				}
			}
		}
	}
}


@include critical {
	.c-wishlist-add-button__tooltip {
		opacity: 0;
	}
}

@include defer {
	.c-wishlist-add-button__tooltip {
		position: absolute;
		top: -101px;
		left: 17px;
		transform: translateX(-50%) translateY(80px);
		background: rgba($colorBase,0.9);
		padding: 3px 6px;
		color: #FFF;
		white-space: nowrap;
		z-index: 100;
		transition: all 300ms;
		font-size: rem(14px);
		pointer-events: none;

		.c-product__wishlist-button--top & {
			@include mq-min($bpDesktop) {
				left: 24px;
			}
		}

		.mRange &,
		.c-product-page--ppc & {
			@include mq-max($bpDesktop - 1) {
				right: 0;
				left: auto;

				&:before {
					left: calc(100% - 30px);
				}
			}
		}

		&.c-wishlist-add-button__tooltip--shift-left {
			left: auto;
			right: -12px;

			&:before {
				left: calc(100% - 30px);
			}
		}
	}

	.c-wishlist-add-button__tooltip:before {
		content: '';
		width: 0;
		height: 0;
		border-left: solid 5px transparent;
		border-right: solid 5px transparent;
		border-top: solid 5px rgba($colorBase,0.9);
		position: absolute;
		transform: translateX(-50%) translateY(-2px);
		bottom: -7px;
		left: 50%;
	}

	.c-wishlist-add-button__tooltip[aria-hidden="true"] {
		.mRange & {
			@include mq-max($bpDesktop - 1) {
				transform: translateX(11px) translateY(90px);
			}
		}

		&.c-wishlist-add-button__tooltip--shift-left {
			transform: translateX(0) translateY(90px);
		}
	}

	.c-wishlist-add-button__tooltip[aria-hidden="false"] {
		opacity: 1;
		transform: translateX(-50%) translateY(70px);

		.mRange & {
			@include mq-max($bpDesktop - 1) {
				transform: translateX(11px) translateY(70px);
			}
		}

		&.c-wishlist-add-button__tooltip--shift-left {
			transform: translateX(0) translateY(70px);
		}
	}
}
