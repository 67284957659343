@include critical {

	.js-tooltip {
		display: none;
	}
}

@include defer {

	.js-tooltip {
		position: absolute;
		z-index: 10;
		opacity: 0;
		text-align: left;

		@include mq-min($bpDesktop) {
			transform: translateY(20px);
			@include transition(#{opacity 0.25s ease, transform 0.25s ease});
			width: 330px;
		}

		@include mq-max($bpDesktop - 1) {
			width: calc(100% - 40px);
			top: 50% !important; // to override inline styles
			left: 50% !important; // to override inline styles
			transform: translate(-50%, -50%);
			position: fixed;
			max-width: 500px;
		}
	}

	.js-tooltip--show {
		display: block;
	}

	.js-tooltip--visible {
		opacity: 1;

		@include mq-min($bpDesktop ) {
			transform: translateY(0);
		}
	}

	.js-tooltip__inner {
		border: 6px solid $mJSTooltipInnerBorderColor;
		padding: 33px 20px 20px 20px;
		background: $mJSTooltipInnerBackgroundColor;
		position: relative;

		.cms {
			font-size: rem(14px);
			line-height: rem(18px);

			a {
				font-weight: bold;
				text-decoration: underline;
			}

			p {
				padding: 0 0 15px 0;

				&:last-child {
					padding: 0;
				}
			}
		}
	}

	.js-tooltip__inner:not(.js-tooltip__inner--delivery) .cms p:first-child {
		color: $mJSTooltipInnerPFirstChildColor;
		@include bold();
	}

	.js-tooltip__close {
		position: absolute;
		top: 10px;
		right: 15px;
		padding: 0 22px 0 0;
		background: none;
		border: 0;
		margin: 0;
		@include appearance-none();
		cursor: pointer;
		font-family: $fontBase;
		color: $mJSTooltipCloseColor;

		&:before,
		&:after {
			content: '';
			display: block;
			width: 19px;
			height: 1px;
			background: $mJSTooltipCloseBeforeAfterBackgroundColor;
			position: absolute;
			top: 50%;
			right: 0;
			transform: rotate(45deg);
		}

		&:hover {
			color: $mJSTooltipCloseHoverColor;

			&:before,
			&:after {
				background: $mJSTooltipCloseHoverBeforeAfterBackgroundColor;
			}
		}

		&:after {
			transform: rotate(-45deg);
		}
	}
}

@include critical {

	.js-tooltip-trigger {
		width: rem(19px);
		height: rem(19px);
		border: 2px solid $mJSTooltipTriggerBorderColor;
		border-radius: 100%;
		text-align: left;
		background: $mJSTooltipTriggerBackgroundColor;
		color: $colorWhite;
		margin: 0 0 0 10px;
		position: relative;
		display: none;

		.js-toggle-title & {
			@include mq-min($bpDesktop) {
				position: absolute;
				right: 0;
				top: 2px;
			}
		}

		table &,
		table .js-tooltip-trigger-wrapper & {

			@include mq-min($bpDesktop) {
				top: 2px;
			}
		}

		@include mq-min($bpDesktop) {
			width: rem(15px);
			height: rem(15px);
		}

		.js & {
			display: inline-block;
			vertical-align: middle;
		}

		&:before {
			content: '?';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin-top: -1px;
			text-indent: 0;
			text-align: center;
			color: $mJSTooltipTriggerBeforeColor;
			font-weight: bold;
			font-size: rem(16px);
			line-height: rem(20px);

			@include mq-min($bpDesktop) {
				margin-top: -3px;
				font-size: rem(14px);
				line-height: rem(20px);
			}
		}

		.no-touch &:hover,
		&.js-tooltip-trigger--clicked {
			background: $mJSTooltipTriggerHoverBackgroundColor;
			outline: none;

			&:before {
				color: $mJSTooltipTriggerHoverBeforeColor;
			}
		}
	}
}

@include defer {

	.js-tooltip-dimmer {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $mJSTooltipDimmerBackgroundColor;
		@include opacity(0.25);
		display: none;
		z-index: 9;

		@include mq-min($bpDesktop) {
			display: none !important;
		}
	}

	.js-tooltip-dimmer--visible {
		display: block;
	}
}

@include critical {

	.js-tooltip-trigger-wrapper {
		position: relative;
		padding-left: 30px;
		display: inline-block;
		min-height: 21px;
		padding-top: 2px;
		vertical-align: top;

		@include mq-min($bpDesktop) {
			min-height: 19px;
			padding-top: 0;
			padding-left: 29px;
		}

		.js-tooltip-trigger {
			margin: 0;
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	.js-tooltip-trigger-wrapper--right {
		padding: 0;
		margin: 0 0 0 10px;

		.js-tooltip-trigger {
			position: relative;
		}
	}
	// alt styling
	.js-tooltip-trigger--alt {

		@include mq-min($bpDesktop) {
			background: $mJSTooltipTriggerAltBackgroundColor;
			border-color: $mJSTooltipTriggerAltBorderColor;

			.no-touch &:hover,
			&.js-tooltip-trigger--clicked {
				background: $mJSTooltipTriggerAltHoverBackgroundColor;

				&:before {
					color: $mJSTooltipTriggerAltHoverBeforeColor;
				}
			}
		}
	}

	.js-tooltip-trigger--filters {
		@include mq-max($bpDesktop) {
			margin-left: 3px;
		}
	}

}
