.c-button {

	@include critical {
		display: inline-block;
		margin: 0;
		padding: 0 20px;
		height: rem(40px);
		line-height: rem(40px);
		background: $cButtonBackgroundColor;
		color: $cButtonTextColor;
		font-size: rem(15px);
		@include appearance-none();
		cursor: pointer;
		box-sizing: border-box;
		text-align: center;
		border: 0;
		font-family: $fontBase;

		&[disabled],
		&.is-disabled {
			background-color: $cButtonDisabledBackgroundColor;
			color: $cButtonDisabledTextColor;
			cursor: not-allowed;
			pointer-events: none;
			opacity: .5;
		}
	}

	@include defer {
		transition: background 0.25s ease;

		&:focus {
			color: $cButtonTextHoverColor;
			background: $cButtonHoverBackgroundColor;
		}

		@media (hover: hover) {
			&:hover {
				color: $cButtonTextHoverColor;
				background: $cButtonHoverBackgroundColor;
			}
		}

		@include focusState();
	}
}

.c-button--secondary {

	@include critical {
		background-color: $cButtonSecondaryBackgroundColor;
		border: 3px solid $cButtonSecondaryBorderColor;
		color: $cButtonSecondaryColor;
		line-height: rem(33px);
	}

	@include defer {

		.c-radio-list__item__input:not(:checked):focus + &,
		&:focus {
			background-color: $cButtonSecondaryHoverBackgroundColor;
			border-color: $cButtonSecondaryHoverBorderColor;
			color: $cButtonSecondaryHoverColor;
		}

		@media (hover: hover) {
			&:hover {
				background-color: $cButtonSecondaryHoverBackgroundColor;
				border-color: $cButtonSecondaryHoverBorderColor;
				color: $cButtonSecondaryHoverColor;
			}
		}
	}

	.c-radio-list__item__input:checked + &,
	.c-radio-list__item__input:checked + &:focus {

		@include critical {
			border-color: $cButtonSecondaryCheckedBorderColor;
			background-color: $cButtonSecondaryCheckedBackgroundColor;
			color: $cButtonSecondaryCheckedColor;
		}
	}

	@media (hover: hover) {
		.c-radio-list__item__input:checked + &:hover {
			@include defer {
				border-color: $cButtonSecondaryCheckedBorderColor;
				background-color: $cButtonSecondaryCheckedBackgroundColor;
				color: $cButtonSecondaryCheckedColor;
			}
		}
	}

	.c-radio-list__item__input.focus-visible:focus + & {
		@include critical {
			@include focusStyles();
		}
	}

	.c-radio-list__item & {

		@include critical {
			border-width: 1px;
			line-height: rem(38px);
		}
	}

	.c-radio-list__item__input:disabled + &,
	.c-radio-list__item__input.is-disabled + & {

		@include critical {
			opacity: 0.5;
		}
	}
}

.c-button--emphasize {

	@include critical {
		background: $cButtonEmphasizeBackgroundColor;
		color: $cButtonEmphasizeTextColor;
		font-weight: 600;
	}

	@include defer {

		&:focus {
			background: $cButtonEmphasizeHoverBackgroundColor;
			color: $cButtonEmphasizeTextHoverColor;
		}

		@media (hover: hover) {
			&:hover {
				background: $cButtonEmphasizeHoverBackgroundColor;
				color: $cButtonEmphasizeTextHoverColor;
			}
		}
	}
}

.c-button--full {

	@include critical {
		width: 100%;
	}
}

.c-button--cta-on-dark {
	@include critical {
		text-transform: uppercase;
		font-weight: 600;
		padding: 0 20px;
		background-color: $cButtonCtaOnDarkBackgroundColor;
		color: $cButtonCtaOnDarkTextColor;

		@include mq-min($bpMassive) {
			padding: 0 40px;
		}

		@include mq-min($bpMega) {
			padding: 0 45px;
		}
	}

	@include defer {
		&:focus {
			color: $cButtonCtaOnDarkHoverTextColor;
			background: $cButtonCtaOnDarkHoverBackgroundColor;
		}

		@media (hover: hover) {
			&:hover {
				color: $cButtonCtaOnDarkHoverTextColor;
				background: $cButtonCtaOnDarkHoverBackgroundColor;
			}
		}
	}
}

.c-button--dark {

	@include critical {
		text-transform: uppercase;
		font-weight: 700;
		padding: 0 20px;
		background-color: $cButtonDarkBackgroundColor;
		color: $cButtonDarkTextColor;
		font-size: rem(16px);
		letter-spacing: 1.2px;
	}

	@include defer {
		&:focus {
			color: $cButtonDarkHoverTextColor;
			background: $cButtonDarkHoverBackgroundColor;
		}

		@media (hover: hover) {
			&:hover {
				color: $cButtonDarkHoverTextColor;
				background: $cButtonDarkHoverBackgroundColor;
			}
		}
	}
}

.c-button--dark--alt {
	@include critical {
		text-transform: none;
		letter-spacing: normal;
		height: rem(58px);
		line-height: rem(58px);
		font-size: rem(20px);
	}
}

.c-button--submit {

	@include critical {
		height: auto;
		padding: rem(15px) rem(20px);
		font-size: rem(18px);
		line-height: normal;
	}
}

.c-button__group-item {
	@include critical {
		margin-right: rem(10px);
	}
}

.c-button__group-item--margin-bottom {
	@include critical {
		margin-bottom: rem(10px);
	}
}

.c-button.c-button--large {

	@include critical {
		height: rem(64px);
		padding: 0 20px;
		font-size: rem(18px);
		line-height: rem(64px);

		@include mq-min($bpDesktop) {
			padding: 0 30px;
		}
	}
}

.c-button.c-button--medium {

	@include critical {
		padding: 14px 40px;
		font-size: rem(18px);
		line-height: normal;
		height: auto;
	}
}

.c-button--small-mobile {
	@include critical {
		@include mq-max($bpTablet) {
			font-size: rem(14px);
			padding: 0 10px;
		}
	}
}

.c-button--full-width-mobile {
	@include critical {
		@include mq-max($bpTablet) {
			display: block;
			width: 100%;
		}
	}
}

.c-button--with-icon {
		@include critical {
			display: inline-flex;
			align-items: center;
		}
}

.c-button__icon {
	@include critical {
		margin-left: 3px;
	}
}


.c-button--outline {
	@include critical {
		background-color: transparent;
		color: inherit;
		border: 1px solid $cButtonOutlineBorderColor;
		font-size: rem(20px);
		font-weight: 600;
		height: auto;
		line-height: normal;
		padding: rem(15px);
	}
}

.c-button--outline:hover,
.c-button--outline:focus {
	@include defer {
		background: $cButtonOutlineHoverBackgroundColor;
		color: $colorWhite;
	}
}
