.c-select {

	@include critical {
		display: inline-block;
		border: 0;
		margin: 0;
		color: $cSelectTextColor;
		box-shadow: none;
		@include appearance-none();
		text-align: left;
		background: transparent;
		border-radius: 0;
		line-height: normal;
		background-size: 14px auto;
		background-position: calc(100% - 13px) 50%;
		background-repeat: no-repeat;
		font-size: rem(16px);

		@include mq-min($bpDesktop) {
			font-size: rem(15px);
		}

		padding: 7px 35px 7px 15px;
		font-weight: 600;
		border: 1px solid #373737;
		font-family: $fontBase;
		box-sizing: border-box;

		&::-ms-expand {
			display: none;
		}

		&::-ms-value {
			background: none;
			color: $cSelectTextColor;
		}
	}

	@include defer {
		background-image: $cSelectBackgroundUrl;

		&:focus {
			background-color: $cSelectFocusBackgroundColor;
		}

		@include focusState();

		option.is-disabled {
			color: #9b9b9b;
		}
	}
}

.c-select--full {

	@include critical {
		width: 100%;
	}
}

.c-select--medium {
	@include critical {
		padding-top: 0;
		padding-bottom: 0;
		height: rem(64px);
	}
}

.c-select--large {

	@include critical {
		padding-top: 0;
		padding-bottom: 0;
		height: rem(64px);
		font-size: rem(18px);
	}
}
