@use "sass:color";

// LEGACY MODULES

// modules/_add-to-basket-overlay.scss

$mAtbSidePanelWidth: 955px;
$mAtbPanelWidth: 955px;
$mAtbDesktopBp: 990px;
$mAtbWideBp: ($mAtbSidePanelWidth + $mAtbPanelWidth); // 1235
$mAtbDeepBp: 628px; // iphone 6/7/8 Plus - https://www.kylejlarson.com/blog/iphone-6-screen-size-web-design-tips/
$mAtbAnimationTime: 0.5s;
$mAtbAnimationEasing: ease;
$mAtbHeaderHeight: 40px;
$mAtbHeaderHeight-deep: 100px;
$mAtbFooterHeight: 65px;
$mAtbFooterHeight-desktop: 90px;
$mAtbBackgroundBackgroundColor: $colorBlack !default;
$mAtbMainBackgroundColor: $colorWhite !default;
$mAtbMainHeaderInnerBackgroundColor: #f7f7f7 !default;
$mAtbMainHeaderInnerBorderColor: #ededed !default;
$mAtbMainHeaderImageBorderColor: #c6c7c7 !default;
$mAtbMainHeaderMessageColor: #1ba31a !default;
$mAtbMainHeaderMessageBeforeBackgroundUrl: url(#{$baseSitePath}/images/atb/sprite.png?v=#{$spriteVersion}) !default;
$mAtbMainAddedBackgroundColor: #f7f7f7 !default;
$mAtbMainAddedBorderColor: #ededed !default;
$mAtbMainAddedImageBorderColor: #c6c7c7 !default;
$mAtbMainAddedMessageColor: #1ba31a !default;
$mAtbMainAddedMessageBeforeBackgroundUrl: url(#{$baseSitePath}/images/atb/sprite.png?v=#{$spriteVersion}) !default;
$mAtbMainTitleBackgroundColor: $colorWhite !default;
$mAtbMainTitleColor: $colorBase !default;
$mAtbMainProductBorderColor: $colorWhite !default;
$mAtbMainProductBorderHoverColor: $colorDivider !default;
$mAtbMainProductBoxShadowHoverColor: rgba($colorDivider, .75) !default;
$mAtbMainProductTitleBorderColor: $colorDivider !default;
$mAtbMainProductTitleLinkColor: $colorGreyDarker !default;
$mAtbMainProductActionViewColor: #297c9d !default;
$mAtbMainProductActionViewHoverColor: $colorLink !default;
$mAtbMainProductStockStockColor: $colorGreySafe !default;
$mAtbMainProductStockStockBeforeBackgroundUrl: url(#{$baseSitePath}/images/misc/stock-crosses.png) !default;
$mAtbMainProductStockStockInColor: $colorSuccess !default;
$mAtbMainProductStockStockInBeforeBackgroundUrl: url(#{$baseSitePath}/images/misc/stock.png) !default;
$mAtbMainProductStockStockDeliveryColor: $colorBase !default;
$mAtbMainProductStockStockDeliveryBeforeBackgroundUrl: url(#{$baseSitePath}/images/misc/stock-delivery-tick.png) !default;
$mAtbMainFooterBorderColor: #ededed !default;
$mAtbMainFooterBackgroundColor: #f7f7f7 !default;
$mAtbMainFooterBasketButtonBeforeBackgroundUrl: url(#{$baseSitePath}/images/atb/sprite.png?v=#{$spriteVersion}) !default;
$mAtbMainFooterCloseColor: $colorBase !default;
$mAtbMainFooterCloseDesktopColor: $colorLink !default;
$mAtbMainFooterCloseBeforeBackgroundUrl: url(#{$baseSitePath}/images/atb/sprite.png?v=#{$spriteVersion}) !default;
$mAtbMainFooterCloseHoverColor: $colorLinkHover !default;
$mAtbDetailBackgroundColor: $colorWhite !default;
$mAtbDetailItemPagerBorderColor: $colorDivider !default;
$mAtbDetailItemPagerLinkBackroundColor: #b7b7b7 !default;
$mAtbDetailItemPagerLinkActiveBackroundColor: $colorLink !default;
$mAtbDetailItemPrevNextBackgroundUrl: url(#{$baseSitePath}/images/atb/sprite.png?v=#{$spriteVersion}) !default;
$mAtbDetailItemTitleColor: $colorBase !default;
$mAtbDetailItemStockBorderColor: $colorDivider !default;
$mAtbDetailItemFeaturesBorderColor: $colorDivider !default;
$mAtbDetailItemFeaturesColor: $colorGreyDarker !default;
$mAtbDetailItemFeaturesItemBeforeColor: $colorBase !default;
$mAtbDetailItemSpecsBorderColor: $colorDivider !default;
$mAtbDetailItemSpecsTitleColor: $colorBase !default;
$mAtbDetailItemSpecsTitleAfterBackgroundUrl: url(#{$baseSitePath}/images/atb/sprite.png?v=#{$spriteVersion}) !default;
$mAtbDetailItemSpecsContentBorderColor: $colorDivider !default;
$mAtbDetailCloseBackgroundColor: $colorLink !default;
$mAtbDetailCloseHoverColor: $colorLink !default;
$mAtbDetailCloseNoTouchHoverColor: $colorLinkHover !default;
$mAtbDetailCloseNoTouchHoverBeforeAfterBackgroundColor: $colorLinkHover !default;
$mAtbDetailLoaderBackgroundColor: $colorWhite !default;
$mAtbDetailLoaderCircleBackgroundColor: $colorGamma !default;
$mAtbQuantityAddAfterBackgroundUrl: url(#{$baseSitePath}/images/atb/sprite.png?v=#{$spriteVersion}) !default;
$mAtbQuantityWidgetButtonBackgroundColor: #00abee !default;
$mAtbQuantityWidgetButtonAfterBackgroundUrl: url(#{$baseSitePath}/images/atb/sprite.png?v=#{$spriteVersion}) !default;
$mAtbQuantityWidgetButtonHoverBackgroundColor: color.adjust($colorGamma, $lightness: -5%, $space: hsl) !default;
$mAtbQuantityWidgetValueBorderColor: #d7d7d7 !default;
$mAtbAddedMessageColor: #1ba31a !default;
$mAtbAddedMessageBeforeBackgroundUrl: url(#{$baseSitePath}/images/atb/sprite.png?v=#{$spriteVersion}) !default;
$mAtbDetailCloseColor: $colorLink !default;
$mAtbDetailCloseFont: $fontBase !default;
$mAtbMainFooterCloseFont: $fontBase !default;
$mAtbItemActionLinkColor: #297c9d !default;
$mAtbItemActionLinkColorHover: #297c9d !default;

// modules/_address-picker.scss

$mAddressPickerInnerContentListLevel1InnerColor: $colorBase !default;

// modules/_addresses.scss

$mAddressesBorderColor: $colorDivider !default;
$mAddressesListLevel1BorderColor: $colorDivider !default;

// modules/_banners.scss

$mBannersBackgroundColor: #f6f6f6 !default;
$mBannersSearchInnerBackgroundColor: $colorWhite !default;
$mBannersSearchInnerFormTextBorderColor: $colorWhite !default;
$mBannersSearchInnerFormButtonBackgroundColor: $colorWhite !default;
$mBannersSearchInnerFormButtonBackgroundUrl: url(#{$baseSitePath}/images/layout/header/search.png) !default;
$mBannersPrevNextBackgroundUrl: url(#{$baseSitePath}/images/modules/banners/arrows.png) !default;

// modules/_basket.scss

$mBasketTableTdThBorderColor: $colorDivider !default;
$mBasketTableTrBorderColor: $colorDivider !default;
$mBasketTableTdThDetailsImageBorderColor: $colorBorder !default;
$mBasketTableTdThDetailsComponentsColor: $colorGreySafe !default;
$mBasketTableTdThDetailsMoveColor: $colorGreySafe !default;
$mBasketTableTdThDetailsMovePrimaryColor: $colorGrey !default;
$mBasketTableTdThDetailsMovePrimaryHoverColor: $colorGrey !default;
$mBasketTableDiscountBackgroundColor: color.adjust($colorEmphasise, $lightness: 57%, $space: hsl) !default;
$mBasketTableDiscountTdThColor: $colorEmphasise !default;
$mBasketTableDiscountTdThBackgroundColor: color.adjust($colorEmphasise, $lightness: 57%, $space: hsl) !default;
$mBasketTableDiscountAltBackgroundColor: color.adjust($colorAlpha, $lightness: 57%, $space: hsl) !default;
$mBasketTableDiscountAltTdThColor: $colorAlpha !default;
$mBasketTableDiscountAltTdThBackgroundColor: color.adjust($colorAlpha, $lightness: 57%, $space: hsl) !default;
$mBasketTableHeaderBackgroundColor: color.adjust($colorDivider, $lightness: 10%, $space: hsl) !default;
$mBasketTableHeaderTdThSmallColor: color.adjust($colorGreySafe, $lightness: -1%, $space: hsl) !default;
$mBasketTotalDeliveryStyledBackgroundColor: color.adjust($colorAlpha, $lightness: 57%, $space: hsl) !default;
$mBasketTotalDeliveryStyledBorderColor: $colorDivider !default;
$mBasketTotalDeliveryStyledSmallColor: $colorBase !default;
$mBasketTotalDeliveryStyledOpenColor: $colorAlpha !default;
$mBasketTotalTotalTdThBorderColor: $colorDivider !default;
$mBasketAddressesTitleColor: $colorGreySafe !default;
$mBasketAddressesMessageColor: $colorGreySafe !default;
$mBasketAddressesCardDtColor: $colorGreySafe !default;
$mBasketSummaryQuantityColor: $colorGreySafe !default;
$mBasketSummaryQuantityTabletColor: $colorBase !default;
$mBasketTotalBackgroundUrl: url(#{$baseSitePath}/images/modules/basket/cards.png?v=#{$spriteVersion}) !default;

// modules/_block.scss

$mBlockBorderColor: $colorBorder !default;
$mBlockTitleBorderColor: $colorBorder !default;
$mBlockTitleColor: $colorAlpha !default;
$mBlockNavPrimaryColor: $colorBase !default;
$mBlockNavPrimaryHoverColor: $colorGrey !default;
$mBlockAltBackgroundColor: $colorBorder !default;
$mBlockAltBorderColor: $colorWhite !default;
$mBlockAltTitleColor: $colorBase !default;
$mBlockClearBorderColor: $colorBorder !default;
$mBlockClearTitleColor: $colorAlpha !default;
$mBlockClearTitleColorRedundant: $colorBase !default;
$mBlockClearTitleBorderColor: $colorDivider !default;
$mBlockQuietBackgroundColor: none !default;
$mBlockQuietTitleColor: $colorAlpha !default;

// modules/_blog-list.scss

// Empty //

// modules/_buying-guides.scss

// Empty //

// modules/_calculator.scss

$mCalculatorInputValidationBorderColor: $colorEmphasise !default;
$mCalculatorInputValidationBackgroundImage: none !default;
$mCalculatorFormPodBackgroundColor: $colorGreyLight !default;
$mCalculatorFormPodResulsBackgroundUrl: url(#{$baseSitePath}/images/calculator/bg.jpg) !default;
$mCalculatorFormRadiosItemLabelBeforeBackgroundColor: $colorWhite !default;
$mCalculatorFormRadiosItemLabelBeforeBorderColor: $colorOffWhite !default;
$mCalculatorFormRadiosItemLabelAfterBackgroundColor: $colorOffWhite !default;
$mCalculatorFormRadiosItemLabelAfterCheckedBackgroundColor: $colorGamma !default;
$mCalculatorFormResultsItemResultColor: #297c9c !default;

// modules/_categories-promo.scss

// Empty //

// modules/_categories.scss

$mCategoriesMenuTitleColor: $colorAlpha !default;
$mCategoriesMenuItemTitleColor: $colorBase !default;

// modules/_category-page.scss

$mCategoryPageContentBlockAColor: $colorBase !default;
$mCategoryPageContentBlockAHoverColor: $colorAlpha !default;
$mCategoryPageContentBlockHeaderColor: $colorAlpha !default;
$mCategoryPageCategoriesTitleColor: $colorBase !default;
$mCategoryPageCategoriesTitleHoverColor: $colorAlpha !default;

// modules/_connect.scss

$mConnectPrimaryBackgroundUrl: url(#{$baseSitePath}/images/modules/connect/icons.png) !default;

// modules/_content-group.scss

$mContentGroupInnerBorderColor: $colorDivider !default;

// modules/_delivery-checker.scss

$mDeliveryCheckerBorderColor: $colorDivider !default;
$mDeliveryCheckerThTdBorderColor: $colorDivider !default;
$mDeliveryCheckerThColor: $colorGreySafe !default;
$mDeliveryCheckerThTdBackgroundColor: color.adjust($colorDivider, $lightness: 10%, $space: hsl) !default;

// modules/_features.scss

$mFeaturesTitleFallbackBackgroundColor: $colorWhite !default;
$mFeaturesTitleBackgroundColor: rgba($colorWhite, .75) !default;
$mFeaturesTitleColor: $colorDark !default;
$mFeaturesTitleHoverColor: $colorAlpha !default;

// modules/_filter-slide-out.scss

$mFilterSlideOutBackgroundColor: $colorOffWhite !default;
$mFilterSlideOutControlsOpenLinkBackgroundColor: $colorBeta !default;
$mFilterSlideOutControlsOpenLinkColor: $colorWhite !default;
$mFilterSlideOutControlsOpenLinkBeforeBackgroundUrl: url(#{$baseSitePath}/images/layout/nav/toggle.svg) !default;
$mFilterSlideOutControlsStickyBackgroundColor: $colorWhite !default;
$mFilterSlideOutOverlayBackgroundColor: $colorBlack !default;
$mFilterContainerBackgroundColor: $colorOffWhite !default;
$mFilterCloseContainerBackgroundColor: $colorBeta !default;
$mFilterCloseContainerCloseResetColor: $colorWhite !default;
$mFilterCloseContainerCloseLinkColor: $colorWhite !default;
$mFilterCloseContainerCloseLinkBeforeBackgroundUrl: url(#{$baseSitePath}/images/misc/sprite-filter-slide-out.png) !default;
$mFilterCloseContainerResetLinkColor: $colorWhite !default;
$mFilterCloseContainerResetLinkColor: $colorWhite !default;
$mFilterFilterSectionTitleColor: $colorGamma !default;
$mFilterSubmitBackgroundColor: $colorBeta !default;
$mFilterSlideOutControlsSortHeight: 36px !default;
$mFilterSlideOutControlsWrapperHeight: 56px !default;

// modules/_filter.scss

$mFilterBorderColor: #9B9B9B !default;
$mFilterTitleColor: $colorGreyDarker !default;
$mFilterTitleMobileTabletColor: #373737 !default;
$mFilterTitleBeforeMobileTabletBackgroundUrl: url(#{$baseSitePath}/images/misc/sprite-filter-slide-out.png) !default;
$mFilterFormChecksRadiosBorderColor: $colorOffWhite !default;
$mFilterFormChecksRadiosMobileTabletBorderColor: $colorOffWhite !default;
$mFilterFormChecksRadiosLabelColor: $colorBase !default;
$mFilterFormChecksRadiosLabelHoverColor: $colorGrey !default;
$mFilterFormChecksRadiosLabelSmallColor: #373737 !default;
$mFilterFormChecksRadiosInputCheckedSpanBeforeBackgroundUrl: url(#{$baseSitePath}/images/misc/sprite-filter-slide-out.png) !default;
$mFilterFormChecksRadiosInputCheckedSpanBeforeBackgroundColor: $colorWhite !default;
$mFilterFormChecksRadiosSpanColor: $colorGreyDarker !default;
$mFilterFormChecksRadiosSpanBeforeBackgroundColor: $colorWhite !default;
$mFilterFormChecksRadiosSpanBeforeBorderColor: #373737 !default;
$mFilterFormChecksRadiosHoverSpanBeforeBackgroundColor: color.adjust($colorBorder, $lightness: -10%, $space: hsl) !default;
$mFilterFormChecksRadiosFocusSpanBeforeBackgroundColor: color.adjust($colorBorder, $lightness: -20%, $space: hsl) !default;
$mFilterFormChecksSpanBeforeBorderColor: #9B9B9B !default;
$mFilterFormChecksSpanBeforeHoverBorderColor: #9B9B9B !default;
$mFilterFormChecksSpanAfterBorderColor: #575757 !default;
$mFilterFormChecksSpanFocusColor: $colorAlpha !default;
$mFilterFormChecksSpanFocusOutlineColor: $resetLinkFocusOutlineColor !default;
$mFilterFormChecksImageSpanBeforeBackgroundUrl: url(#{$baseSitePath}/images/misc/sprite-filter-slide-out.png) !default;
$mFilterFormChecksImageSpanBeforeBackgroundColor: $colorWhite !default;
$mFilterFormChecksImageLabelBorderColor: #373737 !default;
$mFilterFormChecksImageLabelFocusColor: $colorAlpha !default;
$mFilterFormChecksImageLabelBackgroundColor: $colorWhite !default;
$mFilterFormChecksImageImageBorderColor: #b7b7b7 !default;
$mFilterShowMoreColor: $colorGamma !default;
$mFilterShowMoreAfterBackgroundUrl: url(#{$baseSitePath}/images/misc/sprite-filter-slide-out.png) !default;
$mFilterScrollableTrackBackgroundColor: #e1e1e1 !default;
$mFilterScrollableThumbBackgroundColor: #373737 !default;
$mFilterScrollableThumbHoverBackgroundColor: color.adjust(#373737, $lightness: 25%, $space: hsl) !default;
$mFilterInnerBackgroundColor: $colorWhite !default;
$mFilterByColor: $colorGreyDarker !default;
$mFilterActionBorderColor: $colorDivider !default;
$mFilterClearBackgroundColor: $colorWhite !default;
$mFilterClearPrimaryBackgroundColor: transparent !default;
$mFilterClearPrimaryColor: #373737 !default;
$mFilterClearPrimaryBorderColor: #373737 !default;
$mFilterClearPrimaryHoverFocusBackgroundColor: #373737 !default;
$mFilterClearPrimaryHoverFocusColor: $colorWhite !default;
$mFilterClearPrimaryBorderBorderColor: $colorBorder !default;
$mFilterSliderBackgroundColor: color.adjust($colorDivider, $lightness: 5%, $space: hsl) !default;
$mFilterSliderUiSliderBackgroundColor: color.adjust($colorDivider, $lightness: 5%, $space: hsl) !default;
$mFilterSliderUiSliderHandleBackgroundColor: $colorGamma !default;
$mFilterSliderUiSliderHandleHoverBackgroundColor: color.adjust($colorGamma, $lightness: -5%, $space: hsl) !default;
$mFilterSliderUiSliderHandleRangeBackgroundColor: color.adjust($colorAlpha, $lightness: 40%, $space: hsl) !default;
$mFilterSliderFormRowToColor: $colorGrey !default;
$mFilterTagsLinkBackgroundColor: $colorWhite !default;
$mFilterTagsLinkColor: #373737 !default;
$mFilterTagsLinkBeforeAfterBackgroundColor: #373737 !default;
$mFilterTagsLinkHoverColor: $colorWhite !default;
$mFilterTagsLinkHoverBackgroundColor: #373737 !default;
$mFilterTagsLinkHoverBeforeAfterBackgroundColor: $colorWhite !default;
$mFilterTagsBorderColor: #9B9B9B !default;
$mFilterNextDayDeliveryTitleColor: $colorSuccess !default;
$mFilterNextDayDeliveryTitleBackgroundUrl: url(#{$baseSitePath}/images/components/delivery/expedited.svg) !default;
$mFilterNextDayDeliveryTitleBeforeBackgroundUrl: url(#{$baseSitePath}/images/components/delivery/expedited.svg) !default;
$mFilterPlaceholderBackgroundColor: $colorGreyLightAlt !default;

// modules/_full-width-block.scss

$mFullWidthBlockBackgroundColor: $colorWhite !default;
$mFullWidthBlockAltBackgroundColor: $colorOffWhite !default;
$mFullWidthBlockDividerBorderColor: $colorDivider !default;
$mFullWidthBlockTitleColor: $colorAlpha !default;

// modules/_generic-error.scss

$mGenericErrorBackgroundColor: $colorEmphasise !default;
$mGenericErrorColor: $colorWhite !default;

// modules/_grid.scss

// Empty //

// modules/_header.scss

$mHeaderBackgroundColor: $colorBorder !default;
$mHeaderContentBackgroundColor: $colorAlpha !default;
$mHeaderContentColor: $colorWhite !default;
$mHeaderContentDesktopBackgroundColor: rgba(41, 124, 157, .8) !default;
$mHeaderContentSummaryAColor: $colorWhite !default;
$mHeaderContentSummaryAHoverColor: $colorWhite !default;
$mHeaderRangeContentBackgroundColor: none !default;
$mHeaderRangeContentColor: $colorBase !default;
$mHeaderRangeContentTitleColor: $colorAlpha !default;
$mHeaderRangeContentSummaryAColor: $colorBase !default;
$mHeaderRangeContentSummaryAHoverColor: $colorBase !default;
$mHeaderBrandContentBackgroundColor: none !default;
$mHeaderCategoryContentBackgroundColor: none !default;
$mHeaderTextBackgroundColor: none !default;
$mHeaderTextTitleColor: $colorAlpha !default;
$mHeaderCenteredBackgroundColor: none !default;
$mHeaderCenteredTitleColor: $colorGreyDarker !default;
$mHeaderCenteredReadMoreBackgroundColor: $colorWhite !default;

// modules/_help-link.scss

$mHelpLinkBackgroundUrl: url(#{$baseSitePath}/images/modules/help-link/icon.png) !default;
$mHelpLinkBorderColor: $colorWhite !default;

// modules/_js-tooltip.scss

$mJSTooltipInnerBorderColor: $colorGreyLight !default;
$mJSTooltipInnerBackgroundColor: $colorWhite !default;
$mJSTooltipInnerPFirstChildColor: $colorBeta !default;
$mJSTooltipCloseColor: $colorLink !default;
$mJSTooltipCloseHoverColor: $colorLinkHover !default;
$mJSTooltipCloseBeforeAfterBackgroundColor: $colorLink !default;
$mJSTooltipCloseHoverBeforeAfterBackgroundColor: $colorLinkHover !default;
$mJSTooltipTriggerBorderColor: $colorBeta !default;
$mJSTooltipTriggerBackgroundColor: $colorBeta !default;
$mJSTooltipTriggerBeforeColor: $colorWhite !default;
$mJSTooltipTriggerHoverBackgroundColor: $colorWhite !default;
$mJSTooltipTriggerHoverBeforeColor: $colorBeta !default;
$mJSTooltipDimmerBackgroundColor: $colorBlack !default;
$mJSTooltipTriggerAltBackgroundColor: #373737 !default;
$mJSTooltipTriggerAltBorderColor: #373737 !default;
$mJSTooltipTriggerAltHoverBackgroundColor: $colorWhite !default;
$mJSTooltipTriggerAltHoverBeforeColor: #373737 !default;

//  modules/_latest-post.scss

$mLatestPostTitleBorderColor: $colorDivider !default;
$mLatestPostTitleLinkColor: $colorBase !default;
$mLatestPostTitleLinkHoverFocusColor: $colorAlpha !default;
$mLatestPostMetaColor: $colorGrey !default;

// modules/_loading-block.scss

$mLoadingBlockColor: $colorGrey !default;
$mLoadingBlockBeforeBackgroundUrl: url(#{$baseSitePath}/images/misc/loader.gif) !default;

// modules/_mobile-search.scss

$mMobileSearchFallbackBackgroundColor: $colorBlack !default;
$mMobileSearchBackgroundColor: rgba($colorBlack, .85) !default;
$mMobileSearchFormBackgroundColor: $colorAlpha !default;
$mMobileSearchFormBorderColor: $colorWhite !default;
$mMobileSearchFormTextBackgroundColor: none !default;
$mMobileSearchFormTextColor: $colorWhite !default;
$mMobileSearchFormTextClearIconBackgroundUrl: 'data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE0IiB3aWR0aD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTE0IDEuNEwxMi42IDAgNyA1LjYgMS40IDAgMCAxLjQgNS42IDcgMCAxMi42IDEuNCAxNCA3IDguNGw1LjYgNS42IDEuNC0xLjRMOC40IDcgMTQgMS40eiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIi8+Cjwvc3ZnPgo=' !default;
$mMobileSearchFormButtonBackgroundColor: none !default;
$mMobileSearchContentBackgroundColor: $colorWhite !default;
$mMobileSearchIntroColor: $colorWhite !default;
$mMobileSearchResultsTitleColour: $colorGreyDark !default;
$mMobileSearchResultsTitleBackgroundColor: $colorOffWhite !default;
$mMobileSearchResultsInfoEmptyColor: $colorGrey !default;
$mMobileSearchMenuItemBorderColor: $colorOffWhite !default;
$mMobileSearchMenuLinkColor: $colorBase !default;
$mMobileSearchMenuLinkBMarkBackgroundColor: none !default;
$mMobileSearchMenuLinkIColor: $colorAlpha !default;
$mMobileSearchLoaderBackgroundColor: $colorWhite !default;
$mMobileSearchLoaderColor: $colorGrey !default;
$mMobileSearchProductImageBorderColor: $colorBorder !default;
$mMobileSearchProductTitleColor: $colorBase !default;
$mMobileSearchProductPriceColor: $colorEmphasise !default;
$mMobileSearchProductPriceWasColor: $colorBase !default;
$mMobileSearchProductPriceSaleColor: $colorEmphasise !default;
$mMobileSearchCloseBackgroundColor: $colorAlpha !default;
$mMobileSearchCloseBorderColor: $colorWhite !default;
$mMobileSearchCloseColor: $colorWhite !default;
$mMobileSearchCloseIconColor: $colorWhite !default;

// modules/_need-some-help.scss

$mNeedSomeHelpColor: $colorBase !default;
$mNeedSomeHelpBorderColor: $colorBorder !default;
$mNeedSomeHelpInnerPhoneColor: $colorAlpha !default;

// modules/_order.scss

$mOrderSummaryDetailsDtColor: $colorGreySafe !default;

// modules/_pager.scss

$mPagerInnerListLevel1PrimaryBackgroundColor: $colorBorder !default;
$mPagerInnerListLevel1PrimaryActiveColor: $colorBase !default;
$mPagerInnerListLevel1RangeColor: $colorGrey !default;
$mPagerInnerAllPrimaryBackgroundColor: $colorBorder !default;

// modules/_popups.scss

$mPopupFadeBackgroundColor: $colorBlack !default;
$mPopupBackgroundColor: $colorWhite !default;
$mPopupBoxShadow: 0 0 10px rgba(0,0,0,.3) !default;
$mPopupGalleryLinkBackgroundColor: $colorDivider !default;
$mPopupGalleryLinkBorderColor: $colorWhite !default;
$mPopupGalleryLinkHoverBackgroundColor: $colorGrey !default;
$mPopupGalleryLinkActiveBackgroundColor: $colorLink !default;
$mPopupGalleryPrevNextBackgroundColor: $colorWhite !default;
$mPopupGalleryPrevNextBeforeBackgroundUrl: url(#{$baseSitePath}/images/modules/top-sellers/arrows.png) !default;
$mPopupLoaderBackgroundColor: $colorWhite !default;
$mPopupLoaderBackgroundUrl: url(#{$baseSitePath}/images/misc/loader.gif) !default;
$mPopupCloseBackgroundUrl: url(#{$baseSitePath}/images/misc/close.png) !default;

// modules/_print.scss

$mPrintPrimaryBackgroundUrl: url(#{$baseSitePath}/images/modules/print/icon.png) !default;

// modules/_range.scss

$mRangeInnerContentListLevel1BorderColor: $colorBorder !default;

$mRangeItemBackgroundColor: $colorWhite !default;
$mRangeItemBorderDesktopColor: $colorWhite !default;
$mRangeItemInnerImageBackgroundColor: $colorWhite !default;
$mRangeItemInnerImageBorderColor: $colorBorder !default;
$mRangeItemInnerTitleTitlePrimaryColor: $colorGreyDarker !default;
$mRangeItemInnerTitleOptionsColor: $colorLink !default;
$mRangeItemInnerTitleReviewColor: $colorGreySafe !default;
$mRangeItemInnerTitleReviewRatingBackgroundUrl: url(#{$baseSitePath}/images/modules/stars/icons-small.png) !default;
$mRangeItemInnerStockColor: $colorGreySafe !default;
$mRangeItemInnerStockBeforeBackgroundUrl: url(#{$baseSitePath}/images/misc/stock-crosses.png) !default;
$mRangeItemInnerStockInExpeditedColor: $colorSuccess !default;
$mRangeItemInnerStockInExpeditedBeforeBackgroundUrl: url(#{$baseSitePath}/images/misc/stock.png) !default;
$mRangeItemInnerStockDeliveryColor: $colorGreyDarker !default;
$mRangeItemInnerStockDeliveryBeforeBackgroundUrl: url(#{$baseSitePath}/images/misc/stock-delivery.png) !default;
$mRangeItemInnerPricePriceColor: $colorGreyDarker !default;
$mRangeItemInnerPricePriceSaleColor: $colorEmphasise !default;
$mRangeItemInnerPriceRRPColor: $colorGreySafe !default;
$mRangeItemHoverBorderColor: $colorDivider !default;
$mRangeItemHoverBoxShadow: 0 0 10px 0 rgba($colorDivider, .75) !default;

$mRangeItemStandaloneBorderColor: $colorBorder !default;
$mRangeItemStandaloneHoverBorderColor: $colorDivider !default;
$mRangeItemStandaloneHoverBoxShadow: 0 0 10px 0 rgba($colorDivider, .75) !default;

// modules/_rating.scss

$mRatingBackgroundUrl: url(#{$baseSitePath}/images/misc/rating.png) !default;
$mRatingOnGreyBackgroundUrl: url(#{$baseSitePath}/images/misc/rating-on-grey.png) !default;

// modules/_share.scss

$mShareInnerBorderColor: $colorDivider !default;
$mShareInnerEmailPrimaryBackgroundUrl: url(#{$baseSitePath}/images/modules/share/email.png) !default;

// modules/_stars.scss

$mStarsTotalColor: $colorGreySafe !default;
$mStarsRatingLinkColor: $colorBase !default;
$mStarsRatingLinkHoverColor: $colorBase !default;
$mStarsStarsBackgroundUrl: url(#{$baseSitePath}/images/modules/stars/icons.png?v=3) !default;
$mStarsStarsRevealBackgroundUrl: url(#{$baseSitePath}/images/modules/stars/icons.png?v=3) !default;
$mStarsStarsSmallBackgroundUrl: url(#{$baseSitePath}/images/modules/stars/icons-small.png?v=3) !default;
$mStarsStarsSmallRevealBackgroundUrl: url(#{$baseSitePath}/images/modules/stars/icons-small.png?v=3) !default;

// modules/_tooltip.scss

$mTooltipBackgroundColor: color.adjust($colorWhite, $lightness: -10%, $space: hsl) !default;
$mTooltipBorderColor: $colorDivider !default;

// modules/_typeahead.scss

$mTypeaheadMenuBackgroundColor: $colorWhite !default;
$mTypeaheadMenuBoxShadow: 0 3px 3px rgba(0,0,0,.15) !default;
$mTypeaheadMenuColor: $colorBase !default;
$mTypeaheadMenuBeforeBorderColor: $colorWhite !default;
$mTypeaheadMenuAfterBackgroundColor: $colorBorder !default;
$mTypeaheadMenuHighlightColor: $colorDark !default;
$mTypeaheadMenuHeaderTitleColor: $colorDark !default;
$mTypeaheadMenuHeaderLoadingColor: $colorGrey !default;
$mTypeaheadMenuFooterLinkBackgroundColor: $colorGamma !default;
$mTypeaheadMenuFooterLinkColor: $colorWhite !default;
$mTypeaheadMenuFooterLinkHoverBackgroundColor: color.adjust($colorGamma, $lightness: -5%, $space: hsl) !default;
$mTypeaheadMenuFooterLinkHoverColor: $colorWhite !default;
$mTypeaheadMenuSuggestionLinkColor: $colorBase !default;
$mTypeaheadMenuSuggestionLinkCategoryColor: $colorAlpha !default;
$mTypeaheadMenuSuggestionLinkCategoryHighlightColor: $colorAlpha !default;
$mTypeaheadMenuSuggestionLinkHoverBackgroundColor: color.adjust($colorDelta, $lightness: 7%, $space: hsl) !default;
$mTypeaheadMenuSuggestionLinkHoverColor: $colorAlpha !default;
$mTypeaheadMenuSuggestionLinkHoverBackgroundColor: color.adjust($colorDelta, $lightness: 7%, $space: hsl) !default;
$mTypeaheadMenuSuggestionLinkHighlightHoverColor: $colorAlpha !default;
$mTypeaheadMenuSuggestionProductHoverBackgroundColor: color.adjust($colorDelta, $lightness: 7%, $space: hsl) !default;
$mTypeaheadMenuSuggestionProductPictureBorderColor: $colorBorder !default;
$mTypeaheadMenuSuggestionProductContentTitleColor: $colorBase !default;
$mTypeaheadMenuSuggestionProductContentTitleHoverColor: $colorAlpha !default;
$mTypeaheadMenuSuggestionProductContentTitleHighlightHoverColor: $colorAlpha !default;
$mTypeaheadMenuSuggestionProductContentRatingColor: $colorGreySafe !default;
$mTypeaheadMenuSuggestionProductContentRatingStarsBackgroundUrl: url(#{$baseSitePath}/images/modules/stars/icons-small.png?v=1) !default;
$mTypeaheadMenuSuggestionProductContentPriceColor: $colorEmphasise !default;
$mTypeaheadMenuSuggestionProductContentPriceSaleColor: $colorEmphasise !default;
$mTypeaheadMenuSuggestionProductContentPriceWasColor: $colorBase !default;
$mTypeaheadMenuSuggestionTTCursorLinkColor: $colorLink !default;
$mTypeaheadMenuSuggestionTTCursorLinkBackgroundColor: color.adjust($colorDelta, $lightness: 7%, $space: hsl) !default;
$mTypeaheadMenuSuggestionTTCursorLinkHighlightColor: $colorLink !default;
$mTypeaheadMenuSuggestionTTCursorProductBackgroundColor: color.adjust($colorDelta, $lightness: 7%, $space: hsl) !default;
$mTypeaheadMenuSuggestionTTCursorProductContentTitleColor: $colorAlpha !default;
$mTypeaheadMenuSuggestionTTCursorProductContentTitleHighlightColor: $colorAlpha !default;

$mTypeaheadLookupBorderColor: $colorBorder !default;
$mTypeaheadLookupCursorColor: #e8f3f7 !default;
