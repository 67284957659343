﻿.c-social {

	@include critical {
		text-align: center;
		min-height: 82px;
		.c-article-aside & {
			margin: 30px 0 0 0;
		}
	}
}


.c-social__title {

	@include critical {
		font-size: rem(26px);
		line-height: rem(32px);
		font-weight: 600;
		margin: 0 0 15px 0;
		padding: 0;
	}
}

.c-social__list {

	@include critical {
		margin: 0;
		padding: 0;
		list-style: none;
		font-size: 0;
		line-height: 0;
	}
}

.c-social__item {

	@include critical {
		display: inline-block;
		vertical-align: middle;
		padding: 0;

		&:not(:first-child) {
			margin: 0 0 0 5px;
		}
	}
}

.c-social__link {

	@include critical {
		display: block;
		color: $cSocialLinkColor;
		width: 48px;
		height: 48px;
		background: rgba(0,0,0,0);
		position: relative;
	}
}

.c-social__icon {

	@include critical {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
	}

	.c-social__item--instagram & {

		@include critical {
			width: 28px;
			height: 28px;
			margin: -14px 0 0 -14px;
		}
	}

	.c-social__item--twitter & {

		@include critical {
			width: 26px;
			height: 28px;
			margin: -14px 0 0 -13px;
		}
	}

	.c-social__item--pinterest & {

		@include critical {
			width: 20px;
			height: 28px;
			margin: -14px 0 0 -10px;
		}
	}

	.c-social__item--facebook & {

		@include critical {
			width: 16px;
			height: 28px;
			margin: -14px 0 0 -8px;
		}
	}
}

.c-social__well {

	@include critical {
		position: relative;
		background-color: #eef5f7;
		padding: rem(20px);
		margin-top: rem(30px);
		font-size: rem(18px);

		@include mq-min($bpDesktop) {
			margin-top: rem(40px);
		}

		&:before {
			content: '';
			position: absolute;
			top: -25px;
			margin-left: -25px;
			width: 0;
			height: 0;
			border-left: 25px solid transparent;
			border-right: 25px solid transparent;
			border-bottom: 25px solid #eef5f7;
		}
	}
}

.c-social__messenger-text {
	a {
		@include critical {
			color: inherit;
			text-decoration: underline;
		}
	}
}


.c-social__well-link {
	@include critical {
		color: inherit;
		text-decoration: underline;
	}
}


.c-social__well-icon {
	path {
		color: $cSocialIconColor;
	}
	@include critical {
		display: block;
		margin: 0 auto rem(20px) auto;
	}
}
