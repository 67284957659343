﻿.mStars
{

	@include critical {
		margin: 0;
		padding: 0 0 15px 0;
	}
	> .stars
	{

		@include critical {
			background: $mStarsStarsBackgroundUrl no-repeat -130px 0;
			font-size: 0;
			height: 22px;
			line-height: 0;
			margin: 0;
			padding: 0;
			position: relative;
			width: 127px;
			z-index: 1;
			> .reveal
			{
				background: $mStarsStarsRevealBackgroundUrl no-repeat 0 0;
				height: 26px;
				margin: 0;
				padding: 0;
				width: 0%;
			}
			> .total
			{
				color: $mStarsTotalColor;
				display: none;
				font-size: rem($fontSize - 2);
				line-height: rem($fontHeight - 2);
				margin: 0;
				padding: 0;
				position: absolute;
				right: 0;
				text-align: left;
				top: 1px;
				width: 45px;
				z-index: 1;
			}
			&.small
			{
				background: $mStarsStarsSmallBackgroundUrl no-repeat -80px 0;
				height: 16px;
				padding-right: 50px;
				width: 80px;
				> .reveal
				{
					background: $mStarsStarsSmallRevealBackgroundUrl no-repeat 0 0;
					height: 16px;
					margin: 0;
					padding: 0;
					width: 0%;
				}
				> .total
				{
					display: block;
				}
			}
		}
	}
	> .rating
	{

		@include critical {
			font-size: rem($fontSize - 2);
			font-weight: 700;
			line-height: rem($fontHeight - 2);
			margin: 0;
			padding: 5px 0 0 2px;
		}
		> .inner
		{

			@include critical {
				margin: 0;
				padding: 0;
			}
			> .link
			{

				@include critical {
					color: $mStarsRatingLinkColor;
					text-decoration: none;
				}

				&:hover
				{

					@include defer {
						color: $mStarsRatingLinkHoverColor;
						text-decoration: underline;
					}
				}
			}
		}		
	}
	&.tight
	{

		@include critical {
			padding-bottom: 0;
		}
	}
}