.c-header {

	@include critical {
		position: relative;
		z-index: 5555;
		transition: transform 300ms ease-out;


		&.is-sticky {
			position: sticky;
			top: 0;
			z-index: 9999;

			&.is-scrolling-down {
				transform: translateY(-100%);
			}

			&.no-transition {
				transition: none;
			}

			@media(min-width: 980px) and (max-height: 900px) {
				position: relative;
				top: auto;

				&.is-scrolling-down {
					transform: translateY(0);
				}
			}
		}
	}
}

.c-header__top {

	@include critical {
		display: none;

		@include mq-min($bpDesktop) {
			display: block;
			background: $cHeaderTopDesktopBackgroundColor $cHeaderTopDesktopBackgroundUrl repeat-x 0 100%;
		}
	}
}

.c-header__top__inner {

	@include critical {
		padding: 10px 20px;
	}
}

.c-header__call {

	@include critical {
		margin: 0;
		padding: 0;
		color: $cHeaderCallTextColor;
		float: left;
	}
}

.c-header__call__link {

	@include critical {
		color: $cHeaderCallLinkTextColor;
		letter-spacing: 0.5px;
		display: inline-block;
		font-size: rem(16px);
		line-height: rem(16px);
		font-weight: 600;
	}
}

.c-header__call__hours {

	@include critical {
		display: inline-block;
		margin: 0 0 0 8px;
		font-size: rem(14px);
		line-height: rem(14px);
		font-weight: 400;
	}
}

.c-header__quick-links {

	@include critical {
		margin: 0;
		padding: 0;
		list-style: none;
		font-size: rem(15px);
		line-height: rem(15px);
		font-weight: 500;
		float: right;
	}
}

.c-header__quick-links__item {

	@include critical {
		display: inline-block;
		margin: 0;
		padding: 0;

		&:not(:first-child) {
			padding: 0 0 0 22px;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 1px;
				position: absolute;
				top: 2px;
				bottom: 2px;
				left: 10px;
				background: $cHeaderQuickLinksDividerColor;
			}
		}
	}
}

.c-header__quick-links__link {

	@include critical {
		color: $cHeaderQuickLinksTextColor;
	}
}

.c-header__middle {

	@include critical {
		background: $cHeaderMiddleBackgroundColor;

		@include mq-max($bpSmallLogoHeaderMax) {
			height: 62.25px;
		}

		@include mq-min($bpDesktop) {
			position: relative;
			z-index: 100; /* Above the nav */
		}
	}
}

.c-header__middle__inner {

	@include critical {
		padding: 15px 20px;
	}
}

.c-header__middle__inner__inner {

	@include critical {
		position: relative;
	}
}

.c-header__logo {

	@include critical {
		width: 150px;
		margin: 0;
		padding: 0;

		.c-header--checkout & {
			margin: 0 auto;

			@include mq-min($bpDesktop) {
				width: 230px;
			}
		}

		@include mq-min(375px) {
			width: 175px;
		}

		@include mq-min($bpTabletLarge) {
			width: 185px;
		}

		@include mq-min($bpDesktop) {
			width: 295px;
			margin: 0 auto;
		}

		&.c-header__logo--trade {
			width: $cHeaderTradeLogoMobileWidth;

			@include mq-min($bpTabletLarge) {
				width: $cHeaderTradeLogoTabletWidth;
			}

			@include mq-min($bpDesktop) {
				width: $cHeaderTradeLogoDesktopWidth;
			}
		}
	}
}

.c-header__logo__link {

	@include critical {
		color: $cHeaderLogoLinkTextColor;
		display: block;
		position: relative;

		.c-icon {
			width: 100%;
			height: auto;
		}
	}
}

.c-header__search {

	@include critical {
		position: absolute;
		width: 230px;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		display: none;
		z-index: 10;

		.html--mega-nav-open & {
			z-index: auto;
		}

		@include mq-min($bpDesktop) {
			display: block;
		}
	}
}

.c-header__search__inner {

	@include critical {
		position: relative;
		width: $cHeaderSearchWidth;
		height: rem(40px);

		@include mq-min($bpLarge) {
			width: $cHeaderSearchWidthBpLarge;
		}
	}
}

.c-header__search__text {

	@include critical {
		background: transparent;
		margin: 0;
		padding: rem(10px);
		color: rgba($cHeaderSearchTextColor, 0.8);
		background: $cHeaderSearchTextBackgroundColor;
		@include placeholder-text($a: $cHeaderSearchTextColor, $b: rgba($cHeaderSearchTextColor,1));
		font-family: $fontBase;
		font-size: rem(14px);
		line-height: normal;
		font-weight: 600;
		letter-spacing: 0.2px;
		width: $cHeaderSearchWidth - $cHeaderSearchButtonWidth;
		border: 1px solid $cHeaderSearchBorderColor;
		transition: border 0.25s ease;
		will-change: border;
		@include appearance-none();
		border-radius: 0;

		@include mq-min($bpLarge) {
			width: $cHeaderSearchWidthBpLarge - $cHeaderSearchButtonWidth;
		}
	}

	&:focus {

		@include defer {
			border-color: $cHeaderSearchFocusBorderColor;

			&.focus-visible {
				@include focusStyles();
			}
		}
	}

	@include critical {
		/* clears the 'X' from Internet Explorer */
		&::-ms-clear {
			display: none;
			width: 0;
			height: 0;
		}

		&::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}
		/* clears the 'X' from Chrome */
		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			display: none;
		}
	}
}

.c-header__search__button {

	@include critical {
		width: $cHeaderSearchButtonWidth;
		height: rem(40px);
		border: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		top: 50%;
		@include background-fill();
		transform: translateY(-50%);
		cursor: pointer;
		background-color: $cHeaderSearchButtonBackgroundColor;

		.c-icon {
			width: 15px;
			height: 15px;
			color: $cHeaderSearchButtonIconColor;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.c-header__utils {

	@include critical {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 40px;
		z-index: 10;

		.html--mega-nav-open & {
			z-index: auto;
		}

		@include mq-min($bpDesktop) {
			right: 0;
		}
	}
}

.c-header__utils__list {

	@include critical {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style: none;
	}
}

.c-header__utils__item {

	@include critical {
		margin: 0;
		padding: 0;
		display: flex;
		align-items: flex-end;

		&:not(:first-child) {
			margin-left: 15px;

			@include mq-min($bpDesktop) {
				margin-left: 20px;
			}
		}
	}
}

.c-header__utils__item--account {

	@include critical {

		@include mq-max($bpMobileLarge - 1) {
			display: none;
		}

		position: relative;

		@include mq-max($bpDesktop - 1) {
			width: 30px;
			height: 30px;

			.c-header__utils__count {
				right: 3px;
			}
		}

		.c-icon {
			width: 30px;
			height: 30px;
		}

		.c-icon[aria-hidden="true"] {
			display: none;
		}
	}
}

.c-header__utils__item--wishlist {

	@include critical {

		@include mq-max($bpMobileMid - 1) {
			display: none;
		}

		position: relative;

		@include mq-max($bpDesktop - 1) {
			width: 30px;
			height: 30px;
		}

		.c-icon {
			width: 34px;
			height: 34px;
			margin-bottom: -2px;
		}

		.c-icon[aria-hidden="true"] {
			display: none;
		}
	}
}

@include mq-min($bpDesktop) {

	@include critical {
		.c-header__utils__item--account,
		.c-header__utils__item--wishlist {
			.c-header__utils__link {
				display: flex;
				align-items: center;
			}
			.c-icon {
				width: 18px;
				height: 18px;
				margin-right: 6px;
			}
		}
	}
}

.c-header__utils__item--with-sub {

	@include critical {
		position: relative;
	}
}

.c-header__utils__link {

	@include critical {
		color: $cHeaderUtilsLinkTextColor;
		display: flex;
		align-items: center;
	}
}

.c-header__utils__link--basket {

	@include critical {
		position: relative;
		width: 30px;
		height: 30px;
		@include background-fill();
		display: block;

		@include mq-min($bpDesktop) {
			padding: 0 40px 0 0;
			width: auto;
			height: auto;
			display: flex;
			align-items: flex-end;
		}

		.c-icon {
			width: 37px;
			height: 37px;
			margin-top: -3px;

			@include mq-min($bpDesktop) {
				width: 18.5px;
				height: 15.5px;
				position: relative;
				top: auto;
				left: auto;
				margin-right: 6px;
			}
		}
	}
}

.c-header__utils__text {

	@include critical {

		@include mq-max($bpDesktop - 1) {
			@include accessible-hide();
		}
	}
}

.c-header__utils__count {

	@include critical {
		display: block;
		position: absolute;
		font-weight: 700;
		text-align: center;
		right: -5px;
		bottom: -5px;
		background: $cHeaderUtilsCountBackgroundColor;
		font-size: rem(11px);
		line-height: rem(11px);
		padding: 3px 5px;
		border-radius: 10px;
		text-overflow: ellipsis;
		white-space: nowrap;

		@include mq-min($bpDesktop) {
			width: 30px;
			height: rem(30px);
			background: $cHeaderUtilsDesktopCountBackgroundColor;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			font-size: rem(16px);
			line-height: rem(28px);
			color: $cHeaderUtilsDesktopCountTextColor;
			padding: 0;
			border-radius: 0;
			bottom: auto;
		}
	}
}

.c-header__utils__sub {

	@include critical {
		visibility: hidden;
		position: absolute;
		opacity: 0;
		&[aria-hidden="true"] {
			display: none;
		}
	}

	@include defer {
		z-index: 10;
		top: 100%;
		left: 50%;
		@include background-fill();
		width: 126px;
		transition: opacity 0.25s ease;
		will-change: opacity;
		margin-left: -63px;

		.c-header__utils__item--hovering & {
			opacity: 1;
			visibility: visible;
		}
	}
}

.c-header__utils__sub__inner {

	@include defer {
		padding: 10px 0 0 0;
	}
}

.c-header__utils__sub__list {

	@include defer {
		margin: 0;
		padding: 10px;
		list-style: none;
		background: $cHeaderUtilsSubListBackgroundColor;
		box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.2);
		text-align: center;
		position: relative;

		&:after {
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-bottom: 8px solid $cHeaderUtilsSubListBackgroundColor;
			position: absolute;
			top: -6px;
			left: calc(50% - 8px);
		}
	}
}

.c-header__utils__sub__item {

	@include defer {
		margin: 0;
		padding: 0;

		&:not(:first-child) {
			margin: 5px 0 0 0;
		}
	}
}

.c-header__utils__sub__link {

	@include defer {
		color: $cHeaderUtilsSubLinkTextColor;
	}
}

.c-header__search-toggle {

	@include critical {
		padding: 0;
		position: absolute;
		@include background-fill();
		top: 50%;
		transform: translateY(-50%);
		right: 175px;

		@include mq-max($bpMobileLarge - 1) {
			right: 125px;
		}

		@include mq-max($bpMobileMid - 1) {
			right: 85px;
		}

		@include mq-min($bpDesktop) {
			display: none;
		}
	}
}

.c-header__search-toggle__link {

	@include critical {
		display: block;
		width: 30px;
		height: 30px;
		position: relative;

		.c-icon {
			width: 30px;
			height: 30px;
			color: $cHeaderSearchToggleIconColor;
		}
	}
}

.c-header__call__link,
.c-header__quick-links__link,
.c-header__logo__link,
.c-header__search__button,
.c-header__utils__link,
.c-header__utils__sub__link,
.c-header__search-toggle__link {

	@include defer {
		transition: opacity 0.25s ease;
		will-change: opacity;

		&:hover,
		&:focus {
			color: $cHeaderGenericLinkColor;
			opacity: 0.8;
		}
	}
}
