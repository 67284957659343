@include critical {

	.c-newsletter {
		box-sizing: border-box;
	}

	.c-newsletter-signup__title {
		color: $cNewsletterTitleTextColor;
		font-size: rem(16px);
		font-weight: 700;
		margin: 0 0 10px 0;
		padding: 0; 
	}

	.c-newsletter-signup__intro {
		color: $cNewsletterSignupIntroTextColor;
		margin: 0 0 15px 0;
		padding: 0;
	}

	.c-newsletter-signup__row {
		display: flex;
		width: 100%;
	}

.c-newsletter-signup__input {
	width: 100%;
	background-color: $cNewsletterSignupInputBackgroundColor;
	padding: 8px 12px;
	border: 1px solid $cNewsletterSignupInputBorderColor;
	font: inherit;
	font-size: rem(16px);
	@include mq-min($bpDesktop) {
		font-size: rem(14px);
	}
	@include placeholder-text($cNewsletterSignupInputPlaceholderColor, rgba($cNewsletterSignupInputPlaceholderColor, 1));
	@include appearance-none();
	border-radius: 0;
	min-width: 0; // required to stop element breaking out of container in Firefox
}

	.c-newsletter-signup__button {
		border: none;
		color: $cNewsletterSignupButtonTextColor;
		background-color: $cNewsletterSignupButtonBackgroundColor;
		font-weight: bold;
		cursor: pointer;
		transition: background-color 300ms;
		padding: 0 5px;
		width: 120px;
		white-space: nowrap;
	}
}

@include defer {

	.c-newsletter-signup__input:focus {
		border-color: $cNewsletterSignupInputFocusBorderColor;
	}

	.c-newsletter-signup__button:hover,
	.c-newsletter-signup__button:focus {
		background-color: $cNewsletterSignupButtonHoverBackgroundColor;
	}
}
