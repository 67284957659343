.c-nav {

	@include critical {
		background: $cNavBackground;
		color: $cNavColor;

		@include mq-max($bpDesktop - 1) {
			display: none;
		}
	}

	@include defer {

		@include mq-max($bpDesktop - 1) {
			position: absolute;
			right: 0;
			transform: translateX($cNavWidth);
			text-align: left;
			top: 0;
			height: 100vh;
			height: 100dvh;
			width: $cNavWidth;
			background: $cNavBackground $cNavMobileBackgroundImage no-repeat right bottom;
		}

		@include mq-min($bpDesktop) {
			position: relative;
			z-index: 99;
			height: auto !important; // to override JavaScript
		}
	}
}

.c-nav__inner {

	@include critical {

		@include mq-min($bpDesktop) {
			max-width: $cNavInnerMaxWidth;
			margin: 0 auto;
			z-index: 99999; // above the dimmer
			position: relative;
		}
	}

	@include defer {

		@include mq-max($bpDesktop - 1) {
			height: 100%;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
		}
	}
}

.c-nav__mobile-trigger {

	@include critical {
		background-color: transparent;
		border: none;
		background-image: $cNavTitleBackgroundImage;
		background-size: 25px 20px;
		background-position: 50%;
		background-repeat: no-repeat;
		width: 50px;
		height: 50px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;

		@include mq-min($bpDesktop) {
			display: none;
		} 
	}
}

.c-nav__list {

	@include critical {
		margin: 0;
		padding: 0;
		list-style: none;

		@include mq-min($bpDesktop) {
			display: flex;
			justify-content: center;
			font-size: 0;
			line-height: 0;
			text-align: center;
		}
	}

	@include defer {
		@include mq-max($bpDesktop - 1) {
			background-color: $cNavBackground;
		}
	}
}

.c-nav__item {

	@include critical {
		padding: 0;

		@include mq-max($bpDesktop - 1) {
			position: relative;
			border-bottom: 1px solid $cNavItemBorderColorTwo;

			&:not(:first-child) {
				border-top: 1px solid $cNavItemBorderColorOne;
			}

			&--sale {
				border-bottom-color: $cNavItemSaleBorderBottomColor;
			}

			&--sale:not(:first-child) {
				border-top-color: $cNavItemSaleBorderTopColor;
			}

			&--feature {
				border-bottom-color: $cNavItemFeatureBorderBottomColor;
			}

			&--feature:not(:first-child) {
				border-top-color: $cNavItemFeatureBorderTopColor;
			}
		}

		@include mq-min($bpMobileMid) {
			&--mobile-small-only {
				display: none !important;
			}
		}

		@include mq-min($bpDesktop) {
			text-align: left;
			display: flex;

			&--mobile-only {
				display: none !important;
			}

			&--2 {
				max-width: 50%;
			}

			&--3 {
				max-width: 33.3%;
			}

			&--4 {
				max-width: 25%;
			}

			&--5 {
				max-width: 20%;
			}

			&--6 {
				max-width: 16.6%;
			}

			&--7 {
				max-width: 14.2%;
			}

			&--8 {
				max-width: 12.4%;
			}

			&--9 {
				max-width: 11.1%;
			}

			&--10 {
				max-width: 10%;
			}
		}

		@include mq-min($bpMassive) {
			max-width: none;
		}
	}

	@include defer {

		&.c-nav__item--alt {
			border-bottom: 1px solid $cNavItemBorderColorSeven;
			border-top: 1px solid $cNavItemBorderColorSix;
		}
	}
}

@mixin navLinkMobileStyle {
	font-size: rem(14px);
	line-height: rem(18px);
	padding: 15px 54px 15px 10px;
	display: block;
	color: $cNavLinkColor;
	font-weight: 600;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	&:hover,
	&:focus {
		color: $cNavLinkColor;

		@include mq-min($bpDesktop) {
			background: $cNavLinkHoverBackground;
			color: $cNavLinkHoverColor;
		}
	}

	.c-nav__item--feature > & {
		background-color: $cNavItemFeatureBackgroundColor;
		color: $cNavItemFeatureFontColor;

		+ .c-nav__more:before {
			border-left-color: $cNavItemFeatureFontColor;
		}
	}

	.c-nav__item--feature > & {
		.no-touch &:hover {
			background: $cNavItemFeatureHoverBackgroundColor;
		}
	}

	.c-nav__item--feature > &[aria-expanded="true"] {
		background: $cNavItemFeatureHoverBackgroundColor;
		color: $cNavItemFeatureFontColor;
	}

	.c-nav__item--sale > & {
		background-color: $cNavItemSaleBackgroundColor;
		color: $cNavItemSaleFontColor;

		+ .c-nav__more:before {
			border-left-color: $cNavItemSaleFontColor;
		}
	}

	.c-nav__item--sale > & {
		.no-touch &:hover {
			background: $cNavItemSaleHoverBackgroundColor;
		}
	}

	.c-nav__item--sale > &[aria-expanded="true"] {
		background: $cNavItemSaleHoverBackgroundColor;
		color: $cNavItemSaleFontColor;
	}

	&.c-nav__link--alt {
		background-color: $cNavItemAltBackgroundColor;
	}
}

.c-nav__link {

	@include critical {

		@include navLinkMobileStyle();

		@include mq-min($bpDesktop) {
			padding: 12px 14px;
			letter-spacing: 0.2px;
			display: flex;
			align-items: center;
			text-align: center;
			text-overflow: clip;
			overflow: visible;
			white-space: normal;

			&[aria-expanded="true"] {
				background-color: $colorWhite;
				color: $cNavBackground;
			}
		}
		@include mq-min($bpMassive) {
			white-space: nowrap;
		}
	}
}

.c-nav__more {

	@include critical {
		display: none;
	}

	@include defer {

		@include mq-max($bpDesktop - 1) {
			display: block;
			width: 48px;
			height: 48px;
			border: 0;
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			background: none;
			@include appearance-none();
			cursor: pointer;
			padding: 0;
			border-left: 1px solid $cNavItemBorderColorTwo;

			&:before {
				content: '';
				display: block;
				width: 0;
				height: 0;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				border-left: 5px solid $cNavMoreArrowColor;
				top: 50%;
				left: 50%;
				position: absolute;
				margin: -5px 0 0 -2.5px;
				transition: transform 300ms ease;
			}

			&:after {
				content: '';
				display: block;
				width: 1px;
				position: absolute;
				top: 0;
				bottom: 0;
				background: $cNavItemBorderColorOne;
			}

			&[aria-expanded="true"]:before {
				transform: rotate(90deg);
			}

			.c-nav__item--sale > & {
				border-left-color: $cNavItemSaleMoreBorderColor1;

				&:after {
					background: $cNavItemSaleMoreBorderColor2;
				}
			}

			.c-nav__item--feature > & {
				border-left-color: $cNavItemFeatureMoreBorderColor1;

				&:after {
					background: $cNavItemFeatureMoreBorderColor2;
				}
			}
		}
	}
}

.c-nav__mobile-toggle-content {

	@include defer {

		@include mq-max($bpDesktop - 1) {
			background: $cNavSecondaryBackground;
			max-height: 0;
			transition: max-height 300ms;
			overflow: hidden;

			&:not(.is-active) {
				max-height: 0 !important; // to override inline max height
			}
		}

		@include mq-min($bpDesktop) {
			max-height: none !important; // to override inline max height
		}
	}
}

.c-nav__secondary {

	@include mq-min($bpDesktop) {

		@include critical {
			display: none;

			&.is-active {
				display: block;
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
				width: 100%;
				max-width: $cNavInnerMaxWidth;
				margin: 0 auto;
				background-color: $cNavDesktopBackgroundColor;
			}
		}
	}
}

.c-nav__list--secondary {

	@include defer {

		@include mq-min($bpDesktop) {
			display: block;
		}

		.c-nav__item {

			@include mq-max($bpDesktop - 1) {
				border: none;
			}

			@include mq-min($bpDesktop) {
				display: block;
				max-width: none;
			}
		}

		.c-nav__link {
			font-weight: normal;

			@include mq-max($bpDesktop - 1) {
				color: $cNavSecondaryLinkColor;
			}
		}

		.c-nav__more {

			@include mq-max($bpDesktop - 1) {
				border-color: $cNavItemBorderColorFour;

				&:after {
					background: $cNavItemBorderColorThree;
				}
			}
		}
	}
}


.html--nav-open {

	@include defer {
		
		@include mq-max($bpDesktop - 1) {
			body {
				overflow: hidden !important;
			}

			.filterSlideOut {
				display: none;
			}
		}

		@include mq-min($bpDesktop) {
			.c-header__middle {
				position: relative;
				z-index: 99;
			}

			.c-nav {
				position: relative;
				z-index: 100;
			}
		}
	}
}


.c-nav-site-wrapper {

	@include defer {
		position: relative;
		z-index: 1;

		@include mq-max($bpDesktop - 1) {
			transition: transform 0.3s ease;

			.html--nav-open & {
				transform: translateX(-$cNavWidth);
			}
		}
	}
}

.c-nav-site-wrapper__cover {

	@include defer {

		@include mq-max($bpDesktop - 1) {
			background: $cNavSiteWrapperCoverBackground;
			opacity: .4;
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 8888;
			display: none;

			.html--nav-open & {
				display: block;
			}
		}
	}
}

/* Mega nav */

@include defer {

	.c-nav__meganav-list {
		margin: 0;
		padding: 0;
		list-style: none;

		@include mq-min($bpDesktop) {
			font-size: rem(14px);
			line-height: rem(18px);
		}
	}

	.c-nav__meganav-list-item {
		margin: 0;
		padding: 0;
	}

	.c-nav__meganav-grid-inner {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.c-nav__meganav-list-title {
		position: relative;
		margin: 0;
		padding: 0;
	}

	.c-nav__meganav-col {
		margin: 0;
		padding: 0;
	}

	.c-nav__meganav-title {
		display: none;
	}

	@include mq-max($bpDesktop - 1) {

		.c-nav__meganav {
			border-top: 1px solid $cNavItemBorderColorTwo;
		}

		.c-nav__meganav-inner {
			border-top: 1px solid $cNavItemBorderColorThree;
		}

		.c-nav__meganav-col {

			&:not(:last-child) {
				border-bottom: 1px solid $cNavItemBorderColorThree;
			}
		}

		.c-nav__meganav-list-item {
			margin-bottom: 0 !important; // override any inline styles added for desktop
			border-bottom: 1px solid $cNavItemBorderColorFour;

			.c-nav__meganav-col:last-child &:last-child {
				border-bottom: 0;
			}

			&:not(:first-child) {
				border-top: 1px solid $cNavItemBorderColorThree;
			}
		}

		.c-nav__meganav-list--sub {
			background: $cNavMobileRenameThisBackgroundColor;
			border-top: 1px solid $cNavItemBorderColorFour;

			.c-nav__meganav-list-item {
				border-bottom: 1px solid $cNavItemBorderColorOne;

				&:not(:first-child) {
					border-top: 1px solid $cNavItemBorderColorFive;
				}

				&:last-child {
					border-bottom: 0;
				}
			}
		}

		.c-nav__meganav-list-link,
		.c-nav__meganav-list-nolink {
			@include navLinkMobileStyle();
			font-weight: 400;

			.c-nav__meganav-list--sub & {
				color: rgba($cNavLinkColor, 0.8);
				padding-left: 20px;
			}
		}

		.c-nav__meganav .c-nav__more {
			border-color: $cNavItemBorderColorFour;

			&:after {
				background: $cNavItemBorderColorThree;
			}
		}
	}

	@include mq-min($bpDesktop) {

		.c-nav__meganav-inner {
			padding: 40px;
			height: 100%;
		}

		.c-nav__meganav-title {
			display: block;
			margin: 0 0 30px 0;
			font-weight: 600;
			font-size: rem(18px);
			line-height: rem(22px);
			color: $cNavMegaNavTitleColor;
			padding: 0;
		}

		.c-nav__meganav-title-link {
			color: $cNavMegaNavTitleColor;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

		.c-nav__meganav-title-link:after {
			content: ' >';
			display: inline-block;
			padding-left: 5px;
			transform: scale(0.8, 1);
			position: relative;
			bottom: -1px;
		}

		.c-nav__meganav-grid-inner {
			display: flex;
		}

		.c-nav__meganav-col {
			width: auto;
			flex: 1;
			box-sizing: border-box;
			padding: 0 0 0 40px;
		}

		.c-nav__meganav-grid-inner .c-nav__meganav-col:first-child {
			padding-left: 0;
		}

		.c-nav__meganav--side-banners {

			display: flex;

			.c-nav__meganav-inner {
				box-sizing: border-box;
				vertical-align: top;
				min-height: ($cNavSideBannerHeight * 2);
				flex: 1;
				width: auto;
			}
		}

		.c-nav__meganav-list-item:not(:last-child) {
			padding-bottom: 10px;
		}

		.c-nav__meganav-list--sub {
			padding: 10px 0;
		}

		.c-nav__meganav-list-title {
			color: $cNavMegaNavListTitleColor;
			font-weight: 600;
			font-size: rem(15px);
		}

		.c-nav__meganav-list-link {
			color: $cNavMegaNavListLinkColor;
			text-decoration: none;

			.c-nav__meganav-list-title & {
				color: $cNavMegaNavListTitleLinkColor;
			}

			.no-touch &:hover {
				text-decoration: underline;
			}
		}

		.c-nav__meganav-list-nolink {
			color: $cNavMegaNavListNoLinkColor;
			text-decoration: none;

			.c-nav__meganav-list-title & {
				color: $cNavMegaNavListTitleNoLinkColor;
			}
		}

		.c-nav__meganav-list-link--Italic {
			font-style: italic;
		}

		.c-nav__meganav-list-link--Sale,
		.c-nav__meganav-list-title .c-nav__meganav-list-link--Sale {
			color: $cNavMegaNavListLinkSaleColor;
		}
	}
}


.c-nav__banners {

	@include defer {

		@include mq-max($bpDesktop - 1) {
			display: none;
		}

		@include mq-min($bpDesktop) {
			font-size: 0;
			line-height: 0;
			display: flex;

			.c-nav__meganav--side-banners & {
				width: $cNavSideBannerWidth;
				display: flex;
				flex-direction: column;
			}

			.c-nav__banners-link {
				display: block;

				.c-nav__meganav--banners-2.c-nav__meganav--bottom-banners & {
					width: 50%;
					display: inline-block;
				}

				flex: 1;
				width: auto !important;

				.picture {
					position: relative;
					display: block;

					.c-nav__meganav--side-banners & {
						@include ratio($cNavSideBannerWidth, ($cNavSideBannerHeight * 2));
					}

					.c-nav__meganav--side-banners.c-nav__meganav--banners-2 & {
						@include ratio($cNavSideBannerWidth, $cNavSideBannerHeight);
					}

					.c-nav__meganav--bottom-banners & {
						@include ratio($cNavBottomBannerWidth, $cNavBottomBannerHeight);
					}

					.c-nav__meganav--bottom-banners.c-nav__meganav--banners-2 & {
						@include ratio(($cNavBottomBannerWidth * 0.5), $cNavBottomBannerHeight);
					}
				}
			}

			.c-nav__banners-image {
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}


// dimmer
@include defer {

	.js-mega-nav-dimmer {
		display: none;

		@include mq-min($bpDesktop) {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 125vh;
			z-index: 1;
			opacity: 0;
			transition: opacity 0.25s ease;
			will-change: opacity;
		}
	}

	.js-mega-nav-dimmer__inner {

		@include mq-min($bpDesktop) {
			width: 100%;
			height: 100%;
			background: $cNavMegaNavDimmerBackgroundColor;
			opacity: 0.75;
		}
	}

	.js-mega-nav-dimmer--show {

		@include mq-min($bpDesktop) {
			display: block;
		}
	}

	.js-mega-nav-dimmer--fade {

		@include mq-min($bpDesktop) {
			opacity: 1;
		}
	}
}
