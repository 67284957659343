@use "sass:color";

// _forms.scss

$formWrapperBorderColor: $colorDivider !default;
$formReqColor: $colorEmphasise !default;
$formTextTextareaSelectUploadBorderColor: $colorDivider !default;
$formTextTextareaSelectUploadColor: $colorGreySafe !default;
$formTextTextareaSelectUploadPlaceholderTextFallbackColor: $colorGrey !default;
$formTextTextareaSelectUploadPlaceholderTextColor: rgba(153,153,153, 1) !default;
$formTextTextareaSelectUploadFocusBorderColor: $colorGamma !default;
$formTextTextareaSelectUploadFocusColor: $colorBase !default;
$formTextTextareaSelectUploadFocusBoxShadow: 0 0 4px $colorGrey !default;
$formTextTextareaSelectUploadDisabledBackgroundColor: #F2F2F2 !default;
$formTextTextareaSelectUploadValidationErrorBackgroundImageUrl: url(#{$baseSitePath}/images/forms/errors/border.png) !default;
$formChecksRadiosInputFocusOutlineColor: $colorGamma !default;
$formChecksRadiosInputInputValidationErrorBoxShadow: 0 0 5px $colorEmphasise !default;
$formChecksRadiosRatingsBackgroundUrl: url(#{$baseSitePath}/images/misc/rating.png) !default;
$formChecksRadiosDividerBorderColor: $colorDivider !default;
$formButtonBackgroundColor: $colorGamma !default;
$formButtonColor: $colorWhite !default;
$formButtonHoverBackgroundColor: color.adjust($colorGamma, $lightness: -5%, $space: hsl) !default;
$formButtonHoverColor: $colorWhite !default;
$formButtonFocusBackgroundColor: $colorGamma !default;
$formButtonFocusColor: $colorGamma !default;
$formButtonDisabledBackgroundColor: $colorGrey !default;
$formButtonToggleBeforeBackgroundUrl: url(#{$baseSitePath}/images/forms/toggle.png) !default;
$formButtonActionBackgroundColor: $colorSuccess !default;
$formButtonActionHoverBackgroundColor: color.adjust($colorSuccess, $lightness: -5%, $space: hsl) !default;
$formButtonGreenBackgroundColor: $colorAlpha !default;
$formButtonGreenHoverBackgroundColor: color.adjust($colorAlpha, $lightness: -5%, $space: hsl) !default;
$formButtonGreyBackgroundColor: $colorGrey !default;
$formButtonGreyHoverBackgroundColor: color.adjust($colorGrey, $lightness: -5%, $space: hsl) !default;
$formButtonRedBackgroundColor: $colorEmphasise !default;
$formButtonRedHoverBackgroundColor: color.adjust($colorEmphasise, $lightness: -5%, $space: hsl) !default;
$formButtonLinkBackgroundColor: $colorLink !default;
$formButtonLinkHoverBackgroundColor: $colorLinkHover !default;
$formButtonSearchBackgroundColor: $colorGamma !default;
$formButtonSearchBackgroundUrl: url(#{$baseSitePath}/images/misc/search.png) !default;
$formButtonSearchHoverBackgroundColor: color.adjust($colorGamma, $lightness: -5%, $space: hsl) !default;
$formButtonSearchHoverBackgroundUrl: url(#{$baseSitePath}/images/misc/search.png) !default;
$formButtonCheckoutBackgroundColor: $colorSuccess !default;
$formButtonCheckoutHoverBackgroundColor: #21AE19 !default;
$formMessageSuccessWarningErrorBackgroundColor: color.adjust($colorAlpha, $lightness: 55%, $space: hsl) !default;
$formMessageSuccessWarningErrorColor: $colorAlpha !default;
$formMessageSuccessWarningErrorAColor: $colorAlpha !default;
$formMessageSuccessWarningErrorAHoverColor: $colorAlpha !default;
$formSuccessBackgroundColor: $colorSuccess !default;
$formSuccessColor: $colorWhite !default;
$formWarningBackgroundColor: $colorWarning !default;
$formWarningColor: $colorBase !default;
$formErrorBackgroundColor: $colorEmphasise !default;
$formErrorColorOnBackground: $colorWhite !default;
$formErrorAColor: $colorWhite !default;
$formErrorAHoverColor: $colorWhite !default;
$formRowChecksRadiosErrorColor: $colorEmphasise !default;
$formInfoColor: $colorGreySafe !default;
$formUmbracoBackgroundColor: transparent !default;
$formErrorColor: #CC4233 !default;
$uiAutocompleteBorderColor: $colorBorder !default;
$uiAutocompleteBoxShadow: 0 2px 2px rgba(0,0,0,.25) !default;
$uiAutocompleteMenuItemABackgroundColor: $colorWhite !default;
$uiAutocompleteMenuItemAColor: $colorBase !default;
$uiAutocompleteMenuItemFocusActiveBackgroundColor: color.adjust($colorDelta, $lightness: 7%, $space: hsl) !default;
$uiAutocompleteMenuItemFocusActiveColor: $colorBase !default;
$uiAutocompleteAutocompleteProductsProductBorderColor: $colorBorder !default;
$uiAutocompleteAutocompleteProductsProductLinkContentTitleColor: $colorAlpha !default;
$uiAutocompleteAutocompleteProductsProductLinkContentCodeColor: $colorGrey !default;
$uiAutocompleteAutocompleteProductsProductLinkContentPriceColor: $colorEmphasise !default;
$uiAutocompleteAutocompleteProductsAllLinkBackgroundColor: $colorAlpha !default;
$uiAutocompleteAutocompleteProductsAllLinkColor: $colorWhite !default;
$uiAutocompleteAutocompleteProductsAllLinkHoverFocusBackgroundColor: color.adjust($colorAlpha, $lightness: 5%, $space: hsl) !default;
$uiAutocompleteAutocompleteProductsAllLinkHoverFocusColor: $colorWhite !default;
